import { Link } from "react-router-dom";
import {
  OfferDetailPartsFragment,
  ProductInfoPartFragment,
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { useMainContext } from "../../layout/components/MainProvider";
import { addCommaFunc } from "../../orders-route/handler/handler";
import { getProdImgUrls } from "../../products-route/types/product";

interface IProps {
  productData?: ProductInfoPartFragment;
  offerData?: OfferDetailPartsFragment;
}

export function ProductInfo({ productData, offerData }: IProps): JSX.Element {
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const sellerRoute = `/${tenantUrlTag}/sellers`
  const productImgUrls = getProdImgUrls(productData?.product_image_urls || offerData?.product_image_urls);

  return (
    <Card>
      <div className="row">
        <div className="col-sm-12 col-md-3 d-flex justify-content-center">
          {productImgUrls?.length ? (
            <>
              <img
                src={productImgUrls[0]}
                alt="Product"
                className="img-fluid mb-3"
                style={{ maxHeight: "160px" }}
              />
            </>
          ) : (
            <div className="img-placeholder d-flex justify-content-center align-items-center mt-5 d-none d-lg-block">
              <i className="bi bi-card-image"></i>
            </div>
          )}
        </div>
        <div className="col-sm-12 col-md-9">
          <h2 className="sub-title font-weight-semi-bold text-muted">
            {productData?.product_title || offerData?.product_title || "NAME"}
          </h2>
          <p className="mb-0">
            <span className="font-weight-semi-bold">Manufacturer:</span>
            <span className="text-muted ms-2">{productData?.manufacturer || offerData?.cp_product.manufacturer}</span>
          </p>
          <p className="mb-0">
            <span className="font-weight-semi-bold">MFGER PN:</span>
            <span className="text-muted ms-2">{productData?.mfr_part_number || offerData?.cp_product.mfr_part_number}</span>
          </p>
          <p className="mb-1">
            <span className="font-weight-semi-bold">Category:</span>
            <span className="text-muted ms-2">{productData?.product_category || offerData?.cp_product.product_category || "n/a"}</span>
          </p>
          <p className="font-weight-semi-bold mb-0">Description</p>
          <p className="text-muted">{productData?.product_description || offerData?.product_description || "n/a"}</p>
        </div>
      </div>
      {offerData &&
        <div className="row">
          <div className="col-md-12 col-lg-4">
            <div className="d-flex p-2 border mb-1">
              <div className="ms-2 me-3 align-self-top">
                <i className="bi bi-shop text-muted text-large"></i>
              </div>
              <div className="flex-1">
                <small className="text-muted me-2">Seller</small>
                <h6>
                  <Link data-role="nav" to={`${sellerRoute}/${offerData.seller_id}`}>
                    {offerData.company_name}
                  </Link>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4">
            <div className="d-flex p-2 border mb-1">
              <div className="ms-2 me-3 align-self-top">
                <i className="bi bi-cart text-muted text-large"></i>
              </div>
              <div className="flex-1">
                <small className="text-muted me-2">Number of Orders</small>
                <h6>{offerData?.order_count}</h6>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4">
            <div className="d-flex p-2 border mb-1">
              <div className="ms-2 me-3 align-self-top">
                <i className="bi bi-cash-coin text-muted text-large"></i>
              </div>
              <div className="flex-1">
                <small className="text-muted me-2">Total Revenue</small>
                <h6>$ {!offerData.cp_revenue ? 0 : addCommaFunc(offerData.cp_revenue)}</h6>
              </div>
            </div>
          </div>
        </div>}
    </Card>
  );
}
