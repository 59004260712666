import { FC, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Vw_Sales_By_Seller_Order_By, useSalesBySellerQuery } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { ELoadingType, IRef, OrderByType } from "../../common/types/types";
import { useMainContext } from "../../layout/components/MainProvider";
import { ColumnAlignLeft } from "./ColumnAlignEnd";
import { ZeroedOutMoney } from "./ZeroedOutMoney";

interface IProp {
  handleCallBack: (where: string,
    orderBy: Vw_Sales_By_Seller_Order_By | Vw_Sales_By_Seller_Order_By[]) => void;
};

export const SalesBySellerData: FC<IProp> = (props) => {
  const [loading, setLoading] = useState<number>(-1);
  const { handleCallBack } = props;
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const accessSeller = userInfo.permissions! & context.permissions.access_seller;

  const { number_items_per_page } = context.operatorSettings.preset;
  const [offset, setOffset] = useState<number>(0);

  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "Seller",
    column_name: "company_name",
    orderBy: "asc",
  });
  const orderByOptions: { [key: string]: string } = {
    Seller: "company_name",
    Orders: "orders",
    Refunds: "refunds",
    Shipping: "shipping",
    Commission: "commission",
    Total_Sales: "total_sales",
    Gross_Sales: "gross_sales",
  };
  const tableColumnsToSearch: string[] = ["company_name", "status_cd"];

  const tableColumns = [
    "Seller",
    "Orders",
    "Gross Sales",
    "Refunds",
    "Shipping",
    "Commission",
    "Total Sales",
  ];
  const orderByString = orderBy.column_name
    ? { [orderBy.column_name]: orderBy.orderBy }
    : { [orderByOptions.company_name]: "desc" };

  const customRule = `,"operator_id": {"_eq":"${userInfo.operator_id}"},"orders": {"_gt": 0},"status_cd":{"_in":["Open","Suspended", "Closed" ]}`;
  let handleWhereStr = handleWhere({
    columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue),
    selectedFilters,
    customRule
  });
  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      order_by: orderByString,
      where: JSON.parse(handleWhereStr),
    },
  };

  const [queried] = useSalesBySellerQuery(inputs);
  const multiSelectRef = useRef<IRef | null>(null);
  useEffect(() => {
    if (queried.data) {
      setLoading(ELoadingType.None);
    }
  }, [queried.data]);

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "SalesBySellerData", errMsg: error.message }} />;
  }

  const rows = queried.data?.vw_sales_by_seller;
  const totalRows = queried.data?.items?.aggregate?.count ?? 0;

  handleCallBack(handleWhereStr, orderByString);
  const tableData = rows?.map((row) => {
    return {
      id:
        row.seller_id ?? "MISSING ID",
      Orders: <ColumnAlignLeft className="order-total">{row.orders}</ColumnAlignLeft>,

      // TODO: hide entire column if seller, cause only 1 seller
      Seller: accessSeller ? (
        <NavLink
          data-role="nav"
          data-testid="nav-message"
          // TODO: alter SQL to make id unique and replace with store id
          to={`/${tenantUrlTag}/sellers/${row.seller_id}`}
          style={{ maxWidth: "180px" }}
          className="nav-link text-truncate text-decoration-underline"
        >
          {row.company_name}
        </NavLink>
      ) : (
        <div style={{ maxWidth: "180px" }} className="text-truncate">
          {row.company_name}
        </div>
      ),
      Total_Sales: (
        <ColumnAlignLeft className="total-sales">
          <ZeroedOutMoney amount={row.total_sales ?? 0} />
        </ColumnAlignLeft>
      ),
      Gross_Sales: (
        <ColumnAlignLeft className="gross-sales">
          <ZeroedOutMoney amount={row.gross_sales ?? 0} />
        </ColumnAlignLeft>
      ),
      Refunds: (
        <ColumnAlignLeft className="refunds">
          <ZeroedOutMoney amount={(row.refunds ?? 0)} />
        </ColumnAlignLeft>
      ),
      Shipping: (
        <ColumnAlignLeft className="shipping">
          <ZeroedOutMoney amount={row.shipping ?? 0} />
        </ColumnAlignLeft>
      ),
      Commission: (
        <ColumnAlignLeft className="commission">
          <ZeroedOutMoney amount={row.commission ?? 0} />
        </ColumnAlignLeft>
      ),
    };
  });

  const applyChanges = () => { };
  const addFilterOptions = [
    { label: "Seller", value: "company_name_lower", type: "string" },
    { label: "Orders", value: "orders", type: "numeric" },
    { label: "Gross Sales", value: "gross_sales", type: "numeric" },
    { label: "Refunds", value: "refunds", type: "numeric" },
    { label: "Shipping", value: "shipping", type: "numeric" },
    { label: "Commission", value: "commission", type: "numeric" },
    { label: "Total Sales", value: "total_sales", type: "numeric" },
    {
      label: "Status", value: "status_cd",
      valueOptions:
        [
          { label: '', value: '' },
          { label: 'Open', value: 'Open' },
          { label: 'Suspended', value: 'Suspended' },
          { label: 'Closed', value: 'Closed' },
          { label: 'Pending', value: 'Pending' },
          { label: 'Archived', value: 'Archived' },
        ]
    }
  ];

  return (

    <Card>
      {tableData === undefined ? (
        <>
          <PlaceholderTableSearchItem />
          <TablePlaceHolder columnNames={tableColumns} numberOfRows={number_items_per_page} />
        </>
      ) : (
        <Table
          setSearchValue={setSearchValue}
          columnNames={tableColumns}
          data={tableData}
          applyChanges={applyChanges}
          offset={offset}
          setOffset={setOffset}
          totalRecords={totalRows}
          ref={multiSelectRef}
          multiSelectTable={false}
          filters
          addFilterOptions={addFilterOptions}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setOrderBy={setOrderBy}
          orderByOptions={orderByOptions}
          orderBy={orderBy}
          loading={loading}
          setLoading={setLoading}
          searchPlaceHolder="Search by Seller,..."
          searchHint="Search by Seller, Status"
        />
      )}
    </Card>

  );
};
