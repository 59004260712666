import {
  FocusEvent,
  forwardRef,
  Ref,
  SyntheticEvent,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { ISearch, SelectOption } from "../../types/types";
import { Dialog } from "../Dialog";
import { AutosizeInput } from "./AutosizeInput";
import { DropdownOptions } from "./DropdownOptions";
import { SelectedItem } from "./SelectedItem";

type SelectProps = {
  options: SelectOption[];
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  multiple?: boolean;
  required?: boolean;
  search?: ISearch;
  errorMsg?: string;
  noClear?: boolean;
  nonSearchDef?: string;
  onSelectionChanged?: (option: SelectOption[]) => void;
  addValue?: (value: string, callback: (added: SelectOption | null) => void) => void;
};

export interface IInputState {
  focus: boolean;
  clear: boolean;
  showHolder: boolean;
  placeholder?: string;
}

export type SelectRef = Readonly<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  selected: SelectOption[]
  clear: () => void;
  set: (values: string[]) => void;
}>;

export const CustomSelect = forwardRef<SelectRef, SelectProps>(
  (props: SelectProps, ref: Ref<SelectRef>): JSX.Element => {
    const {
      label,
      options,
      placeholder,
      disabled,
      multiple,
      search,
      required,
      errorMsg,
      noClear,
      nonSearchDef,
      onSelectionChanged,
      addValue,
    } = props;

    const [isAdd, setIsAdd] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>("");
    const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);
    const [accuOptions, setAccuOptions] = useState<SelectOption[]>([]);
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [useDefVal, setUseDefVal] = useState<boolean>(search ? !!search.searchParam.defaultValue : !!nonSearchDef);
    const defInputState = {
      focus: false,
      clear: false,
      showHolder: true,
      placeholder: placeholder,
    };
    const [inputStatus, setInputStatus] = useState<IInputState>(defInputState);
    const selectedValues = selectedOptions.map((option) => option.value);
    useImperativeHandle(
      ref,
      () => ({
        value: multiple
          ? selectedOptions.map((option) => option.value)
          : selectedOptions.find((option) => option)?.value,
        selected: selectedOptions,
        clear: clear,
        set: (values: string[]) => {
          const curOptions = options.filter(opt => values.includes(opt.value));
          setSelectedOptions([...selectedOptions, ...curOptions]);
        },
      }),
      [selectedOptions]
    );

    useEffect(() => {
      if (search) {
        const param = search.searchParam;
        if (param.offset !== 0) {
          const accuValues = accuOptions.map((option) => option.value);
          const currents = options.filter((option) => !accuValues.includes(option.value));
          setAccuOptions([...accuOptions, ...currents]);
        } else setAccuOptions(options);
        initDefault();
      }
      else {
        initDefault();
        setAccuOptions(options);
      }
    }, [options]);

    useEffect(() => {
      if (onSelectionChanged) {
        onSelectionChanged(selectedOptions);
      }
    }, [selectedOptions]);

    const initDefault = () => {
      if (useDefVal) {
        const defVal = search ? search.searchParam.defaultValue : nonSearchDef;
        const defOpt = options.find(opt => opt.value.includes(defVal));
        if (defOpt) {
          setSelectedOptions([defOpt]);
          setUseDefVal(false);
          search?.reset && search?.reset();
        }
      }
    }

    const curOptions = accuOptions.filter((option) => !selectedValues.includes(option.value));
    const doSearch = (showMore: boolean, searchTerm?: string) => {
      addValue && setInputValue(searchTerm || "");
      if (search) {
        const param = search.searchParam;
        const offset = showMore ? param.limit + param.offset : 0;
        const searchWords = showMore ? param.searchTerm : searchTerm || "";
        search.setSearchParam({ ...param, searchTerm: `%${searchWords}%`, offset: offset });
      }
    };

    const isSelected = (value: object) => selectedOptions.some((sop) => sop.value === value);
    const handleSelect = (option: SelectOption) => {
      if (!multiple) {
        setSelectedOptions([option]);
        closeDropdown();
        return;
      }
      let selects = [...selectedOptions];
      if (isSelected(option.value)) selects = selects.filter((sop) => sop.value !== option.value);
      else selects = [...selects, option];
      setSelectedOptions(selects);
      setInputStatus({ ...inputStatus, focus: true });
    };

    const handleAll = (adding: boolean = true) => {
      if (adding) setSelectedOptions([...selectedOptions, ...curOptions]);
      else {
        setSelectedOptions([]);
      }
    };

    const clearSelection = (event: SyntheticEvent) => {
      setInputStatus({ ...inputStatus, clear: true, showHolder: !dropdownOpen });
      handleAll(false);
      suppressEvent(event);
    };

    const clear = () => {
      handleAll(false);
      setInputStatus({ ...inputStatus, clear: true, focus: false, showHolder: true });
    };

    const closeDropdown = () => {
      setDropdownOpen(false);
      setInputStatus({ ...inputStatus, clear: true, focus: false });
    };

    const suppressEvent = (event: SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();
    };

    const toggleDropdown = () => {
      !disabled && setDropdownOpen(!dropdownOpen);
    };

    const handleFocus = () => {
      if (!disabled) {
        setInputStatus({ ...inputStatus, focus: true, showHolder: false });
      }
    };

    const handleBlur = (event: FocusEvent) => {
      if (!event.currentTarget?.contains(event.relatedTarget as Node)) {
        setDropdownOpen(false);
        setInputStatus({
          ...inputStatus,
          clear: true,
          focus: false,
          showHolder: selectedOptions.length === 0,
        });
        setInputValue("");
      }
    };

    const continueAdd = async (isContinue: boolean) => {
      if (isContinue)
        inputValue && addValue && addValue(inputValue, closeAddDialog);
      else closeAddDialog();
    }

    const showAddDialog = (event: SyntheticEvent) => {
      setIsAdd(true);
      suppressEvent(event);
    };

    const closeAddDialog = (added: SelectOption | null = null) => {
      if (added)
        handleSelect(added);
      setIsAdd(false);
      closeDropdown();
    }

    const selections = () => {
      return (
        <div
          className={`d-flex justify-content-between align-items-center ${search ? "" : "input-none"}`}
          onMouseDown={toggleDropdown}
        >
          <div className="d-flex flex-row flex-wrap align-items-center w-100">
            {selectedOptions
              .sort((op1, op2) => op1.label.localeCompare(op2.label))
              .map((option) => (
                <SelectedItem
                  key={`selected-item-${option.value}`}
                  option={option}
                  multiple={multiple}
                  handleSuppress={suppressEvent}
                  handleSelect={handleSelect}
                  disabled={disabled}
                />
              ))}
            {(selectedOptions.length === 0 || dropdownOpen) && (
              <AutosizeInput
                status={inputStatus}
                searchFunc={(searchTerm: string) => doSearch(false, searchTerm)}
                disabled={disabled}
                fetching={search?.fetching && search?.searchParam.offset === 0}
                readOnly={!search}
              />
            )}
          </div>
          {!noClear && !disabled && selectedOptions.length !== 0 && (
            <div className="d-flex align-items-center align-self-stretch border-right">
              <div className="px-2" onMouseDown={(event) => clearSelection(event)}>
                <i className="text-dark bi bi-x-lg"></i>
              </div>
            </div>
          )}
          {addValue && (
            <div className="d-flex align-items-center align-self-stretch border-right">
              <div className="px-2" onMouseDown={(event) => !!inputValue && showAddDialog(event)}>
                <i className={`${!inputValue ? "text-light-gray" : "text-dark"} bi bi-plus-lg`}></i>
              </div>
            </div>
          )}

        </div>
      );
    };

    return (
      <>
        {label && (
          <label className={`card-title font-weight-semi-bold form-label ${required ? "required" : ""}`}>
            {label}
          </label>
        )}
        <div
          tabIndex={0}
          onFocus={handleFocus}
          onBlur={handleBlur}
          aria-disabled={disabled}
          className={`custom__select form-select d-flex flex-column ${errorMsg ? "is-invalid" : ""} ${dropdownOpen ? "focused" : ""}`}
        >
          {selections()}
          {dropdownOpen && (
            <DropdownOptions
              options={curOptions}
              handleSelect={handleSelect}
              handleSelectAll={handleAll}
              searchFunc={() => doSearch(true)}
              total={search?.searchParam.total || options.length}
              accuTotal={accuOptions.length}
              fetching={search?.fetching && search?.searchParam?.offset !== 0}
              handleSuppress={suppressEvent}
              multiple={multiple}
            />
          )}
          <Dialog
            show={isAdd}
            title={`Add Value`}
            continueText="Add"
            continue={continueAdd}
          >
            Are you sure you want to add the value?
          </Dialog>
        </div>
        {errorMsg && (
          <span className="small text-danger">
            <i className="bi bi-exclamation-circle-fill me-1" />
            {errorMsg}
          </span>)}
      </>
    );
  }
);
