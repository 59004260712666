/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Invite_Bool_Exp, Vw_Active_User_Bool_Exp,
  useCurrentTcSubscription,
  useTotalAllUserSubscription,
  useTotalArchivedUserSubscription,
  useTotalPendingInviteSubscription
} from "../../../generated/urql-graphql";
import { LinkTabsComponent } from "../../common/components/LinkTabsComponent";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useRoleList } from "../../common/hooks/globals";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { ColumnDesc } from "../../common/types/types";
import { useMainContext } from "../../layout/components/MainProvider";
import { AllUsers } from "./AllUsers";
import { ArchivedUsers } from "./ArchivedUsers";
import { InviteUser } from "./InviteUser";
import { PendingInvites } from "./PendingInvites";

const fieldListAllUser: ColumnDesc[] = [
  { column_name: "company", display_name: "Company" },
  { column_name: "email", display_name: "Email" },
  { column_name: "full_name", display_name: "Full Name" },
  { column_name: "role_name", display_name: "Role" },
  {
    column_name: "last_login",
    column_type: "datetime",
    display_name: "Last Login Date",
  },
];

const fieldListPendingInvites: ColumnDesc[] = [
  { column_name: "expire_date", column_type: "datetime", display_name: "Expire Date" },
  { column_name: "cp_company", display_name: "Company" },
  { column_name: "email", display_name: "Email" },
  { column_name: "first_name", display_name: "First Name" },
  { column_name: "last_name", display_name: "Last Name" },
  { column_name: "name", display_name: "Role" },
];

export const UserList: FC = () => {
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/users`;

  const [, setFieldList] = useState<ColumnDesc[]>(fieldListAllUser);
  const userInfo = useUserInfo()!;
  const operatorId = userInfo.operator_id!;
  const location = useLocation();

  let staticFilterString = "";
  let initialQryString = `"auth_id":{"_is_null":true}`;
  if (location.pathname.includes(baseRoute + "/archived")) {
    initialQryString = "{}";
  }
  else if (location.pathname.includes(baseRoute + "/pending")) {
    staticFilterString = `"auth_id":{"_is_null":true}`;
    initialQryString = `{ "_and": [], ${staticFilterString}}`;
  } else { //baseroute
    staticFilterString = `"current": {"_eq":true}`;
    initialQryString = `{ "_and": [], ${staticFilterString}}`;
  }
  const condition = userInfo.user_level! === 35 ? `operator_id:{_eq:"${operatorId}"}` : "";

  let where1 = userInfo.user_level! === 35 ? ` "operator_id":{ "_eq": "${operatorId}"}` : "";
  let where2 = userInfo.user_level! >= 20 ? ` "usr_order": { "_eq": 1}` : '';

  let where = `{${where1},${where2}}`;
  where = where.replace('{,', '{');
  where = where.replace(',}', '}');
  const [totalAllUser] = useTotalAllUserSubscription({
    variables: {
      where: JSON.parse(where),
    },
  });

  where2 = `"auth_id": { "_is_null": true}`;
  where = `{"_and": {${where2},${where1}}}`;
  where = where.replace(',}', '}');
  const [totalPendingInvite] = useTotalPendingInviteSubscription({
    variables: {
      where: JSON.parse(where),
    },
  });

  where = `{${where1}}`;
  const [totalArchivedUser] = useTotalArchivedUserSubscription({
    variables: {
      where: JSON.parse(where),
    },
  });

  const [tcSubscribed] = useCurrentTcSubscription({
    variables: {
      where: {
        operator_id: { _eq: operatorId },
        if_default: { _eq: true }
      }
    }
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [whereString, setWhereString] = useState<Vw_Active_User_Bool_Exp | Invite_Bool_Exp>(
    JSON.parse(initialQryString)
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const roleList = useRoleList();

  const { number_items_per_page: logsPerPage } = context.operatorSettings.preset;

  useEffect(() => {
    setSearchValue("");
    if (location.pathname === baseRoute) {
      initialQryString = `{ "_and": [], "current": {"_eq":true} }`;
      setFieldList(fieldListAllUser);
    } else if (location.pathname === baseRoute + "/pending") {
      initialQryString = `{ "_and": [], "auth_id":{"_is_null":true}}`;
      setFieldList(fieldListPendingInvites);
    }
    setWhereString(JSON.parse(initialQryString));
  }, [location.pathname]);

  useEffect(() => {
    setCurrentPage(1);
  }, [whereString, searchValue]);

  if (totalAllUser.error || totalPendingInvite.error || totalArchivedUser.error || tcSubscribed.error) {
    let errorMsg = totalAllUser.error || totalPendingInvite.error || totalArchivedUser.error;
    return <div>{`Error loading: ${errorMsg}`}</div>;
  }
  if (totalAllUser.fetching
    || totalPendingInvite.fetching
    || totalArchivedUser.fetching
    || tcSubscribed.fetching) {
    return <Loader />;
  }

  const inviteUser = userInfo.permissions! & context.permissions.invite_user;
  let tabs = [
    {
      tabName: "All Users",
      route: baseRoute,
      totalNumberOfElements: totalAllUser.data?.vw_active_user_aggregate.aggregate?.count,
      component: (
        <AllUsers
          whereString={whereString}
          logsPerPage={logsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ),

    },
    {
      tabName: "Pending Invites",
      route: baseRoute + "/pending",
      totalNumberOfElements: totalPendingInvite.data?.invite_aggregate.aggregate?.count,
      component: (
        <PendingInvites
          whereString={whereString}
          logsPerPage={logsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      ),
    },
  ];
  if (userInfo?.user_level! >= 20) {
    tabs = [
      ...tabs,
      {
        tabName: "Archived Users",
        route: baseRoute + "/archived",
        component: <ArchivedUsers />,
        totalNumberOfElements: totalArchivedUser.data?.vw_archived_user_aggregate.aggregate?.count,
      },
    ];
  }
  const dataTCCount = tcSubscribed.data ? tcSubscribed.data.terms_and_conditions.length : 0;
  function ShowInviteUser() {
    if (!inviteUser || (userInfo.seller_id && dataTCCount === 0)) {
      return <></>
    }
    return <InviteUser ToCCount={dataTCCount} />
  }
  function ShowTCMessage() {
    if (!userInfo.seller_id && (dataTCCount === 0)) {
      return <>
        <p className="bg-warning display-5 p-3 mb-5"><i className="bi bi-exclamation-triangle"></i> Terms and conditions document for your sellers has not been uploaded or set as default.<br></br>
          Please go to
          <NavLink
            data-role="nav"
            data-testid=""
            className="administration-card-link"
            to={`/${tenantUrlTag}/settings/terms-and-conditions`}
            key="InviteLink">
            {` Setting Terms and Condition page `}
          </NavLink>
          to upload a PDF file with your terms and conditions and mark it as the default/active ToC.
        </p>
      </>;
    }
    return <></>;
  }
  return (
    <div>
      <ReturnHeader title="Manage Users">
        <div><ShowInviteUser /></div>
      </ReturnHeader>
      <ShowTCMessage />
      {inviteUser ? (
        <LinkTabsComponent tabs={tabs} />
      ) : (
        <AllUsers
          whereString={whereString}
          logsPerPage={logsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};
