import { FC } from "react";

type Props = React.DetailedHTMLProps<
  React.OptionHTMLAttributes<HTMLOptionElement>,
  HTMLOptionElement
> & { "data-testid": string }; // force inclusion of id for testing

export const Option: FC<Props> = (props) => {
  // eslint-disable-next-line react/forbid-elements
  return <option data-value={props.value} {...props} />;
};
