import { ForwardedRef, forwardRef, useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Card } from "../../../common/components/Card";
import { TablePlaceHolder } from "../../../common/components/TablePlaceHolder";
import { Button } from "../../../forms/components/Button";
import { useMainContext } from "../../../layout/components/MainProvider";
import { addCommaFunc } from "../../../orders-route/handler/handler";
import { PaginationOffset } from "../../../settings-route/components/PaginationOffset";
import { escapeControlCharacters } from "../../handlers/handleEscapeControlChar";
import { handleSingleCustomFilter } from "../../handlers/handleSingleCustomFilter";
import { useDateFormat } from "../../hooks/useDateFormat";
import { maxCountLimitLargeData } from "../../miscellaneous/data";
import { ELoadingType, IDateRange, IFilterOption, ILocalSingleFilter, OrderByType, SelectOption } from "../../types/types";
import { CustomSelect } from "../CustomSelect";
import { Input } from "../Input/index";
import { MultiSelectTable } from "../MultiSelectTable";
import { FilterDialog } from "./FilterDialog";
import Footer from "./Footer";
interface IRef {
  clearSelectedData: () => void;
  selectedData: string[];
  selectedOption: string;
}

interface IProps {
  /**
   * the content
   */
  columnNames: string[];
  /**
   * table information
   */
  data: { id: string }[];
  /**
   * number of pages to skip in pagination
   */
  offset?: number;
  /**
   * handle number of pages to skip in pagination
   */
  setOffset?: (value: number) => void;
  /**
   * total number of elements
   */
  totalRecords?: number;
  /**
   * search handler
   */
  setSearchValue?: (val: string) => void | undefined;
  /**
   * options for the multi select
   */
  options?: string[];
  /**
   * apply
   */
  applyChanges?: () => void;
  /**
   * apply multi select changes
   */
  multiSelectTable?: boolean;
  /**
   * show filters
   */
  filters?: boolean;
  /**
   * dates
   */
  dateRange?: IDateRange;
  /**
   * date handler
   */
  setDateRange?: (val: IDateRange) => void;
  /**
   * predefined date range filter
   */
  predefinedDateRange?: boolean;
  /**
   * options for individual filtering
   */
  addFilterOptions?: IFilterOption[];
  /**
   * selection of selected filters
   */
  selectedFilters?: string;
  /**
   * handle selection of selected filters
   */
  setSelectedFilters?: (val: string) => void;
  /**
* handle selection of selected filters for search_product custom function
*/
  setSelectedFiltersFunc?: (val: string) => void;
  /**
   * custom filters
   */
  customFilters?: { label: string; value: string }[];
  /**
   * custom filter handler
   */
  convertFiltersToLower?: boolean;
  extraFilterOps?: string[];
  setCustomSelectedFilters?: (val: string) => void;
  setOrderBy?: React.Dispatch<React.SetStateAction<OrderByType>>;
  orderBy?: OrderByType;
  orderByOptions?: {
    [key: string]: string;
  };
  loading?: number;
  setLoading?: (val: number) => void;
  searchPlaceHolder?: string;
  searchHint?: string;
  subTotal?: { label: string; value: string }[];
}

const dateRangeFilterOptions: { label: string; value: string }[] = [
  { label: "Today", value: "Today" },
  { label: "Last 7 days", value: "Last 7 days" },
  { label: "Last 14 days", value: "Last 14 days" },
  { label: "Last 30 days", value: "Last 30 days" },
  { label: "Last 90 days", value: "Last 90 days" },
  { label: "Previous calendar month", value: "Previous calendar month" },
  { label: "Previous calendar year", value: "Previous calendar year" },
  { label: "Week to date", value: "Week to date" },
  { label: "Month to date", value: "Month to date" },
  { label: "Year to date", value: "Year to date" },
  { label: "No date range", value: "No date range" },
  { label: "Custom date range", value: "Custom date range" },
];

export const Table = forwardRef((props: IProps, ref: ForwardedRef<IRef>) => {
  const {
    setSearchValue,
    columnNames,
    data,
    options,
    applyChanges,
    multiSelectTable,
    filters,
    offset,
    setOffset,
    totalRecords,
    dateRange,
    setDateRange,
    predefinedDateRange,
    addFilterOptions,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectedFilters,
    setSelectedFilters,
    setSelectedFiltersFunc,
    customFilters,
    setCustomSelectedFilters,
    setOrderBy,
    orderBy,
    orderByOptions,
    loading,
    setLoading,
    extraFilterOps,
    searchPlaceHolder,
    searchHint,
    subTotal
  } = props;
  //console.log(`index loading: ${loading}`);
  const calendarRef = useRef<HTMLDivElement>(null);
  const [context] = useMainContext();
  const { number_items_per_page } = context.operatorSettings.preset;
  const formatDate = useDateFormat();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [prevSearchValue, setPrevSearchValue] = useState<string>("");
  const [searchValue, setSearchValue2] = useState<string>("");
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [localSingleFilters, setLocalSingleFilters] = useState<ILocalSingleFilter[]>([]);
  const [currentPredefinedFilterValue, setCurrentPredefinedFilterValue] = useState<string>(
    //"Last 7 days"
    "Last 14 days"
  );
  const totalOfCountPerPage =
    number_items_per_page * currentPage > (totalRecords || 0)
      ? totalRecords
      : number_items_per_page * currentPage;

  // Handle Search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setCurrentPage(1);
      setOffset && setOffset(0);
      setSearchValue && setSearchValue(searchValue);

      if (prevSearchValue !== searchValue) {
        setLoading && setLoading(ELoadingType.IncrementalSearch);
        setPrevSearchValue(searchValue);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  // Handle Calendar
  useEffect(() => {
    const validateClickOutsideTheModal = (event: MouseEvent) => {
      if (
        showCalendar &&
        calendarRef.current &&
        !calendarRef.current.contains(event.target as Node)
      ) {
        setShowCalendar(false);
      }
    };

    document.addEventListener("mousedown", validateClickOutsideTheModal);

    return () => {
      document.removeEventListener("mousedown", validateClickOutsideTheModal);
    };
  }, [showCalendar, setShowCalendar]);

  // Handle Single Filter
  useEffect(() => {
    const localSingleFiltersForCountSearch: ILocalSingleFilter[] = localSingleFilters.map(i => {
      const escapedFilterValue = escapeControlCharacters(i.filter_value)
      return {
        ...i, value: escapedFilterValue.trim(),
      }
    })
    const localSingleFiltersForSearch: ILocalSingleFilter[] = localSingleFilters.map(i => {
      const escapedFilterValue = escapeControlCharacters(i.filter_value)
      return { ...i, value: escapedFilterValue.trim() }
    })
    setSelectedFilters && setSelectedFilters(handleSingleFilter(localSingleFiltersForSearch, props.convertFiltersToLower));
    setSelectedFiltersFunc && setSelectedFiltersFunc(handleSingleFilterFunc(localSingleFiltersForCountSearch, props.convertFiltersToLower));
    setCustomSelectedFilters &&
      customFilters &&
      setCustomSelectedFilters(handleSingleCustomFilter(localSingleFilters, customFilters));
  }, [localSingleFilters]);

  let currentDate = new Date();
  let last14Days = new Date(currentDate.getTime() - 14 * 24 * 60 * 60 * 1000);
  let initNewRangeDate = {
    startDate: new Date(last14Days.getFullYear(), last14Days.getMonth(), last14Days.getDate()),
    endDate: new Date(),
    key: "selection",
  };
  if (!predefinedDateRange) {
    initNewRangeDate = {
      startDate: dateRange?.startDate || new Date(),
      endDate: dateRange?.endDay || new Date(),
      key: "selection",
    };
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [newDateRange, setNewDateRange] = useState<any>([initNewRangeDate]);

  // handle calendar date range
  const handleNewDateRange = () => {
    setShowCalendar(false);

    setDateRange &&
      setDateRange({
        startDate: newDateRange[0].startDate,
        endDay: newDateRange[0].endDate,
        column_name: dateRange?.column_name || "",
      });
    setOffset && setOffset(0);
    setCurrentPage(1);
    setLoading && setLoading(ELoadingType.DateRange);
  };

  useEffect(() => {
    if (predefinedDateRange && !showCalendar && dateRange && newDateRange) {
      handleNewDateRange();
    }
  }, [newDateRange, setNewDateRange]);

  const removeSingleFilter = (id: string) => {
    const newFilters = localSingleFilters.filter((filter) => filter.id !== id);
    setLocalSingleFilters(newFilters);
  };

  const handleFilterLabel = (value: string): string => {
    const label = addFilterOptions?.find((val) => val.value === value)?.label || "";
    return label;
  };
  const displayFilter = (filter: ILocalSingleFilter): string => {
    const filterOpt = addFilterOptions?.find((opt) => opt.value === filter.filter_name);
    let displayValue;
    if (filterOpt?.valueOptions) {
      displayValue = filterOpt?.valueOptions.find(val => val.value === filter.filter_value)?.label;
      if (!displayValue)
        displayValue = filterOpt?.valueOptions.find(val => val.value === "")?.label;
    }
    return `${filterOpt?.label || ""} ${filterOpt?.op ? "" : `${filter.filter_op} ${displayValue || filter.filter_value}`}`;
  };

  const handlePredefinedDateRange = (selected: SelectOption[]) => {
    let currentDate = new Date();
    let fromDate: Date;
    let toDate: Date;

    let last7Days = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
    let last14Days = new Date(currentDate.getTime() - 14 * 24 * 60 * 60 * 1000);
    let last30Days = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000);
    let last90Days = new Date(currentDate.getTime() - 90 * 24 * 60 * 60 * 1000);
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth();
    let lastYear = currentYear - 1;
    let firstDayOfWeek = currentDate.getDate() - currentDate.getDay();
    let firstday = new Date(currentDate.setDate(firstDayOfWeek));
    let lastMonthLastDay = new Date();
    lastMonthLastDay.setDate(0);
    let lastMonthFirstDay = new Date(lastMonthLastDay);
    lastMonthFirstDay.setDate(1);

    const selectedValue = selected.find(opt => opt)?.value;
    setCurrentPredefinedFilterValue(selectedValue);
    switch (selectedValue) {
      case "Today":
        fromDate = new Date();
        toDate = new Date();
        setNewDateRange([{ startDate: fromDate, endDate: toDate, key: "selection" }]);
        break;
      case "Last 7 days":
        fromDate = new Date(last7Days.getFullYear(), last7Days.getMonth(), last7Days.getDate());
        toDate = new Date();
        setNewDateRange([{ startDate: fromDate, endDate: toDate, key: "selection" }]);
        break;
      case "Last 14 days":
        fromDate = new Date(last14Days.getFullYear(), last14Days.getMonth(), last14Days.getDate());
        toDate = new Date();
        setNewDateRange([{ startDate: fromDate, endDate: toDate, key: "selection" }]);
        break;
      case "Last 30 days":
        fromDate = new Date(last30Days.getFullYear(), last30Days.getMonth(), last30Days.getDate());
        toDate = new Date();
        setNewDateRange([{ startDate: fromDate, endDate: toDate, key: "selection" }]);
        break;
      case "Last 90 days":
        fromDate = new Date(last90Days.getFullYear(), last90Days.getMonth(), last90Days.getDate());
        toDate = new Date();
        setNewDateRange([{ startDate: fromDate, endDate: toDate, key: "selection" }]);
        break;
      case "Previous calendar month":
        fromDate = new Date(
          lastMonthFirstDay.getFullYear(),
          lastMonthFirstDay.getMonth(),
          lastMonthFirstDay.getDate()
        );
        toDate = new Date(
          lastMonthLastDay.getFullYear(),
          lastMonthLastDay.getMonth(),
          lastMonthLastDay.getDate()
        );
        setNewDateRange([{ startDate: fromDate, endDate: toDate, key: "selection" }]);
        break;
      case "Previous calendar year":
        fromDate = new Date(lastYear, 0, 1);
        toDate = new Date(lastYear, 11, 31);
        setNewDateRange([{ startDate: fromDate, endDate: toDate, key: "selection" }]);
        break;
      case "Week to date":
        fromDate = new Date(firstday.getFullYear(), firstday.getMonth(), firstday.getDate());
        toDate = new Date();
        setNewDateRange([{ startDate: fromDate, endDate: toDate, key: "selection" }]);
        break;
      case "Month to date":
        fromDate = new Date(currentYear, currentMonth, 1);
        toDate = new Date();
        setNewDateRange([{ startDate: fromDate, endDate: toDate, key: "selection" }]);
        break;
      case "Year to date":
        fromDate = new Date(currentYear, 0, 1);
        toDate = new Date();
        setNewDateRange([{ startDate: fromDate, endDate: toDate, key: "selection" }]);
        break;
      case "No date range":
        setNewDateRange([{ startDate: null, endDate: null, key: "selection" }]);
        break;
      case "Custom date range":
        setShowCalendar(true);
        break;
    }
  };

  return (
    <>
      {filters && (
        <>
          <div className="d-flex flex-xs-column flex-sm-column flex-md-row justify-content-between">
            <div className="d-flex flex-xs-column flex-md-row align-items-center position-relative mb-3">
              {predefinedDateRange && dateRange && (
                <div className="me-3">
                  <CustomSelect
                    onSelectionChanged={handlePredefinedDateRange}
                    options={dateRangeFilterOptions}
                    nonSearchDef="Last 14 days"
                    noClear={true}
                  />
                </div>
              )}
              {dateRange && !predefinedDateRange && (
                <Button
                  data-testid=""
                  className="btn btn-clean d-flex justify-content-between border me-2"
                  onClick={() => setShowCalendar(true)}
                  disabled={!dateRange && predefinedDateRange && !setDateRange}
                >
                  <p className="m-0 me-3">Filter by date range</p>
                  <i className="bi bi-calendar text-muted"></i>
                </Button>
              )}
              {showCalendar && (
                <div
                  className="table-floating-card d-flex flex-column align-items-start"
                  style={{ top: 50 }}
                  ref={calendarRef}
                >
                  <div className="table-floating-card__triangle bg-white ms-2"></div>
                  <div className="table-floating-card__container align-items-center bg-white position-relative p-3">
                    <DateRange
                      editableDateInputs={true}
                      onChange={(item) => setNewDateRange([item.selection])}
                      moveRangeOnFirstSelection={false}
                      ranges={newDateRange}
                      startDatePlaceholder={"Start Date"}
                      endDatePlaceholder={"End Date"}
                    />
                    <div className="d-flex justify-content-end">
                      <Button
                        data-testid=""
                        className="btn btn-primary"
                        onClick={handleNewDateRange}
                      >
                        Apply
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {addFilterOptions && addFilterOptions?.length > 0 && (
                <Button
                  data-testid=""
                  className="btn btn-clean d-flex"
                  onClick={() => setShowFilter(true)}
                // disabled={!setSelectedFilters || !setCustomSelectedFilters}
                >
                  <i className="bi bi-plus-square-fill me-2"></i>
                  <p className="m-0">Add Filter</p>
                </Button>
              )}
              {showFilter && setOffset && (
                <FilterDialog
                  addFilterOptions={addFilterOptions}
                  setOffset={setOffset}
                  setCurrentPage={setCurrentPage}
                  localSingleFilters={localSingleFilters}
                  setLocalSingleFilters={setLocalSingleFilters}
                  showFilter={showFilter}
                  setShowFilter={setShowFilter}
                  setLoading={setLoading}
                  extraFilterOps={extraFilterOps}
                />
              )}
            </div>
            <div className="input-search">
              {setSearchValue !== undefined && <Input
                type="text"
                title={searchHint ? searchHint : "search"}
                className="form-control"
                placeholder={searchPlaceHolder ? searchPlaceHolder : "search"}
                disabled={!setSearchValue}
                value={searchValue}
                onChange={(event) => {
                  setSearchValue2 && setSearchValue2(event.target.value);
                }}
                icon="search"
                loading={loading}
              />
              }
            </div>
          </div>
          <div className="mb-3">
            {dateRange?.startDate &&
              (!predefinedDateRange ||
                (predefinedDateRange && currentPredefinedFilterValue === "Custom date range")) &&
              setDateRange && (
                <span className="badge bg-light-gray display-6 text-dark me-2">
                  From: {formatDate(dateRange.startDate)}
                  <Button
                    data-testid=""
                    className="btn-clean pe-0"
                    onClick={() => {
                      setDateRange({ ...dateRange, startDate: null });
                      setLoading && setLoading(ELoadingType.DateRange);
                    }}
                  >
                    <i className="bi bi-x-lg"></i>
                  </Button>
                </span>
              )}
            {dateRange?.endDay &&
              (!predefinedDateRange ||
                (predefinedDateRange && currentPredefinedFilterValue === "Custom date range")) &&
              setDateRange && (
                <span className="badge bg-light-gray text-dark display-6 me-2">
                  To: {formatDate(dateRange.endDay)}
                  <Button
                    data-testid=""
                    className="btn-clean pe-0"
                    onClick={() => {
                      setDateRange({ ...dateRange, endDay: null });
                      setLoading && setLoading(ELoadingType.DateRange);
                    }}
                  >
                    <i className="bi bi-x-lg"></i>
                  </Button>
                </span>
              )}
            {localSingleFilters && (
              <>
                {localSingleFilters.map((filter) => (
                  <span key={filter.id} className="badge bg-light-gray text-dark display-6 me-2">
                    {/* {handleFilterLabel(filter.column)} {filter.attribute} {filter.value} */}
                    {displayFilter(filter)}
                    <Button
                      data-testid=""
                      className="btn-clean pe-0"
                      onClick={() => {
                        removeSingleFilter(filter.id);
                        setLoading && setLoading(ELoadingType.AddFilter);
                      }}
                    >
                      <i className="bi bi-x-lg"></i>
                    </Button>
                  </span>
                ))}
              </>
            )}
          </div>
        </>
      )}
      {loading && (loading === 200 || loading === 150) ? (
        <Card className="border-0 shadow-none p-0">
          <TablePlaceHolder columnNames={columnNames} numberOfRows={number_items_per_page} />
        </Card>
      ) : (
        <MultiSelectTable
          columnNames={columnNames}
          tableData={data}
          options={options || []}
          applyChanges={applyChanges}
          multiSelect={multiSelectTable}
          noShadow
          ref={ref}
          setOrderBy={setOrderBy}
          orderByOptions={orderByOptions}
          orderBy={orderBy}
          loading={loading}
          setLoading={setLoading}
        />
      )}
      {subTotal != undefined ? (<Footer data={subTotal} />) : ""}
      {totalRecords === 0 ? (
        <></>
      ) : (
        setOffset &&
        totalRecords &&
        totalRecords > 0 && (
          <div className="d-flex flex-xs-column flex-sm-column flex-md-row mt-3 align-items-center justify-content-between">
            <div className="table-pagination-info" role="status">
              <p className="text-dark d-flex">
                Showing <span className="mx-1 font-weight-medium">{(offset || 0) + 1}</span> to{" "}
                <span className="mx-1 font-weight-medium">{totalOfCountPerPage}</span> of{" "}
                <span className="mx-1 font-weight-medium">{addCommaFunc(totalRecords, 0)}{(totalRecords === maxCountLimitLargeData) && (<>+</>)}</span>{" "}
                entries
              </p>
            </div>
            <PaginationOffset
              elementsPerPage={number_items_per_page}
              totalOfElements={totalRecords}
              paginate={setCurrentPage}
              currentPage={currentPage}
              setOffset={setOffset}
              loading={loading}
              setLoading={setLoading}
            />
          </div>
        )
      )}
    </>
  );
});

Table.defaultProps = {
  multiSelectTable: false,
  setSearchValue: undefined,
  filters: false,
  applyChanges: undefined,
  options: [],
  dateRange: undefined,
  setDateRange: undefined,
  addFilterOptions: undefined,
  selectedFilters: undefined,
  setSelectedFilters: undefined,
  customFilters: undefined,
  setCustomSelectedFilters: undefined,
  loading: ELoadingType.None,
  setLoading: undefined,
  subTotal: undefined,
};

const filterMap = (filter: ILocalSingleFilter): string => {
  let condition: string = "";
  let value: any = `%${filter.filter_value}%`;
  switch (filter.filter_op) {
    case "starts with":
      condition = `"_like":`;
      value = `${filter.filter_value}%`;
      break;
    case "contains":
      condition = `"_ilike":`;
      break;
    case "does not contain":
      condition = `"_nilike":`;
      break;
    case "equal":
      condition = `"_eq":`;
      value = `${filter.filter_value}`;
      break;
    case "greater than":
      condition = `"_gt":`;
      value = `${filter.filter_value}`;
      break;
    case "greater than or equal":
      condition = `"_gte":`;
      value = `${filter.filter_value}`;
      break;
    case "less than":
      condition = `"_lt":`;
      value = `${filter.filter_value}`;
      break;
    case "less than or equal":
      condition = `"_lte":`;
      value = `${filter.filter_value}`;
      break;
    case "is empty":
      condition = `"_is_null":`;
      value = true;
      break;
    default:
      break;
  }
  return `{"${filter.filter_name}": {${condition} ${handleEscapeMetaChar(value)}}}`;
};

const handleEscapeMetaChar = (value: any) => {
  if (typeof value === 'string') {
    return JSON.stringify(value.replaceAll(
      "\\",
      "\\\\"))
  }
  return value
}

function handleSingleFilter(
  data: ILocalSingleFilter[],
  convertToLower: boolean = false
): string {
  if (data.length) {
    let value = "";
    data.forEach((f, index) => {
      let filter = convertToLower ? { ...f, value: f.filter_value.toLowerCase() } : f;
      value += `${filterMap(filter)}${data.length === index + 1 ? "" : ","}`;
    });
    return value;
  } else {
    return "";
  }
}

const filterMapFunc = (filter: ILocalSingleFilter): string => {
  let condition: string = "";
  let value: string = `'%${filter.filter_value}%'`;
  switch (filter.filter_op) {
    case "starts with":
      condition = `like`;
      value = `'${filter.filter_value}%'`;
      break;
    case "contains":
      condition = `ilike`;
      break;
    case "does not contain":
      condition = `not ilike`;
      break;
    case "equal":
      condition = `=`;
      value = `'${filter.filter_value}'`;
      break;
    case "greater than":
      condition = `>`;
      value = `'${filter.filter_value}'`;
      break;
    case "greater than or equal":
      condition = `>=`;
      value = `'${filter.filter_value}'`;
      break;
    case "less than":
      condition = `<`;
      value = `'${filter.filter_value}'`;
      break;
    case "less than or equal":
      condition = `<=`;
      value = `'${filter.filter_value}'`;
      break;
    case "is empty":
      condition = `is null`;
      value = ``;
      break;
    default:
      condition = `like`;
      break;
  }
  return `"${filter.filter_name} ${condition} ${value}"`;
};

function handleSingleFilterFunc(
  data: ILocalSingleFilter[],
  convertToLower: boolean = false
): string {
  if (data.length) {
    let value = "";
    data.forEach((f, index) => {
      let filter = convertToLower ? { ...f, value: f.filter_value.toLowerCase() } : f;
      value += `${filterMapFunc(filter)}${data.length === index + 1 ? "" : ","}`;
    });
    //   value += "}";
    return value;
  } else {
    return "";
  }
}

