import { FC } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { useArchivedTenantAdminUsersCountQuery, useTenantAdminUsersCountQuery } from "../../../generated/urql-graphql";
import { LinkTabsComponent } from "../../common/components/LinkTabsComponent";
import { Loader } from "../../common/components/Loader";
import { Button } from "../../forms/components/Button";
import ArchivedTenantsList from "./ArchivedTenantList";
import TenantDetails from "./TenantDetails";
import TenantsList from "./TenantsList";

export const TenantsRoute: FC = () => {
  const baseRoute = "/tenants";

  const [adminsCountData] = useTenantAdminUsersCountQuery({
    variables: {
      whereValue: { operator_name: { _ilike: '%%' }, operator_status: { _neq: "Archived" } }
    },
  });
  const [archivedAdminsCountData] = useArchivedTenantAdminUsersCountQuery({
    variables: {
      whereValue: { operator_name: { _ilike: '%%' }, operator_status: { _eq: "Archived" } }
    },
  });
  const totalRecords = adminsCountData.data?.total_admins.aggregate?.count;
  const archivedRecords = archivedAdminsCountData.data?.total_admins.aggregate?.count;

  if (adminsCountData.fetching || archivedAdminsCountData.fetching) {
    return <Loader />;
  }

  const tabs = [
    {
      tabName: "All Tenants",
      route: `${baseRoute}`,
      totalNumberOfElements: totalRecords,
      component: <TenantsList />,
    },
    {
      tabName: "Archived Tenants",
      route: `${baseRoute}/archived`,
      totalNumberOfElements: archivedRecords,
      component: (
        <ArchivedTenantsList />
      ),
    },
  ];
  return (
    <div>
      <Routes>
        <Route path="add-tenant" element={<TenantDetails isAddForm={true} />} />
        <Route path=":tenantParam" element={<TenantDetails isAddForm={false} />} />
      </Routes>
      <div>
        <div className="d-flex justify-content-between mb-3">
          <h1 className="page-title">Tenants</h1>
          <div className="d-flex flex-row align-items-end">
            <Link data-role="nav" to={{ pathname: `${baseRoute}/add-tenant` }} id="sellers-add-seller-link">
              <Button
                data-testid=""
                className="ms-2 btn btn-primary">
                Add Tenant
              </Button>
            </Link>
          </div>
        </div>
        <div>
          <LinkTabsComponent tabs={tabs} />
        </div>
      </div>
    </div>
  );
};