import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useGetSellerMessagesCountSubscription, useGetSellerMessagesSubscription } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { ELoadingType, IDateRange, OrderByType } from "../../common/types/types";
import { useMainContext } from "../../layout/components/MainProvider";

const SellerMessageList = () => {
  const userInfo = useUserInfo();
  const sellerId = userInfo.seller_id
  const [context] = useMainContext();

  const formatDate = useDateFormat();
  const navigate = useNavigate();
  const { number_items_per_page } = context.operatorSettings.preset;
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/seller-messages`;
  const offerRoute = `/${tenantUrlTag}/offers`;
  const orderRoute = `/${tenantUrlTag}/orders`;
  const columns = [
    "created_date",
    "customer_email",
    "order",
    "message",
    "actions"
  ];
  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "created_date",
  });
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [offset, setOffset] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "created date",
    column_name: "created_date",
    orderBy: "desc",
  });
  const [selectedCountFilters, setSelectedCountFilters] = useState<any>("");
  const [loading, setLoading] = useState<number>(-1);

  const orderByOptions: { [key: string]: string } = {
    created_date: "created_date",
    id: "id"
  }

  let handleWhereStr = handleWhere({
    columnSearch: handleColumnFilter(["customer_email"], searchValue.toLowerCase()),
    dateRange,
    selectedFilters
  });
  const addFilterOptions = [
    { label: "Customer Email", value: "customer_email" },
    { label: "Seller Item SKU", value: "seller_product_sku" },
    { label: "Order No.", value: "order_number_text" }
  ]
  const orderByStr = orderBy.column_name
    ? [{ [orderBy.column_name]: orderBy.orderBy }, { [orderByOptions.id]: "asc" }]
    : [{ [orderByOptions.Created_Date]: "desc" }, { [orderByOptions.id]: "asc" }];

  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      order_by: orderByStr,
      where: JSON.parse(handleWhereStr),
    },
  };

  const [queried] = useGetSellerMessagesSubscription(inputs)
  const [countQueried] = useGetSellerMessagesCountSubscription({ variables: { where: JSON.parse(handleWhereStr) } })

  const viewMessageThread = (data: any): void => {
    if (data.message_type == "Order") {
      navigate(`${orderRoute}/${data.order_id}`)
      return
    }
    navigate(`${baseRoute}/${data.id}`)
    return
  }
  useEffect(() => {
    if (queried.data) {
      setLoading(ELoadingType.None);
    }
  }, [queried.data]);

  const error = queried.error || countQueried.error
  console.log(queried.error, countQueried.error, handleWhereStr, "   err")
  if (error) {
    return <PageError error={{ source: "SellerMessageList", errMsg: error.message }} />;
  }

  if (!queried.data) {
    return (<Card>
      <PlaceholderTableSearchItem />
      <TablePlaceHolder
        columnNames={columns}
        numberOfRows={number_items_per_page}
      />
    </Card>);
  }

  const sellerMessageData = queried.data.vw_seller_message
  const sellerMessageCount = countQueried.data?.vw_seller_message_aggregate.aggregate?.count

  const tableData = sellerMessageData.map(sellerMessage => {
    const actions = [{
      enabled: true,
      actionType: "View",
      id: sellerMessage.id,
      display: "View",
      icon: `bi bi-eye`,
      actionFunc: () => viewMessageThread(sellerMessage),
    }]
    return {
      id: sellerMessage.id,
      created_date: <div className="message__date-created">
        <p className="mb-0">{formatDate(sellerMessage.created_date)}</p>
        <span className="small text-muted">{formatDate(sellerMessage.created_date, "time")}</span>
      </div>,
      customer_email:
        <div className="message__customer-email">
          {sellerMessage.customer_email}
        </div>,
      order: sellerMessage.order_id ? <NavLink
        data-role="nav"
        data-testid="nav-message"
        to={{ pathname: `${orderRoute}/${sellerMessage.order_id}` }
        }
        state={{ sellerId: sellerId }}
        className="nav-link text-truncate text-decoration-underline message__order-number">
        <span>{sellerMessage.order_number}</span>
      </NavLink > : <div className="message__order-number"></div>,
      message: <div className="message__text">
        {sellerMessage.message_from == "Seller" && <i style={{ cursor: "default" }} className="bi bi-person-gear pe-2"></i>}
        {sellerMessage.message_from == "Customer" && <i style={{ cursor: "default" }} className="bi bi-bell-fill text-success pe-2"></i>}
        {sellerMessage.message_text && sellerMessage.message_text.length > 150
          ? sellerMessage.message_text.slice(0, 150) + " ..."
          : sellerMessage.message_text}
      </div>,
      actions: actions.filter((item) => item.enabled).length > 0 ?
        <DropdownItems items={actions.filter((item) => item.enabled)} />
        : <></>
    }
  }) || []
  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <h1 className="page-title">Seller Messages</h1>
      </div>
      <Card>
        <Table
          setSearchValue={setSearchValue}
          columnNames={columns}
          data={tableData}
          searchHint="Search by Customer email, Seller Item SKU"
          searchPlaceHolder="Search by Customer..."
          offset={offset}
          setOffset={setOffset}
          filters
          dateRange={dateRange}
          setDateRange={setdateRange}
          addFilterOptions={addFilterOptions}
          setSelectedFiltersFunc={setSelectedCountFilters}
          setSelectedFilters={setSelectedFilters}
          convertFiltersToLower={true}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          orderByOptions={orderByOptions}
          extraFilterOps={["contains"]}
          totalRecords={sellerMessageCount}
          setLoading={setLoading}
          loading={loading}
        />
      </Card>
    </>
  )
}

export default SellerMessageList