import _ from "lodash";
import { useEffect, useState } from "react";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import {
  useCategoryResultsQuery,
  useCreateCategoryImportMutation,
  useFullpathCategoriesQuery,
  useRootCategoriesQuery,
  useUpsertCategoryMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { CustomSelect } from "../../common/components/CustomSelect";
import { Dialog } from "../../common/components/Dialog";
import { PlaceholderLists } from "../../common/components/PlaceholderLoaders";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { UploadSpreadsheet } from "../../common/components/UploadSpreadsheet";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { uploadImportFile } from "../../common/miscellaneous/storage";
import { emptyUuid, mutationInfo } from "../../common/miscellaneous/utility";
import { ISearchParam, MutationAction, SelectOption } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Form } from "../../forms/components/Form";
import { FormInput } from "../../forms/components/FormInput";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { ISheetData } from "../../products-import-route/utils/types";
import { HierarchyCategories } from "./HierarchyCategories";
import { Subcategories } from "./Subcategories";

export function CategoriesRoute(): JSX.Element {
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const { tenant_profit_model } = context.operatorSettings.preset;
  const { mng_ctg_attr_view } = context.operatorSettings.product;
  const { tenant_url_tag: tenantUrlTag } = context.operatorInfo;
  const mngCtgAttr = (BigInt(userInfo.permissions)! & BigInt(context.permissions.manage_ctg_n_attr))
    && mng_ctg_attr_view;
  const baseRoute = `/${tenantUrlTag}/settings`;
  const [action, setAction] = useState<string>("");
  const [sheetData, setSheetData] = useState<ISheetData | null>(null);
  const [{ fetching }, addMutation] = useUpsertCategoryMutation();
  const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([]);
  const defParam = { searchTerm: "%", limit: 15, offset: 0, total: 0, defaultValue: emptyUuid };
  const [searchParam, setSearchParam] = useState<ISearchParam>(defParam);
  const [ctgLimit, setCtgLimit] = useState<number>(15);
  const [categoryPath, setCategoryPath] = useState<string>("");
  const [, importMutation] = useCreateCategoryImportMutation();
  const [queried] = useFullpathCategoriesQuery({
    variables: _.omit(searchParam, "total"),
  });
  useEffect(() => {
    if (queried.data) {
      const categories =
        queried.data.category.map(
          (ctg) =>
          ({
            label: ctg.fullname,
            value: ctg.path,
          } as SelectOption)
        ) || [];
      setCategoryOptions(categories);
      setSearchParam({ ...searchParam, total: queried.data.categories.aggregate?.count || 0 });
    }
  }, [queried]);

  const [searchedQueried] = useCategoryResultsQuery({
    variables: { ids: categoryPath.split(",") },
  });

  const [ctgsQueried] = useRootCategoriesQuery({
    variables: { limit: ctgLimit, offset: 0 },
  });

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
  } = methods;

  if (!ctgsQueried.data)
    return (
      <>
        <div className="col-lg-12 col-xl-8 mx-auto">
          <div className="manage-categories-header">
            <ReturnHeader
              title="Manage Categories"
              url={baseRoute}
              description="The global attributes and commission fees of the top parent category are inherited by child categories."
            ></ReturnHeader>
          </div>
          <Card className=" pt-4">
            <PlaceholderLists />
          </Card>
        </div>
      </>
    );

  const cleanup = () => {
    setAction("");
    setSheetData(null);
  }

  const continueAdd = (isContinue: boolean) => {
    if (!isContinue) cleanup();
  };

  const continueImport = async (isContinue: boolean) => {
    if (!isContinue)
      cleanup();
    else {
      if (!sheetData) return;
      const url = await uploadImportFile(sheetData.file);
      if (url) {
        const importInfo = {
          filename: sheetData.file?.name,
          filepath_storage: url,
          sheet_name: sheetData.sheetName,
          operator_id: userInfo.operator_id
        };
        const res = await importMutation({ importInfo });
        alertsRef.current?.generate(mutationInfo("category import info", MutationAction.Create, res));
        if (res.data) cleanup();
      }
    };
  }

  const onSubmit = async (formData: FieldValues) => {
    const category = {
      ...formData,
      commission_rate: formData.commission_rate || 0,
      markup_rate: formData.markup_rate || 0,
      operator_id: userInfo.operator_id
    };
    const res = await addMutation(
      { category },
      { additionalTypenames: ["category", "category_aggregate"] }
    );
    if (!res.error) {
      reset();
      setAction("");
    }
    alertsRef.current?.generate(mutationInfo("category", MutationAction.Update, res));
  };
  const searchedCtgs = searchedQueried.data?.category;
  const ctgs = ctgsQueried.data?.category;
  const total = ctgsQueried.data.categories?.aggregate?.count || 0;
  const importReady = sheetData?.rowData?.header.some(item =>
    ["commission_rate", "category1", "category2"].includes(item))
    && sheetData?.rowData?.bodyRows.length;
  return (
    <div className="row">
      <div className="col-lg-12 col-xl-8 mx-auto">
        <div className="manage-categories-header">
          <ReturnHeader
            title="Manage Categories"
            url={baseRoute}
            description="The global attributes and commission fees of the top parent category are inherited by child categories."
          >
            <>
              {mngCtgAttr && <div className="navbar-expand-xl d-flex flex-column align-items-end">
                <Button
                  data-testid="toggle-button"
                  className="btn btn-light navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarCategoriesContent"
                  aria-controls="navbarCategoriesContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="bi bi-three-dots-vertical"></span>
                </Button>
                <div className="navbar-collapse collapse" id="navbarCategoriesContent">
                  <ul className="navbar-nav ms-xl-auto">
                    <li className="nav-items">
                      <Button
                        data-testid=""
                        className="btn btn-light me-3"
                        onClick={() => setAction("import")}
                      >
                        Import
                      </Button>
                    </li>
                    <li className="nav-items">
                      <Button
                        data-testid=""
                        className="btn btn-primary"
                        onClick={() => setAction("add")}
                      >
                        Add Category
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>}
            </>
          </ReturnHeader>
        </div>
        <div className="search-category-wrapper mt-3">
          <CustomSelect
            options={categoryOptions}
            placeholder="Search by category name"
            search={{ searchParam, setSearchParam }}
            onSelectionChanged={(options: SelectOption[]) => {
              const selected = options.find(opt => opt)?.value || "";
              setCategoryPath(Array.isArray(selected) ? selected.join(",") : selected)
            }}
          />
        </div>
        <Card className="category-tree mt-3">
          {categoryPath && searchedCtgs ? (
            <HierarchyCategories level={0} categories={searchedCtgs} />
          ) : (
            <Subcategories
              level={0}
              categories={ctgs}
              totalCtgs={total}
              subOpen={true}
              handleShowMore={() => setCtgLimit(ctgLimit + 15)}
              fetching={ctgsQueried.fetching}
            />
          )}
        </Card>
      </div>
      <Dialog
        title="Add Category"
        show={action === "add"}
        subFormId="add-category-form"
        continueText="Add"
        staticModal={true}
        continue={continueAdd}
        fetching={fetching}
      >
        <FormProvider {...methods}>
          <Form
            data-testid="add-category-form"
            id="add-category-form"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            className={errors && Object.keys(errors).length !== 0 ? "was-validated" : ""}
          >
            <div className="form-group">
              <FormInput
                name="name"
                label="Category Name"
                reg_options={{ required: true, maxLength: 80 }}
              />
              {tenant_profit_model === "Markup" ?
                <>
                  {/*
                  <FormInput
                    type="number"
                    name="markup_rate"
                    label="Markup Rate"
                    reg_options={{
                      min: 0,
                      max: 100,
                      pattern: /^\d+(\.\d{0,2})?$/,
                    }}
                    measurelabel="%"
                  />
                  */}
                </>
                :
                <FormInput
                  type="number"
                  name="commission_rate"
                  label="Commission Rate"
                  reg_options={{
                    min: 0,
                    max: 100,
                    pattern: /^\d+(\.\d{0,2})?$/,
                  }}
                  measurelabel="%"
                />
              }
            </div>

          </Form>
        </FormProvider>
      </Dialog>
      <Dialog
        title="Import Category"
        show={action === "import"}
        continueText="Import"
        staticModal={true}
        continue={continueImport}
        disableAction={!importReady}
        fetching={fetching}
      >
        <div>
          <div>
            <div>Category file must include commission_rate, category1 and category2 columns. </div>
            <div>Every row stands for a full path of product category.</div>
          </div>
          <UploadSpreadsheet
            setSheetData={setSheetData}
            stopParsing={!!sheetData?.rowData}
          />
          {sheetData?.rowData && !importReady &&
            <div className="text-danger">
              <i className="bi bi-info-circle me-1"></i>
              <small>Invalid sheet columns or data</small>
            </div>}
        </div>
      </Dialog>
    </div>
  );
}

