import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Vw_Seller,
  useAddSellerStatusMutation,
  useAllSellersCountQuery,
  useAllSellersQuery,
  useDeletePendingSellerMutation,
  useRemoveSellerStatusMutation,
  useUpdateCloseStatusMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems, { ActionItemType } from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { badgeCss, mutationInfo } from "../../common/miscellaneous/utility";
import { ELoadingType, IActionState, IDateRange, IRef, MutationAction, OrderByType } from "../../common/types/types";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { handleTitle } from "../handlers/tableHandlers";
import { getActionProp, sellerActionData } from "../miscellaneous/types";
import { CloseStore } from "./CloseStore";

interface AllSellersProps {
  refreshCount: () => void;
}

export const AllSellers: FC<AllSellersProps> = (props): ReturnType<FC> => {
  const [context] = useMainContext();
  const { refreshCount } = props;
  const [loading, setLoading] = useState<number>(-1);
  const formatDate = useDateFormat();

  const { number_items_per_page } = context.operatorSettings.preset;
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/sellers`;
  const multiSelectRef = useRef<IRef | null>(null);
  const [, closeMutation] = useUpdateCloseStatusMutation();
  const [offset, setOffset] = useState<number>(0);
  const defState = { ids: null, action: "" };
  const [actionState, setActionState] = useState<IActionState>(defState);
  const userInfo = useUserInfo()!;
  const manageSeller = userInfo.permissions! & context.permissions.manage_seller;
  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "created_date",
  });
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "seller",
    column_name: "company_name",
    orderBy: "asc",
  });
  const orderByOptions: { [key: string]: string } = {
    created: "created_date",
    seller: "company_name",
    location: "city",
    orders: "orders",
    offers: "offers",
    status: "status_cd",
    id: "id"
  };

  const statusActions: { [key: string]: string[] } = {
    Pending: ["edit", "remove_pending", "add_archived"],
    Open: ["edit", "set_closed", "add_suspended", "add_archived"],
    Closed: ["edit", "set_unclosed", "add_suspended", "add_archived"],
    Suspended: ["edit", "remove_suspended", "add_archived"],
  };

  const addFilterOptions = [
    { label: "Seller", value: "company_name_lower" },
    { label: "City", value: "city_lower" },
    { label: "State", value: "state_lower" },
    {
      label: "Status",
      value: "status_cd",
      valueOptions:
        [
          { label: '', value: '' },
          { label: 'Pending', value: 'Pending' },
          { label: 'Open', value: 'Open' },
          { label: 'Suspended', value: 'Suspended' },
          { label: 'Closed', value: 'Closed' },
          { label: 'Archived', value: 'Archived' },
        ]
    }
  ];

  const columnNames = ["created", "seller", "location", "orders", "offers", "status", "action"];
  const placeholderColumnNames = [
    "created",
    "seller",
    "location",
    "orders",
    "offers",
    "status",
    "action",
  ];
  const bulkActions = ["Open Seller", "Archive Seller"];
  const customRule = `,"status_cd":{"_nin":"Archived"}`;
  const tableColumns = ["status_cd", "company_name", "state", "city"];

  let handleWhereStr = handleWhere({
    columnSearch: handleColumnFilter(tableColumns, searchValue),
    dateRange,
    selectedFilters,
    customRule,
  });

  const orderByStr = orderBy.column_name
    ? [{ [orderBy.column_name]: orderBy.orderBy }, { [orderByOptions.id]: "asc" }]
    : [{ [orderByOptions.seller]: "asc" }, { [orderByOptions.id]: "asc" }];

  const checkCurrentSellersCount = () => {
    return multiSelectRef.current?.selectedData && multiSelectRef.current?.selectedData.length > 1;
  };

  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      where: JSON.parse(handleWhereStr),
      order_by: orderByStr
    },
  };

  const [sellersSubscribed] = useAllSellersQuery(inputs);

  const [totalSellers] = useAllSellersCountQuery({
    variables: {
      where: JSON.parse(handleWhereStr)
    },
  });
  const [, addStatusMutation] = useAddSellerStatusMutation();
  const [, removeStatusMutation] = useRemoveSellerStatusMutation();
  const [, deleteMutation] = useDeletePendingSellerMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (sellersSubscribed.data) {
      setLoading(ELoadingType.None);
    }
  }, [sellersSubscribed.data]);

  const error = sellersSubscribed.error || totalSellers.error;
  if (error) {
    return <PageError error={{ source: "AllSellers", errMsg: error.message }} />;
  }

  if (!sellersSubscribed.data || !totalSellers.data
  ) {
    return (
      <Card>
        <PlaceholderTableSearchItem />
        <TablePlaceHolder
          columnNames={placeholderColumnNames}
          numberOfRows={number_items_per_page}
        />
      </Card>
    );
  }

  const sellerData = sellersSubscribed.data.vw_seller;

  const totalSellersCount = totalSellers.data.vw_seller_aggregate.aggregate?.count;

  const applyChanges = () => {
    const ids = multiSelectRef.current?.selectedData;
    const bulkAction = multiSelectRef.current?.selectedOption;
    if (ids && ids.length !== 0 && bulkAction)
      setActionState({
        ids,
        action: bulkAction === "Archive Seller" ? "add_bulk_archived" : "remove_bulk_pending",
      });
    return true;
  };

  const addActions = (id: string, actionNames: string[]) => {
    const actions: ActionItemType[] = [];
    actionNames?.forEach((name) => {
      const actionProp = sellerActionData.find((data) => data.action === name);
      actions.push({
        actionType: name,
        display: actionProp?.display! ?? name,
        id: id,
        icon: `bi ${actionProp?.icon}`,
        actionFunc: () => {
          if (name === "edit") navigate(`${baseRoute}/${id}`);
          else
            setActionState({
              action: name,
              ids: [id],
            });
        },
      });
    });
    return actions;
  };

  if (manageSeller) tableColumns.push("Action");

  const tableData = sellerData.map((seller: Vw_Seller) => {
    const actionNames = statusActions[seller.status_cd || "Pending"];
    const actions = addActions(seller.id, actionNames);
    return {
      id: seller.id,
      created: (
        <div className="date-created">
          <p className="mb-0">{formatDate(seller.created_date)}</p>
          <span className="small text-muted">{formatDate(seller.created_date, "time")}</span>
        </div>
      ),
      seller: (
        <NavLink
          data-role="nav"
          data-testid="nav-message"
          to={`${baseRoute}/${seller.id}`}
          className="nav-link text-decoration-underline"
          title={seller.company_name || ""}
        >
          {handleTitle(seller.company_name || "", 40)}
        </NavLink>
      ),
      location: (
        <div className="location">
          <p className="text-truncated text-capitalize mb-0">
            {handleTitle(seller.city || "", 15)}, {handleTitle(seller.state || "", 15)}
          </p>
        </div>
      ),
      orders: seller.orders,
      offers: seller.offers,
      status: (
        <span className={badgeCss(seller.status_cd)}>
          {seller.status_cd}
        </span>
      ),
      action: <DropdownItems items={actions.filter((action) => !action.disabled)} />,
    };
  });

  const continueAction = async (isContinue: boolean) => {
    const action = actionState.action;
    let ids = actionState.ids;
    const sellerId = ids?.find((id) => id);
    if (isContinue) {
      let res;
      if (action === "delete") {
        res = await deleteMutation({ sellerId }, { additionalTypenames: ["vw_seller", "vw_seller_aggregate"] });
      } else if (action.includes("add_")) {
        const key = action.replace(/add_bulk_|add_/, "");
        let obj;
        if (key === "suspended") {
          obj = { suspended: true };
        } else if (key === "archived") {
          obj = {
            archived: {
              at: new Date().toISOString(),
              by: userInfo.user_id,
            },
          };
        }
        res = await addStatusMutation({
          sellerIds: ids,
          existingKey: key,
          statusObj: obj,
        }, { additionalTypenames: ["vw_seller", "vw_seller_aggregate"] });
      } else if (action.includes("remove_")) {
        const key = action.replace(/remove_bulk_|remove_/, "");
        res = await removeStatusMutation({ sellerIds: ids, statusKey: key }
          , { additionalTypenames: ["vw_seller", "vw_seller_aggregate"] });
      } else if (action.includes("set_unclosed")) {
        res = await closeMutation({
          sellerId,
          closeStatus: null,
        }, { additionalTypenames: ["vw_seller", "vw_seller_aggregate"] });
      }

      if (res?.data && !res?.error) {
        setActionState(defState);
      }
      alertsRef.current?.generate(mutationInfo("seller(s) status", MutationAction.Update, res));
    } else {
      setActionState(defState);
    }
  };

  const continueClose = (isContinue: boolean) => {
    if (!isContinue) {
      setActionState(defState);
    }
  };

  return (
    <>
      <Card>
        <Table
          setSearchValue={setSearchValue}
          columnNames={columnNames}
          data={tableData}
          options={bulkActions}
          applyChanges={applyChanges}
          offset={offset}
          setOffset={setOffset}
          totalRecords={totalSellersCount || 0}
          ref={multiSelectRef}
          multiSelectTable
          filters
          dateRange={dateRange}
          setDateRange={setdateRange}
          addFilterOptions={addFilterOptions}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setOrderBy={setOrderBy}
          orderByOptions={orderByOptions}
          orderBy={orderBy}
          loading={loading}
          setLoading={setLoading}
          searchHint="Search by Seller, Status, City, State"
          searchPlaceHolder="Search by Seller, ..."
        />
      </Card>
      <Dialog
        show={!["set_closed", ""].includes(actionState.action)}
        title={getActionProp(actionState.action, "dlgTitle")}
        continueText={getActionProp(actionState.action, "display")}
        continue={continueAction}
        contineBtnCss={getActionProp(actionState.action, "css")}
      >
        <div className="dlg-msg">{getActionProp(actionState.action, "dlgMsg")}</div>
      </Dialog>
      <Dialog
        show={actionState.action.includes("set_closed")}
        title={"Close seller"}
        continueText="Close seller"
        subFormId="store-close-form"
        contineBtnCss={"btn-danger"}
        continue={continueClose}
        size="lg"
      >
        <CloseStore
          sellerId={actionState.ids?.find((id) => id)!}
          resetState={() => setActionState(defState)}
        />
      </Dialog>
    </>
  );
};
