import { FC } from "react";
import { CategoryPartFragment } from "../../../generated/urql-graphql";
import { ISetProdCtg } from "../types/types";
import { Category } from "./Category";

interface IProps {
  categories: CategoryPartFragment[];
  level: number;
  subOpen?: boolean;
  fetching?: boolean;
  totalCtgs?: number;
  handleShowMore?: () => void;
  setProdCtg?: ISetProdCtg
}

export const Subcategories: FC<IProps> = ({
  categories,
  level,
  subOpen,
  fetching,
  totalCtgs,
  handleShowMore,
  setProdCtg
}: IProps): ReturnType<FC> => {
  return (
    <div>
      {categories.length > 0 && subOpen && (
        <div className={`${level !== 0 ? "is-child" : ""}`}>
          <div className={`level-${level}`}>
            {categories
              ?.sort((ch1, ch2) => ch1.name.localeCompare(ch2.name))
              .map((ctg) => (
                <Category key={ctg.id} category={ctg} level={level + 1} setProdCtg={setProdCtg} />
              ))}
          </div>
          {totalCtgs && totalCtgs > categories.length && (
            <div className="show-more-wrapper d-flex align-items-center m-2 ps-2">
              <span className="small">
                Showing {categories.length} out of {totalCtgs} categories
              </span>
              <button
                data-role="button"
                className="btn btn-sm btn-link" onClick={handleShowMore}>
                {fetching ? (
                  <div className="spinner-border spinner-border-sm" />
                ) : (
                  <span>Show more</span>
                )}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
