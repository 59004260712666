import { useAuth0 } from "@auth0/auth0-react";
import {
  createRef,
  FC,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import { NavLink, Outlet } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  useUserProfileSubSubscription
} from "../../../generated/urql-graphql";
import { Loader } from "../../common/components/Loader";
import { ToastAlert } from "../../common/components/ToastAlert";
import { UserNotification } from "../../common/components/UserNotification";
import { alertsValue, IAlert, sidebarState, useRoleList, useUserProfile } from "../../common/hooks/globals";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { isDebug, isNullOrEmpty } from "../../common/miscellaneous/utility";
import { IAlertInfo } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { Input } from "../../forms/components/Input";
import { useMainContext } from "../../layout/components/MainProvider";
import { EmulationPrompt } from "../../users-route/components/EmulationPrompt";
import { SellerStores } from "./SellerStores";
export const alertsRef = createRef<{
  add: (message: string, type: "success" | "error") => void,
  generate: (info: IAlertInfo) => void
}>();

const Main: FC = (): ReturnType<FC> => {
  const [alerts, setAlerts] = useRecoilState<IAlert[]>(alertsValue);
  const userInfo = useUserInfo();
  const userProfile = useUserProfile();
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo?.tenant_url_tag ?? "";
  const emulating = !isNullOrEmpty(userInfo.emulater);
  const userIdInput = {
    variables: {
      userId: userInfo.user_id,
    },
  };

  const [userProfileLive] = useUserProfileSubSubscription(userIdInput);
  const url = window.location;
  let logoutUrl = `${url.protocol}//${url.hostname.toLowerCase()}`;
  if (url.port != null && url.port !== "")
    logoutUrl = `${logoutUrl}:${url.port}`;
  logoutUrl = `${logoutUrl}/logout?tenantUrlTag=${context.operatorInfo.tenant_url_tag}`;

  //get rolelist
  useRoleList();

  useEffect(() => {
    const func = () => {
      const scrollButton: HTMLElement | null = document.querySelector(".back-to-top");
      if (window.pageYOffset > 0 && scrollButton) {
        scrollButton.style.visibility = "visible";
      }
      if (!window.pageYOffset && scrollButton) scrollButton.style.visibility = "hidden";
    };
    window.addEventListener("scroll", func);

    return () => {
      window.removeEventListener("scroll", func);
    };
  }, []);

  const addAlert = (message: string, type: "success" | "error") => {
    setAlerts([
      ...alerts,
      {
        id: Math.floor(Math.random() * 10000 + 1),
        message,
        autoClose: true,
        type,
      },
    ]);
  };

  const mainRef = useRef<HTMLElement>(null);

  useImperativeHandle(alertsRef, () => ({
    add: (message: string, type: "success" | "error") => {
      addAlert(message, type);
    },
    generate: (info: IAlertInfo) => {
      addAlert(info.message, info.type);
      info.errMsg && !isDebug() && console.warn(info.errMsg);
    }
  }));

  const [search, setSearch] = useState<boolean>(false);
  const [sidebar, setSidebar] = useRecoilState(sidebarState);
  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", function () {
    setWidth(window.innerWidth);
  });

  const deleteAlert = useCallback(
    (id: number) => setAlerts((alert) => alert.filter((element) => element.id !== id)),
    [setAlerts]
  );

  const { user, logout } = useAuth0();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const validateClickOutsideTheModal = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        ref.current?.classList.remove("show");
      }
    };

    document.addEventListener("mousedown", validateClickOutsideTheModal);

    return () => {
      document.removeEventListener("mousedown", validateClickOutsideTheModal);
    };
  }, []);

  const escFunction = useCallback((event: any) => {
    if (event.keyCode === 27) {
      setSearch(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction);

    return () => {
      document.removeEventListener("keydown", escFunction);
    };
  }, [escFunction]);

  if (!userInfo.user_id) {
    return <Loader />;
  }
  if (!userProfileLive.data) {
    return <Loader />;
  }
  const userProfileInfo = userProfileLive.data.user[0];
  const fullName =
    (userProfileInfo.first_name
      ? `${userProfileInfo.first_name}  ${userProfileInfo.last_name}`
      : user?.name) || userInfo.email;
  const userRole = userInfo.info_obj.role_name;
  const avatar = userProfile.profile_image || (!userInfo.emulater && user?.picture);

  return (
    <main
      className={`flex-grow-1 main-container d-flex flex-column mainContainer ${width <= 1200 && sidebar
        ? "collapse-left-padding"
        : width <= 1200 && !sidebar
          ? "with-sidebar"
          : width >= 1200 && !sidebar
            ? "collapse-left-padding"
            : ""
        }`}
      id="mainContainer"
      ref={mainRef}
    >
      <div className="position-relative">
        <header
          className={`position-fixed toolbar ${width <= 1200 && sidebar
            ? "sidebar-active collapsed"
            : width >= 1200 && !sidebar
              ? "sidebar-collapsed"
              : !sidebar
                ? "sidebar-active"
                : "sidebar-active"
            } top-navbar navbar-expand-md`}
        >
          <EmulationPrompt />
          <div className="d-flex p-3 pe-4 shadow-sm bg-white align-items-center">
            {search ? (
              <div className="d-flex justify-content-between w-100 align-items-center">

                <div className="d-flex align-items-center vw-100 me-2">
                  <i className="bi bi-search display-3 me-3"></i>
                  <Input
                    data-testid=""
                    ref={null}
                    className="form-control search-input"
                    placeholder="Search MarketPush …"
                  />
                </div>

                <div className="d-flex align-items-center">
                  <i className="bi bi-mic-fill display-5 me-2"></i>
                  <Button data-testid="" className="btn-clean" onClick={() => setSearch(false)}>
                    <i className="bi bi-x display-1 me-3"></i>
                  </Button>
                </div>

              </div>
            ) : (
              <>
                <div className="me-auto">
                  <Button data-testid="" className="btn-clean" onClick={() => setSidebar(!sidebar)}>
                    <i className="bi bi-list display-2"></i>
                  </Button>
                </div>
                <div className="d-flex">
                  <div
                    id="navbarContent"
                    className="align-items-center justify-content-end navbar-collapse collapse"
                  >
                    {userInfo.seller_id && !emulating && <SellerStores />}
                    <UserNotification userId={userInfo.user_id} />
                  </div>
                  <Button
                    data-testid=""
                    className="btn btn-clean justify-content-end d-block d-md-none me-2"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarContent"
                  >
                    <i className="bi bi-three-dots"></i>
                  </Button>
                  <div
                    role="button"
                    onClick={() => ref.current?.classList.add("show")}
                    className="position-relative"
                  >
                    <div className="user-menu">
                      {avatar ? (
                        <img
                          referrerPolicy="no-referrer"
                          src={avatar}
                          alt="user profile"
                          className="user-image ms-2"
                          onError={(event) => {
                            event.currentTarget.src = "/default-user-avatar.jpeg";
                          }}
                        />
                      ) : (
                        <i className="bi bi-person-circle display-1"></i>
                      )}
                      <div className="online-badge bg-success position-absolute"></div>
                      <div
                        className="dropdown-menu dropdown-menu-right mt-4 dropdown-menu-md shadow user-menu"
                        aria-labelledby="dropdownMenuButton"
                        ref={ref}
                      >
                        <div className="text-left text-muted px-3 py-2 d-flex">
                          {avatar ? (
                            <img
                              src={avatar}
                              alt="user profile"
                              className="user-image me-2"
                              onError={(event) => {
                                event.currentTarget.src = "/default-user-avatar.jpeg";
                              }}
                            />
                          ) : (
                            <i className="bi bi-person-circle display-1 me-2"></i>
                          )}
                          <div>
                            <h5 className="username display-5 mb-0 font-weight-medium">
                              {`${fullName}`}
                            </h5>
                            <small className="text-muted">{userRole}</small>
                          </div>
                        </div>
                        <div className="dropdown-divider mt-0"></div>
                        {!(userInfo.emulater && userInfo.info_obj.role_name === "MP Admin") &&
                          <NavLink
                            data-role="nav"
                            data-testid=""
                            to={`/${tenantUrlTag}/users/profile`}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              ref.current?.classList.remove("show");
                              event.stopPropagation();
                            }}
                          >
                            <Button data-testid="btnUserProfile" className="dropdown-item btn-clean">
                              <i className="bi bi-person-circle me-3"></i>
                              User Profile
                            </Button>
                          </NavLink>
                        }
                        {(userInfo.permissions! & context.permissions.access_user) > 0 &&
                          <NavLink
                            data-role="nav"
                            data-testid="Nav-manage-users"
                            to={`/${tenantUrlTag}/users`}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              ref.current?.classList.remove("show");
                              event.stopPropagation();
                            }}
                          >
                            <Button data-testid="" className="dropdown-item btn-clean">
                              <i className="bi bi-people-fill me-3"></i>
                              Manage Users
                            </Button>
                          </NavLink>
                        }
                        {userInfo.user_level! >= 10 && userInfo.user_level! < 30 && (
                          <NavLink
                            data-role="nav"
                            data-testid="Nav-support"
                            to={`/${tenantUrlTag}/support`}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              ref.current?.classList.remove("show");
                              event.stopPropagation();
                            }}
                          >
                            <Button data-testid="" className="dropdown-item btn-clean">
                              <i className="bi bi-life-preserver me-3"></i>
                              Support
                            </Button>
                          </NavLink>
                        )}
                        {userInfo.user_level! >= 20 && userInfo.user_level! < 30 && (
                          <NavLink
                            data-role="nav"
                            data-testid=""
                            to={`/${tenantUrlTag}/settings`}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              ref.current?.classList.remove("show");
                              event.stopPropagation();
                            }}
                          >
                            <Button data-testid="" className="dropdown-item btn-clean">
                              <i className="bi bi-gear me-3"></i>
                              Settings
                            </Button>
                          </NavLink>
                        )}
                        {userInfo.user_level! >= 30 && (
                          <NavLink
                            data-role="nav"
                            data-testid="Nav-settings"
                            to={`/${tenantUrlTag}/administration`}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                              ref.current?.classList.remove("show");
                              event.stopPropagation();
                            }}
                          >
                            <Button data-testid="" className="dropdown-item btn-clean">
                              <i className="bi bi-tools me-3"></i>
                              Administration
                            </Button>
                          </NavLink>
                        )}
                        <div className="dropdown-divider"></div>
                        <Button
                          data-testid=""
                          className="dropdown-item btn-clean mb-2"
                          onClick={() => {
                            window.localStorage.removeItem("tenantName");
                            logout({
                              logoutParams: {
                                returnTo: logoutUrl
                              }
                            });
                            window.localStorage.setItem("user-login", "false");
                          }}
                        >
                          <i className="bi bi-box-arrow-right me-3"></i>
                          Log out
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </header>
        <div className="toast-alert mx-3">
          <div className="toast-alert__container d-flex flex-column-reverse">
            {alerts.map((alert) => {
              return <ToastAlert key={alert.id} deleteAlert={deleteAlert} data={alert} />;
            })}
          </div>
        </div>
      </div >
      <div className="pt-5 px-4">
        <Outlet />
      </div>
    </main >
  );
};

export default Main;

