import { FC, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Vw_Report_Sales_By_Product_Order_By, useSalesByProductQuery } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { ELoadingType, IRef, OrderByType } from "../../common/types/types";
import { useMainContext } from "../../layout/components/MainProvider";
import { ColumnAlignLeft } from "./ColumnAlignEnd";
import { ZeroedOutMoney } from "./ZeroedOutMoney";
interface IProp {
  handleCallBack: (where: string,
    orderBy: Vw_Report_Sales_By_Product_Order_By | Vw_Report_Sales_By_Product_Order_By[]) => void;
};

export const SalesByProductData: FC<IProp> = (props) => {
  const [loading, setLoading] = useState<number>(-1);
  const { handleCallBack } = props;
  const userInfo = useUserInfo()!;
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/products`;

  const { number_items_per_page } = context.operatorSettings.preset;
  const [offset, setOffset] = useState<number>(0);
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "Gross Sales",
    column_name: "gross_sale",
    orderBy: "desc",
  });
  const orderByOptions: { [key: string]: string } = {
    Product: "product_title",
    Manufacturer: "manufacturer",
    Orders: "order_count",
    Total_Sales: "total_sale",
    Gross_Sales: "gross_sale",
    Refund_Sales: "gross_adjust",
  };
  const tableColumnsToSearch: string[] = [
    "product_mpn_lower",
    "product_title",
    "product_description",
    "product_category",
    "manufacturer"
  ];

  const tableColumns = [
    "Product",
    "Manufacturer",
    "Orders",
    "Gross Sales",
    "Refund Sales",
    "Total Sales",
  ];
  const orderByString = orderBy.column_name
    ? { [orderBy.column_name]: orderBy.orderBy }
    : { [orderByOptions.Gross_Sales]: "desc" };

  const customRule = `,"operator_id": {"_eq":"${userInfo.operator_id}"}`;
  let handleWhereStr = handleWhere({
    columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue),
    selectedFilters,
    customRule
  });
  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      order_by: orderByString,
      where: JSON.parse(handleWhereStr),
    },
  };

  const [queried] = useSalesByProductQuery(inputs);
  const multiSelectRef = useRef<IRef | null>(null);
  useEffect(() => {
    if (queried.data) {
      setLoading(ELoadingType.None);
    }
  }, [queried.data]);

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "SalesBySeller", errMsg: error.message }} />;
  }

  const rows = queried.data?.vw_report_sales_by_product;
  const totalRows = queried.data?.items?.aggregate?.count ?? 0;

  handleCallBack(handleWhereStr, orderByString);
  const tableData = rows?.map((row) => {
    return {
      id: row.product_mpin ?? "MISSING ID",
      Product: (
        <ColumnAlignLeft className="product-mpin">
          <div className="product-details">
            <NavLink
              data-role="nav"
              data-testid="nav-message"
              to={`${baseRoute}/${row.id}`}
              className="nav-link text-wrap text-decoration-underline product-title pb-0 pt-0"
            >
              {row.product_title}
            </NavLink>
            <p className="product-description small text-muted text-truncate mb-0">
              {row.product_description}
            </p>
            <div className="product-category d-flex align-items-center text-muted mb-0">
              <span className="category-wrapper font-weight-semi-bold small me-1">Category:</span>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <span className="small">{row.product_category}</span>
                  </li>
                </ol>
              </nav>
            </div>
            <small className="text-muted">
              <span className="font-weight-semi-bold me-1">MPN:</span>
              {row.product_mpn}
            </small>
          </div>
        </ColumnAlignLeft>
      ),
      Manufacturer: (
        <ColumnAlignLeft className="product-mpin">
          <div className="flex-1">
            <p>{row.manufacturer}</p>
          </div>
        </ColumnAlignLeft>
      ),
      Orders: <ColumnAlignLeft className="order-total">{row.order_count}</ColumnAlignLeft>,
      Gross_Sales: (
        <ColumnAlignLeft className="gross-sales">
          <ZeroedOutMoney amount={row.gross_sale ?? 0} />
        </ColumnAlignLeft>
      ),
      Refund_Sales: (
        <ColumnAlignLeft className="refund-sales">
          <ZeroedOutMoney amount={row.gross_adjust ?? 0} />
        </ColumnAlignLeft>
      ),
      Total_Sales: (
        <ColumnAlignLeft className="total-sales">
          <ZeroedOutMoney amount={row.total_sale ?? 0} />
        </ColumnAlignLeft>
      ),
    };
  });

  const applyChanges = () => { };
  const addFilterOptions = [
    { label: "MPN", value: "product_mpn_lower", type: "string" },
    { label: "Title", value: "product_title_lower", type: "string" },
    { label: "Description", value: "product_description_lower", type: "string" },
    { label: "Orders", value: "order_count", type: "numeric" },
    { label: "Gross Sales", value: "gross_sale", type: "numeric" },
    { label: "Refund Sales", value: "gross_adjust", type: "numeric" },
    { label: "Total Sales", value: "total_sale", type: "numeric" },
    { label: "Category", value: "category_name_lower", type: "string" },
  ];

  return (

    <Card>
      {tableData === undefined ? (
        <>
          <PlaceholderTableSearchItem />
          <TablePlaceHolder columnNames={tableColumns} numberOfRows={number_items_per_page} />
        </>
      ) : (
        <Table
          setSearchValue={setSearchValue}
          columnNames={tableColumns}
          data={tableData}
          applyChanges={applyChanges}
          offset={offset}
          setOffset={setOffset}
          totalRecords={totalRows}
          ref={multiSelectRef}
          multiSelectTable={false}
          filters
          addFilterOptions={addFilterOptions}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setOrderBy={setOrderBy}
          orderByOptions={orderByOptions}
          orderBy={orderBy}
          loading={loading}
          setLoading={setLoading}
          searchPlaceHolder="Search by MPN,..."
          searchHint="Search by MPN, Title, Description, Category, Manufacturer"
        />
      )}
    </Card>

  );
};
