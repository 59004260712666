import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AttributeMappingPartFragment,
  useCreateProductImportMutation,
} from "../../../generated/urql-graphql";
import { ExportData } from "../../azure/components/AzureBlob";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { Table } from "../../common/components/Table/index";
import { UploadSpreadsheet } from "../../common/components/UploadSpreadsheet";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { uploadImportFile } from "../../common/miscellaneous/storage";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { IHash, MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import { ISheetData } from "../../products-import-route/utils/types";

interface IProps {
  mappedAttrs: AttributeMappingPartFragment[];
}

export const UpdateProducts = (props: IProps) => {
  const { mappedAttrs } = props;
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const navigate = useNavigate();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/products`;
  const [data, setData] = useState<ISheetData | null>(null);
  const [updates, setUpdates] = useState<IHash>({});
  const [showMapping, setShowMapping] = useState<boolean>(false);
  const setAlert = (message: string, messageType: "success" | "error") => {
    alertsRef.current?.add(message, messageType);
  };
  const notificationUrl = `/${tenantUrlTag}/users/notification`;

  const [, createImportMutation] = useCreateProductImportMutation();
  useEffect(() => {
    if (!data?.rowData) {
      setUpdates({});
    }
    else {
      const attrs: IHash = {};
      mappedAttrs?.forEach(attr => {
        if (attr.sheet_column)
          attrs[attr.sheet_column] = attr;
      });

      const temp: IHash = {};
      temp.attrs = {} as IHash;
      let hasMfr = false;
      let hasMpn = false;
      data?.rowData.header.forEach(col => {
        const attr = attrs[col];
        if (attr) {
          temp.attrs[col] = attr;
          if (attr.attribute_name === "manufacturer")
            hasMfr = true;
          if (attr.attribute_name === "mfr_part_number")
            hasMpn = true;
        }
      });

      temp.requiredBase = hasMfr && hasMpn;
      temp.hasCtgCol = hasCol("mp_category_id");
      temp.hasMpinCol = hasCol("customer_mpin");
      setUpdates(temp);
    }
  }, [data?.rowData, mappedAttrs]);

  const updateBulk = async () => {
    if (data) {
      const url = await uploadImportFile(data.file);
      const importInfo = {
        seller_id: userInfo.seller_id,
        filename: data.file?.name,
        filepath_storage: url,
        sheet_name: data.sheetName,
        user_id: userInfo.user_id,
        operator_id: userInfo.operator_id,
        action: "update"
      };
      const res = await createImportMutation({ importInfo });
      if (!res?.error) {
        setTimeout(() => navigate(baseRoute + '/import-history'), 500);;
      }
      alertsRef.current?.generate(mutationInfo("product updates info", MutationAction.Create, res));
    }
  }

  const tableColumn = ["Your Fields", "Marketpush Fields"];
  const mappedTableData = updates.attrs && Object.keys(updates.attrs).map(col => {
    const attr = updates.attrs[col];
    return {
      id: attr.id,
      Your_Fields:
        <div className="d-flex justify-content-between align-items-center">
          <div className="">
            {col}
          </div>
          <div className="display-3 me-2 text-muted">
            <i className="bi bi-arrow-right"></i>
          </div>
        </div>,
      Marketpush_Fields: attr.display
    };
  });
  const existingMappingData = mappedAttrs.filter(attr => attr.attribute_name !== "product_category").map(attr => {
    return {
      id: "existing_" + attr.id,
      Your_Fields:
        <div className="d-flex justify-content-between align-items-center">
          <div className="">
            {attr.sheet_column}
          </div>
          <div className="display-3 me-2 text-muted">
            <i className="bi bi-arrow-right"></i>
          </div>
        </div>,
      Marketpush_Fields: attr.display
    };
  });

  const hasCol = (colname: string) => !!data?.rowData?.header.filter(col => col === colname).length;
  return (
    <>
      <div className="product-import-wizard">
        <ReturnHeader
          url={baseRoute}
          title="Update Products"
          description="Update a subset of product fields by including only specific columns in the file. For example, you may include columns for category id, MPIN, product image URL, datasheet URL, or other selected attributes. There is no need to include all columns; only the ones you wish to update need to be present in the file."
        />
        <Card>
          <>
            {mappedAttrs.length ?
              <div className="d-flex align-items-center ">
                <div>See the instructions on how to name your file columns</div>
                <button
                  data-role="button"
                  onClick={() => setShowMapping(true)}
                  className="btn btn-link"
                >
                  Show
                </button>
              </div> :
              <div>No attribute mapping exists and the products can not be updated.</div>
            }
          </>
        </Card>
        {mappedAttrs.length &&
          <>
            <Card className="p-4">
              <h4 className="card-title mb-0">Upload product file </h4>
              <UploadSpreadsheet setSheetData={setData} stopParsing={updates.requiredBase || data?.rowData} />
              {updates.requiredBase ?
                <>
                  <div className="mt-3">
                    <h6>Category Update</h6>
                    <div className="px-2">
                      {updates.hasCtgCol ?
                        <div className="d-flex justify-content-start">
                          <span className="bi bi-check text-success display-4"></span>
                          mp_category_id column found. Category will be updated.
                        </div> :
                        <small className="text-muted"> - No category update.</small>}
                    </div>
                  </div>
                  <div className="mt-3">
                    <h6>Mpin Update</h6>
                    <div className="px-2">
                      {updates.hasMpinCol && !userInfo.seller_id ?
                        <div className="d-flex justify-content-start">
                          <span className="bi bi-check text-success display-4"></span>
                          customer_mpin column found. Mpin will be updated.
                        </div> :
                        <div><small className="text-muted"> - No mpin update.</small></div>}
                      <div><small className="text-muted"> * Updating mpin is available when allow_customer_mpin setting is on. The seller can't update mpin. </small></div>
                    </div>
                  </div>
                  <div className="mt-3">
                    <h6>Mapped Data Fields</h6>
                    <div className="px-2">
                      {Object.keys(updates.attrs).length > 2 ?
                        <Table columnNames={tableColumn} data={mappedTableData} /> :
                        <small className="text-muted"> - No data attribute update.</small>}
                    </div>
                  </div>
                </>
                : <div className={data?.rowData ? "text-danger" : ""}>
                  <i className="bi bi-info-circle me-1"></i>
                  <small>Manufacturer and part number mappings are required for product updates. (see instruction above)</small>
                </div>
              }
            </Card>
            <div className="mt-4 d-flex justify-content-end">
              <Button
                data-testid=""
                type="button"
                className="btn btn-primary"
                onClick={updateBulk}
                disabled={!(updates.requiredBase &&
                  (updates.hasCtgCol || (updates.hasMpinCol && !userInfo.seller_id)
                    || Object.keys(updates.attrs).length > 2))}
              >
                Update
              </Button>
            </div>
          </>}
        <Dialog
          show={showMapping}
          title="Partial Update Instruction"
          infoOnly={true}
          closeText="Ok"
          size="lg"
          continue={() => setShowMapping(false)}
        >
          <>
            <div>
              <h6>Category Update</h6>
              <div className="px-2">
                To assign a different category to the product, include the <span className="font-weight-semi-bold text-primary">mp_category_id</span> column in your file. This column should contain the UUID of the desired category
                <div>
                  <ExportData queryString={`QUERY_EXPORT_CATEGORY`}
                    inputJson={`{"variables":{"where":{"operator_id":{"_eq":"${userInfo.operator_id}"}}}}`}
                    caption={`Download full category tree with UUIDs`}
                    btnType="link"
                    setAlert={setAlert}
                    redirectLink={notificationUrl}
                  />
                </div>
              </div>
            </div>
            <div className="mt-3">
              <h6>Mpin Update</h6>
              <div className="px-2">Include <span className="font-weight-semi-bold text-primary">customer_mpin</span> column to update the MPIN product field</div>
            </div>
            <div className="mt-3">
              <h6>Attribute Update</h6>
              <div className="px-2">To update product attributes, please match the sheet column to the name of 'Your Fileds'.
                <div className="product-attrs-scroll mt-2">
                  <Table columnNames={tableColumn} data={existingMappingData} />
                </div>
              </div>
            </div>
          </>
        </Dialog>
      </div>
    </>
  );
};
