import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { countries, statesOfUnitedStates } from "../../common/miscellaneous/data";
import { FormInput } from "../../forms/components/FormInput";
import { FormSelect } from "../../forms/components/FormSelect";

type IProps = {
  disableForm?: boolean;
  parentPrefix?: string;
};

export const Address: FC<IProps> = (props: IProps): ReturnType<FC> => {
  const { disableForm, parentPrefix } = props;
  const methods = useFormContext();
  const country = methods.watch(`${parentPrefix || ""}country`);
  const zipcodeRegoption = {
    required: country === 'USA',
    pattern: {
      value: country === 'USA' ? /^[0-9]{5}(?:[-\s][0-9]{4})?$/ : /.*/,
      message: "Invalid zipcode format",
    },
  };

  return (
    <>
      <FormSelect
        name={`${parentPrefix || ""}country`}
        label="Country"
        options={countries}
        reg_options={{ required: true }}
        disabled={disableForm}
      />
      <FormInput
        name={`${parentPrefix || ""}line1`}
        label="Address"
        reg_options={{ required: true, maxLength: 150 }}
        disabled={disableForm}
      />
      <FormInput
        name={`${parentPrefix || ""}line2`}
        label="Suite, unit, building, etc. (Optional)"
        reg_options={{ maxLength: 150 }}
        disabled={disableForm}
      />
      <div className="d-flex row">
        <div className="col-sm-12 col-md-4">
          <FormInput
            name={`${parentPrefix || ""}city`}
            label="City"
            reg_options={{ required: true, maxLength: 80 }}
            disabled={disableForm}
          />
        </div>
        <div className="col-sm-12 col-md-4">
          {country === 'USA' ?
            <FormSelect
              name={`${parentPrefix || ""}state`}
              label="State"
              options={statesOfUnitedStates}
              reg_options={{ required: true }}
              disabled={disableForm}
            />
            :
            <FormInput
              name={`${parentPrefix || ""}state`}
              label="State/Province"
              reg_options={{ required: false }}
              disabled={disableForm}
            />}
        </div>
        <div className="col-ms-12 col-md-4">
          <FormInput
            name={`${parentPrefix || ""}zipcode`}
            label="Postal code"
            reg_options={zipcodeRegoption}
            disabled={disableForm}
          />
        </div>
      </div>
    </>
  );
};
