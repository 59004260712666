import { FC } from "react";
import { Link } from "react-router-dom";
import {
  useDeliveryMethodsInitialQuery,
  useShippingZonesInitialQuery,
  useShippingZonesSubscription,
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { useMainContext } from "../../layout/components/MainProvider";

export const OperatorShipping: FC = (): ReturnType<FC> => {
  const [shippingZoneQuery] = useShippingZonesInitialQuery();
  const [shippingZoneSuscription] = useShippingZonesSubscription({});
  const [deliveryMethodsQuery] = useDeliveryMethodsInitialQuery()
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/settings`;
  const shippingRoute = `${baseRoute}/shipping`;
  if (shippingZoneQuery.fetching || !shippingZoneQuery.data) {
    return <Loader />;
  } else if (shippingZoneQuery.error) {
    return <p>{shippingZoneQuery.error.message}</p>;
  }

  const rawData = shippingZoneSuscription.data ?? shippingZoneQuery.data;
  const data = rawData?.shipping_zone;
  const deliveryMethodsData = deliveryMethodsQuery.data?.delivery_method

  if (!data) {
    return <p>No data available</p>;
  }

  return (
    <div className="col-lg-12 col-xl-8 mx-auto">
      <ReturnHeader
        title="Shipping and Delivery"
        url={baseRoute}
        description="Manage how customers receive their orders."
      />
      <div className="row">
        <div className="col-sm-12">
          <Card>
            <div className="d-flex justify-content-between">
              <p className="font-weight-medium mb-0">Delivery Methods</p>
              <Link data-role="nav" to={`${shippingRoute}/manage-delivery-methods`}>Manage Delivery Methods</Link>
            </div>
            <p className="text-muted">
              Manage delivery methods available to your sellers. Learn more about{" "}
              <a
                href="https://marketpush.freshdesk.com/support/solutions/articles/150000038914-about-shipping"
                target="_blank"
                rel="noopener noreferrer"
              >
                delivery methods
              </a>
              .
            </p>
            <p className="font-weight-medium mb-1">Active Delivery Methods</p>
            {deliveryMethodsData?.filter(deliveryMethod => deliveryMethod.status === true).map(deliveryMethod => {
              return (
                <div className="active-zones ms-2" key={deliveryMethod.id}>
                  <div className="d-flex align-items-center">
                    <i className="bi bi-truck"></i>
                    <p className="text-muted m-0 ms-2">{deliveryMethod.delivery_method_name}</p>
                  </div>
                </div>
              )
            })}
          </Card>
          <Card>
            <div className="d-flex justify-content-between">
              <p className="font-weight-medium mb-0">Shipping Zones</p>
              <Link data-role="nav" to={`${shippingRoute}/manage-shipping-zones`}>Manage Shipping Zones</Link>
            </div>
            <p className="text-muted">
              Manage shipping zones that allow sellers to specify different rates, availability, and
              delivery methods. Learn more about{" "}
              <a
                href="https://marketpush.freshdesk.com/support/solutions/articles/150000038914-about-shipping"
                target="_blank"
                rel="noopener noreferrer"
              >
                shipping zones
              </a>
              .
            </p>
            <p className="font-weight-medium mb-1">Active Shipping Zones</p>
            {data
              .filter((element) => element.status === true)
              .map((zone) => (
                <div className="active-zones ms-2" key={zone.id}>
                  <div className="d-flex align-items-center">
                    <i className="bi bi-globe text-muted"></i>
                    <p className="text-muted m-0 ms-2">{zone.zone_name}</p>
                  </div>
                </div>
              ))}
          </Card>
          <Card>
            <div className="d-flex justify-content-between">
              <p className="font-weight-medium mb-0">Shipping Carriers</p>
              <Link data-role="nav" to={`${shippingRoute}/manage-shipping-carriers`}>Manage Shipping Carriers</Link>
            </div>
            <p className="text-muted mb-0">
              Manage the shipping carriers available to your sellers.
            </p>
          </Card>
          {/* <Card>
            <p className="font-weight-medium">Advanced Settings</p>
            <div className="d-flex align-items-center form-check form-switch">
              <Input ref={null} data-testid="" className="form-check-input" type="checkbox" />
              <Label data-testid="" className="custom-control-label ms-2">
                Exclude shipping from commission
              </Label>
              <Button
                className="btn-clean tooltip-custom right"
                data-testid=""
                data-bs-html="true"
              >
              <span clasName="tooltip-text">Commission will be calculated without deducting shipping from the order.</span>
                <i className="bi bi-info-circle-fill"></i>
              </Button>
            </div>
            <div className="d-flex align-items-center form-check form-switch">
              <Input ref={null} data-testid="" className="form-check-input" type="checkbox" />
              <Label data-testid="" className="custom-control-label ms-2">
                Allow sellers to manage multiple <b>Delivery From</b> locations
              </Label>
              <Button
                className="btn-clean tooltip-custom right"
                data-testid=""
                data-bs-html="true"
                title="Commission will be calculated without deducting shipping from the order."
              > <span clasName="tooltip-text">Commission will be calculated without deducting shipping from the order.</span>
                <i className="bi bi-info-circle-fill"></i>
              </Button>
            </div>
          </Card> */}
        </div>
      </div>
    </div>
  );
};
