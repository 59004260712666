import React from 'react';
import { ZeroedOutMoney } from "../../../reports-route/components/ZeroedOutMoney";

interface SalesData {
    label: string;
    value: string;
}

interface SalesRowProps {
    data: SalesData[];
}

const footerStyles = {
    container: {
        padding: '10px 0',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        width: '100%'
    },
    item: {
        padding: '10px',
        marginRight: '15px',
        display: 'flex',
        alignItems: 'center'
    },
    label: {
        marginRight: '5px'
    },
    value: {
        display: 'inline-block'
    }
} as const;

const Footer: React.FC<SalesRowProps> = ({ data }) => {
    return (
        <div style={footerStyles.container}>
            {data.map((item, index: number) => (
                <div key={index} style={footerStyles.item}>
                    <strong>
                        <span style={footerStyles.label}>{item.label}: </span>
                        <span style={footerStyles.value}>
                            <ZeroedOutMoney amount={(parseFloat(item.value) ?? 0)} />
                        </span>
                    </strong>
                </div>
            ))}
        </div>
    );
};

export default Footer;