import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDeleteMemberMutation, useMemberListSubscription, useMembersCountSubscription } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { ELoadingType, IActionState, IRef, MutationAction, OrderByType } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";

const MemberList = () => {
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const { number_items_per_page } = context.operatorSettings.preset;

  const settingsRoute = `/${tenantUrlTag}/settings`;
  const baseRoute = `/${tenantUrlTag}/settings/members`;
  const [loading, setLoading] = useState<number>(-1);
  const navigate = useNavigate();
  const formatDate = useDateFormat();

  const [offset, setOffset] = useState<number>(0);
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [selectedCountFilters, setSelectedCountFilters] = useState<any>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const multiSelectRef = useRef<IRef | null>(null);
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "Company Name",
    column_name: "name",
    orderBy: "desc",
  });
  const defActionState = { ids: null, action: "", item: null };
  const [actionState, setActionState] = useState<IActionState>(defActionState);

  const [, deleteMutation] = useDeleteMemberMutation()
  const tableColumns = [
    "Company Name",
    "Organization",
    "Member Id",
    "Joined Date",
    "City, State",
    "Actions"
  ]

  const tableColumnToSearch = ["name", "org", "company_id"]

  const orderByOptions: { [key: string]: string } = {
    Company_Name: "name",
    Organization: "org",
    Member_Id: "company_id"
  }
  const bulkActions = ["Delete Member"]
  let handleWhereStr = handleWhere({
    columnSearch: handleColumnFilter(tableColumnToSearch, searchValue),
    selectedFilters,
  });
  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      where: JSON.parse(handleWhereStr),
      order_by: orderBy.column_name ? { [orderBy.column_name]: orderBy.orderBy } : undefined
    },
  };
  const [subscribed] = useMemberListSubscription(inputs)
  const [countSubscribed] = useMembersCountSubscription({ variables: { where: JSON.parse(handleWhereStr) } })
  useEffect(() => {
    if (subscribed.data) {
      setLoading(ELoadingType.None);
    }
  }, [subscribed.data]);

  const error = subscribed.error || countSubscribed.error;
  if (error) {
    return <PageError error={{ source: "MemberList", errMsg: error.message }} />;
  }

  if (!subscribed.data || !countSubscribed.data) {
    return (
      <>
        <ReturnHeader url={settingsRoute} title="Members" />
        <Card>
          <div className="table-wrapper">
            <PlaceholderTableSearchItem />
            <TablePlaceHolder columnNames={tableColumns} numberOfRows={number_items_per_page} />
          </div>
        </Card>
      </>
    );
  }
  const membersData = subscribed.data.members_member_company
  const totalMembers = countSubscribed.data.members_member_company_aggregate.aggregate?.count

  const reset = () => {
    multiSelectRef.current?.clearSelectedData();
    setActionState({ ...actionState, ids: null });
  };
  const applyChanges = () => {
    const ids = multiSelectRef.current?.selectedData;
    const action = multiSelectRef.current?.selectedOption;
    if (ids && ids.length !== 0 && action) setActionState({ ids, action: "Delete" });
    return true;
  }

  const continueAction = async (isContinue: boolean) => {
    if (isContinue) {
      let res
      if (actionState.action == "Delete") {
        console.log(actionState, "actionState")
        res = await deleteMutation({ ids: actionState.ids })
        console.log(res, "    res")
      }
      console.log(res, "    res    ", actionState)
      alertsRef.current?.generate(mutationInfo("members", MutationAction.Delete, res));
      reset();
      return
    } reset()
  }


  const addFilterOptions = [
    { label: "Company Name", value: "name" },
    { label: "Member Id", value: "company_id" },
    { label: "Organization", value: "org" },
  ];

  const tableData = membersData.map(member => {
    const actions = [
      {
        actionType: "edit",
        id: member.id,
        display: "Edit",
        icon: "bi bi-pencil",
        actionFunc: () => navigate(`${baseRoute}/${member.id}`),
      },
      {
        actionType: "delete",
        id: member.id,
        display: "Delete",
        icon: "bi bi-trash",
        actionFunc: () => setActionState({ ids: [member.id], action: "Delete", item: member }),
      }
    ]
    return {
      id: member.id,
      Company_Name: member.name,
      Organization: member.org,
      Joined_Date: <div className="date-created">
        <p className="mb-0">{formatDate(member.joined_date)}</p>
      </div>,
      Member_Id: member.company_id,
      City_State: <div>{member.city + ", " + member.state}</div>,
      Actions: <DropdownItems items={actions} />
    }
  })
  return (
    <>
      <ReturnHeader url={settingsRoute} title="Members">
        <Link data-role="nav" to={{ pathname: `${baseRoute}/add-member` }} id="members-add-member-link">
          <Button
            className="btn btn-primary ms-1"
            data-testid="btn-add-member"
            id="members-add-member-btn">
            Add Member
          </Button>
        </Link>
      </ReturnHeader>
      <Card>
        <Table
          setSearchValue={setSearchValue}
          columnNames={tableColumns}
          data={tableData}
          options={bulkActions}
          applyChanges={applyChanges}
          offset={offset}
          setOffset={setOffset}
          totalRecords={totalMembers || 0}
          ref={multiSelectRef}
          extraFilterOps={["contains"]}
          multiSelectTable
          filters
          addFilterOptions={addFilterOptions}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setOrderBy={setOrderBy}
          orderByOptions={orderByOptions}
          orderBy={orderBy}
          loading={loading}
          setLoading={setLoading}
          searchHint="Search by Company Name, City, Organization"
          searchPlaceHolder="Search by Company, ..."
        />
      </Card>
      <Dialog
        show={!!actionState.ids}
        title={`${actionState.action} Member`}
        continueText={actionState.action}
        continue={continueAction}
        contineBtnCss={"btn-danger"}
      >
        Are you sure you want to {actionState.action.toLowerCase()} this member?
      </Dialog>
    </>
  )
}

export default MemberList