import { FC, useEffect, useState } from "react";
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  Vw_Report_Operator_Order_By_Date,
  Vw_Report_Operator_Order_By_Month,
  useDashboardOperatorOrderByDateQuery,
  useDashboardOperatorOrderByMonthQuery
} from "../../../generated/urql-graphql";
import { ChartPlaceHolder } from "../../common/components/ChartPlaceHolder";
import { PageError } from "../../common/components/Errors";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { Button } from "../../forms/components/Button";
import { RevenueBand } from "./RevenueBand";

const dataInit = [
  {
    key: "January",
    name: "January",
    Orders: 0,
    Refunds: 0,
    Earnings: 0,
  },
  {
    key: "February",
    name: "February",
    Orders: 0,
    Refunds: 0,
    Earnings: 0,
  },
  {
    key: "March",
    name: "March",
    Orders: 0,
    Refunds: 0,
    Earnings: 0,
  },
  {
    key: "April",
    name: "April",
    Orders: 0,
    Refunds: 0,
    Earnings: 0,
  },
  {
    key: "May",
    name: "May",
    Orders: 0,
    Refunds: 0,
    Earnings: 0,
  },
  {
    key: "June",
    name: "June",
    Orders: 0,
    Refunds: 0,
    Earnings: 0,
  },
  {
    key: "July",
    name: "July",
    Orders: 0,
    Refunds: 0,
    Earnings: 0,
  },
  {
    key: "August",
    name: "August",
    Orders: 0,
    Refunds: 0,
    Earnings: 0,
  },
  {
    key: "September",
    name: "September",
    Orders: 0,
    Refunds: 0,
    Earnings: 0
  },
  {
    key: "October",
    name: "October",
    Orders: 0,
    Refunds: 0,
    Earnings: 0
  },
  {
    key: "November",
    name: "November",
    Orders: 0,
    Refunds: 0,
    Earnings: 0,
  },
  {
    key: "December",
    name: "December",
    Orders: 0,
    Refunds: 0,
    Earnings: 0,
  },
];

interface ReportDataType {
  key: string;
  name: string;
  Orders: number;
  Refunds: number;
  Earnings: number;
}

const EarningFactor = (earning: number) => {
  return parseFloat(earning?.toFixed(2)) || 0;
};

export const BarChartOperator: FC = (): ReturnType<FC> => {
  const [loading, setLoading] = useState<boolean>(false);
  const userInfo = useUserInfo();
  const [operatorId, setOperatorId] = useState<string>(userInfo.operator_id!);
  let sellerId = userInfo.seller_id!;
  let reportData1: ReportDataType[] | undefined = [];
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  const [viewState, setViewState] = useState<string>("WTD");
  let last7Days = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  let last7DaysUTC = Date.UTC(
    last7Days.getUTCFullYear(),
    last7Days.getUTCMonth(),
    last7Days.getUTCDate(),
    23,
    59,
    59
  );
  let filterLast7Condition = `{ "_gt": "${new Date(last7DaysUTC).toISOString()}"}`;
  const [where, setWhere] = useState<string>(`{
              "operator_id": { "_eq": "${operatorId}" },
              "created_date_tz": ${filterLast7Condition}
           }`);
  const [whereMonth, setWhereMonth] = useState<string>(`{
              "operator_id": { "_eq": "${operatorId}" },
              "month_tz": { "_like": "${9999}%" }
           }`);
  let inputDates = {
    variables: {
      where: JSON.parse(where),
    },
  };
  let inputMonths = {
    variables: {
      where: JSON.parse(whereMonth),
    },
  };
  const [reportDateOrder] = useDashboardOperatorOrderByDateQuery(inputDates)
  const [reportOrder,] = useDashboardOperatorOrderByMonthQuery(inputMonths);

  useEffect(() => {
    if (reportOrder.data) {
      setLoading(false);
    }
  }, [reportOrder.data]);
  useEffect(() => {
    if (userInfo.operator_id !== '00000000-0000-0000-0000-000000000000') {
      setOperatorId(userInfo.operator_id);
      setWhere(`{
                "operator_id": { "_eq": "${userInfo.operator_id}" },
                "created_date_tz": ${filterLast7Condition}
           }`);
    }
  }, [userInfo]);

  const handleReportButtonClick = (event: React.MouseEvent<HTMLButtonElement>, rptType: string) => {
    setViewState(rptType);
  };

  const findByMatchingProperties = (
    reportData: Array<ReportDataType>,
    matchingReportData: ReportDataType
  ): ReportDataType | undefined => {
    return reportData.find((reportItem) => reportItem.key === matchingReportData["key"]);
  };
  const LastNDays = (nDays: number): Array<ReportDataType> => {
    const dates = [...Array(nDays)].map((_, index) => {
      const today = new Date();
      today.setDate(today.getDate() - index);
      return {
        key:
          today.getFullYear() +
          "-" +
          ("0" + (today.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + today.getDate()).slice(-2),
        name: ("0" + (today.getMonth() + 1)).slice(-2) + "-" + ("0" + today.getDate()).slice(-2),
        Orders: 0,
        Refunds: 0,
        Earnings: 0,
      };
    });
    return dates.reverse();
  };
  useEffect(() => {
    currentDate = new Date();
    currentYear = currentDate.getFullYear();

    last7Days = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
    let last7DaysUTC = Date.UTC(
      last7Days.getUTCFullYear(),
      last7Days.getUTCMonth(),
      last7Days.getUTCDate(),
      23,
      59,
      59
    );
    let last30Days = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000);
    let last30DaysUTC = new Date(last30Days.getFullYear(), last30Days.getMonth(), last30Days.getDate())
    let last30DaysCondition = `{ "_gt": "${new Date(last30DaysUTC).toISOString()}"}`;
    if (viewState === "YTD") {
      setWhereMonth(`{
                "operator_id": { "_eq": "${userInfo.operator_id}" },
                "month_tz": { "_like": "${currentYear}%" }
            }`);
    }
    if (viewState === "MTD") {
      setWhere(`{
                  "operator_id": {"_eq": "${userInfo.operator_id}"} ,
                  "created_date_tz" : ${last30DaysCondition}
                }`);
    } else if (viewState === "WTD") {
      setWhere(`{
                    "operator_id": { "_eq": "${userInfo.operator_id}"} ,
                    "created_date_tz" : ${filterLast7Condition}
                  }`);
    }
  }, [viewState, sellerId]);

  const error = reportOrder.error;
  if (error) {
    return <PageError error={{ source: "BarChartOperator", errMsg: error.message }} />;
  }
  if (reportOrder.fetching || !reportOrder.data) {
    return (
      <ChartPlaceHolder
        icon="bar-chart"
        title="Revenue"
        showMetrics={true}
        loadingText="Loading..."
        numberOfBars={10}
      />
    );
  }

  if (viewState === "YTD") {
    reportData1 = dataInit;

    reportOrder.data.vw_report_operator_order_by_month?.forEach(
      (item: Vw_Report_Operator_Order_By_Month) => {
        if (item.month_tz) {
          let monthIndex: number = parseInt(item.month_tz?.substring(5) || "");
          if (reportData1) {
            reportData1[monthIndex - 1]["Orders"] = item.count;
            reportData1[monthIndex - 1]["Refunds"] = item.refunded_count + item.partially_refunded_count;
            reportData1[monthIndex - 1]["Earnings"] = EarningFactor(item.earnings);
          }
        }
      }
    );

  } else if (viewState === "MTD") {
    reportData1 = LastNDays(30);

    reportDateOrder.data?.vw_report_operator_order_by_date?.forEach(
      (item: Vw_Report_Operator_Order_By_Date) => {
        if (reportData1 && item.created_date_tz) {
          let findItem = findByMatchingProperties(reportData1, {
            key: item.created_date_tz,
            name: "",
            Orders: 0,
            Refunds: 0,
            Earnings: 0,
          });
          if (findItem) {
            findItem["Orders"] = item.count;
            findItem["Refunds"] = item.refunded_count + item.partially_refunded_count;
            findItem["Earnings"] = EarningFactor(item.earnings);
          }
        }
      }
    );

  } else if (viewState === "WTD") {
    reportData1 = LastNDays(7);

    reportDateOrder.data?.vw_report_operator_order_by_date?.forEach(
      (item: Vw_Report_Operator_Order_By_Date) => {
        if (reportData1 && item.created_date_tz) {
          let findItem = findByMatchingProperties(reportData1, {
            key: item.created_date_tz,
            name: "",
            Orders: 0,
            Refunds: 0,
            Earnings: 0,
          });
          if (findItem) {
            findItem["Orders"] = item.count;
            findItem["Refunds"] = item.refunded_count + item.partially_refunded_count;
            findItem["Earnings"] = EarningFactor(item.earnings);
          }
        }
      }
    );

  }
  return (
    <>
      <div className="d-flex flex-xs-column flex-sm-column flex-md-row flex-end justify-content-between">
        <div className="btn-group">
          <h4 className="card-title me-2">
            <i className="bi bi-bar-chart me-2"></i>Revenue
          </h4>
        </div>
        <div className="btn-group" role="group" ref={null}>
          <Button
            data-testid=""
            type="button"
            className={`btn btn-outline-primary btn-sm ${viewState === "WTD" ? "active" : ""}`}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              handleReportButtonClick(event, "WTD");
            }}
          >
            Last 7 days
          </Button>
          <Button
            data-testid=""
            type="button"
            className={`btn btn-outline-primary btn-sm ${viewState === "MTD" ? "active" : ""}`}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              handleReportButtonClick(event, "MTD");
            }}
          >
            Last 30 days
          </Button>
          <Button
            data-testid=""
            type="button"
            className={`btn btn-outline-primary btn-sm ${viewState === "YTD" ? "active" : ""}`}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              handleReportButtonClick(event, "YTD");
            }}
          >
            YTD
          </Button>
        </div>
      </div>
      <div className="row my-2">
        <RevenueBand reportData={reportData1} />
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={300}
          data={reportData1}
          margin={{
            top: 20,
            right: 80,
            bottom: 120,
            left: 20,
          }}
          barSize={20}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="name" label={{ position: "insideBottomRight", offset: 0 }} scale="band" />
          <YAxis label={{ angle: -90, position: "insideLeft" }} />
          <Tooltip />
          <Legend />
          <Bar dataKey="Orders" fill="#085873" legendType="circle" />
          <Area
            type="monotone"
            dataKey="Refunds"
            fill="#eaeaea"
            stroke="#333"
            legendType="circle"
          />
          {/* <Line
            type="monotone"
            dataKey="Earnings"
            stroke="#198754"
            strokeDasharray="5 5"
            legendType="circle"
          /> */}
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};
