import { FC, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDashboardOrderStatsQuery } from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { useMainContext } from "../../layout/components/MainProvider";

const columns = ["Status", "No."];

export const OrderStat: FC = (): ReturnType<FC> => {
  const [loading, setLoading] = useState<boolean>(false);
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  let reportOrderData1: {
    awaitingfulfillment_count: number;
    pending_count: number;
    shipped_count_last_24_hrs: number;
    shipped_count_last_7_days: number;
    open_incident_count: number;
  } = {
    awaitingfulfillment_count: 0,
    pending_count: 0,
    shipped_count_last_24_hrs: 0,
    shipped_count_last_7_days: 0,
    open_incident_count: 0,
  };
  let currentDate = new Date();
  let last7Days = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  let last7DaysUTC = Date.UTC(
    last7Days.getUTCFullYear(),
    last7Days.getUTCMonth(),
    last7Days.getUTCDate(),
    23,
    59,
    59
  );
  let last24hours = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
  let last24DaysUTC = Date.UTC(
    last24hours.getUTCFullYear(),
    last24hours.getUTCMonth(),
    last24hours.getUTCDate(),
    last24hours.getUTCHours(),
    last24hours.getUTCMinutes(),
    last24hours.getUTCSeconds()
  );

  const statusPending = ` "order_status": { "_eq": "Pending" }`;
  const statusAwaiting = `"order_status": { "_eq": "AwaitingFulfillment" }`;
  const statusShipped = `"order_status": { "_eq": "Shipped" }`;
  let filterLast7Condition = `{ "_gt": "${new Date(last7DaysUTC).toISOString()}"}`;
  let filterLast24hoursCondition = `{ "_gt": "${new Date(last24DaysUTC).toISOString()}"}`;
  const [where1, setWhere1] = useState<string>(`{
                    "operator_id": { "_eq": "${userInfo.operator_id}"},
                    ${statusPending}
                    }`);
  const [where2, setWhere2] = useState<string>(`{
                     "operator_id": { "_eq": "${userInfo.operator_id}"},
                     ${statusAwaiting}
                }`);
  const [where3, setWhere3] = useState<string>(`{
                     "operator_id": { "_eq": "${userInfo.operator_id}"},
                     ${statusShipped},
                     "shipped_date": ${filterLast7Condition}
                }`);
  const [where4, setWhere4] = useState<string>(`{
                     "operator_id": { "_eq": "${userInfo.operator_id}"},
                     ${statusShipped},
                     "shipped_date": ${filterLast24hoursCondition}
                }`);
  const [where5, setWhere5] = useState<string>(`{
                     "order_item": { "order": { "seller": { "operator_id": { "_eq": "${userInfo.operator_id}"}}}},
                     "status": { "_eq": "Open"}
                }`);

  let inputs = {
    variables: {
      where1: JSON.parse(where1),
      where2: JSON.parse(where2),
      where3: JSON.parse(where3),
      where4: JSON.parse(where4),
      where5: JSON.parse(where5)
    },
  };

  const [reportOrderStatusCountData] = useDashboardOrderStatsQuery(inputs);

  useEffect(() => {
    if (reportOrderStatusCountData.data) {
      setLoading(false);
    }
  }, [reportOrderStatusCountData.data]);

  useEffect(() => {
    if (userInfo.seller_id) {
      let filterSeller = `"seller_id": { "_eq": "${userInfo.seller_id}"}`;
      setWhere1(`{${filterSeller},
                  ${statusPending}
                 }`);
      setWhere2(`{${filterSeller},
                  ${statusAwaiting}
                 }`);
      setWhere3(`{${filterSeller},
                  ${statusShipped},
                  "shipped_date": ${filterLast7Condition}
                 }`);
      setWhere4(`{${filterSeller},
                  ${statusShipped},
                  "shipped_date": ${filterLast24hoursCondition}
                 }`);
      setWhere5(`{"status": { "_eq": "Open"},
                  "order_item": { "order": {${filterSeller}}}
                 }`);
    }
    else {
      setWhere1(`{
                    "operator_id": { "_eq": "${userInfo.operator_id}"},
                    ${statusPending}
           }`);
      setWhere2(`{
                     "operator_id": { "_eq": "${userInfo.operator_id}"},
                     ${statusAwaiting}
                }`);
      setWhere3(`{
                     "operator_id": { "_eq": "${userInfo.operator_id}"},
                     ${statusShipped},
                     "shipped_date": ${filterLast7Condition}
                }`);
      setWhere4(`{
                     "operator_id": { "_eq": "${userInfo.operator_id}"},
                     ${statusShipped},
                     "shipped_date": ${filterLast24hoursCondition}
                }`);
      setWhere5(`{
                     "order_item": { "order": { "seller": { "operator_id": { "_eq": "${userInfo.operator_id}"}}}},
                     "status": { "_eq": "Open"}
                }`);
    }
  }, [userInfo]);

  const error = reportOrderStatusCountData.error;
  if (error) {
    return <PageError error={{ source: "OrderStats", errMsg: error.message }} />;
  }
  if (!reportOrderStatusCountData.data) {
    return (
      <>
        <h4 className="card-title">
          <i className="bi bi-cart me-2"></i>Orders
        </h4>
        <TablePlaceHolder columnNames={columns} numberOfRows={5} />
      </>
    );
  }

  reportOrderData1.awaitingfulfillment_count =
    reportOrderStatusCountData.data?.awaiting_count.aggregate?.count ?? 0;
  reportOrderData1.pending_count =
    reportOrderStatusCountData.data?.pending_count.aggregate?.count ?? 0;
  reportOrderData1.shipped_count_last_24_hrs =
    reportOrderStatusCountData.data?.shipped_last24_count.aggregate?.count ?? 0;
  reportOrderData1.shipped_count_last_7_days =
    reportOrderStatusCountData.data?.shipped_last7_count.aggregate?.count ?? 0;
  reportOrderData1.open_incident_count =
    reportOrderStatusCountData.data?.open_incident_count.aggregate?.count ?? 0;

  return (
    <>
      <div className="d-flex flex-end justify-content-start">
        <h4 className="card-title me-2">
          <i className="bi bi-cart me-2"></i>Orders
        </h4>
      </div>
      <div className="row">
        <div className="col-12 mb-3">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Status</th>
                <th scope="col" className="text-right">
                  No.
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p className="font-weight-semi-bold mb-0">Pending</p>
                </td>
                <td className="text-right">{reportOrderData1.pending_count}</td>
              </tr>
              <tr>
                <td>
                  <p className="font-weight-semi-bold mb-0">Awaiting Fulfillment</p>
                </td>
                <td className="text-right">{reportOrderData1.awaitingfulfillment_count}</td>
              </tr>
              <tr>
                <td>
                  <p className="font-weight-semi-bold mb-0">Open Incidents</p>
                </td>
                <td className="text-right">{reportOrderData1.open_incident_count}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-12 mb-3">
          <h4 className="card-title">
            <i className="bi bi-box-seam me-2"></i>Fulfilled Orders
          </h4>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <p className="font-weight-semi-bold mb-0">Last 24 hours</p>
                </td>
                <td className="text-right">{reportOrderData1.shipped_count_last_24_hrs}</td>
              </tr>
              <tr>
                <td>
                  <p className="font-weight-semi-bold mb-0">Last 7 days</p>
                </td>
                <td className="text-right">{reportOrderData1.shipped_count_last_7_days}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-12">
          <NavLink
            data-role="nav"
            data-testid="view-orders-button"
            className="btn btn-primary btn-block"
            to={`/${tenantUrlTag}/orders`}
          >
            View Orders
          </NavLink>
        </div>
      </div>
    </>
  );
};
