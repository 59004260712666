import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Data_Vw_Product_Order_By,
  useProductsCountQuery
} from "../../../generated/urql-graphql";
import { ExportData } from "../../azure/components/AzureBlob";
import { LinkTabsComponent } from "../../common/components/LinkTabsComponent";
import { Loader } from "../../common/components/Loader";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { emptyUuid, isNullOrEmpty } from "../../common/miscellaneous/utility";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";
import ActiveProductList from "./ActiveProductList";
import ArchivedProductList from "./ArchivedProductList";
import { ImportHistory } from "./ImportHistory";
import IndustryProductList from "./IndustryProductList";
import { PendingProductList } from "./PendingProductList";

export function ProductTabs() {
  const { pathname } = useLocation();
  const [context] = useMainContext();
  const userInfo = useUserInfo()!;
  const manageProduct = !!(userInfo.permissions! & context.permissions.manage_product);
  const { seller_create_products,
    seller_export_products,
    products_require_operator_approval,
  } = context.operatorSettings.product;
  const { hide_feature } = context.operatorSettings.system;
  const createProd = manageProduct && (!userInfo.seller_id || seller_create_products);
  const exportProd = !userInfo.seller_id || seller_export_products;

  const { tenant_url_tag: tenantUrlTag, industry_id, cp_is_pim } = context.operatorInfo;
  const baseRoute = `/${tenantUrlTag}/products`;
  const notificationUrl = `/${tenantUrlTag}/users/notification`;

  const captionDownloadTemplate = 'Get offer template';
  let initInput = {
    variables: {
      order_by: '',
      where: JSON.parse('{}'),
    },
  };
  const [input, setInput] = useState<{}>(initInput);
  const countsInputs = {
    variables: {
      operatorId: userInfo.seller_id ? emptyUuid : userInfo.operator_id,
      sellerId: userInfo.seller_id ? userInfo.seller_id : emptyUuid,
      industryId: industry_id || emptyUuid
    }
  };
  const [counts] = useProductsCountQuery(countsInputs);
  if (counts.error) {
    return (
      <div>
        <p>{counts.error.message}</p>
      </div>
    );
  }

  if (isNullOrEmpty(userInfo.user_id) || !counts.data) {
    return <Loader />;
  }

  const setAlert = (message: string, messageType: "success" | "error") => {
    alertsRef.current?.add(message, messageType);
  };
  const HandleCallBack = (
    where: string,
    orderBy: Data_Vw_Product_Order_By | Data_Vw_Product_Order_By[]
  ) => {
    const input2 = {
      variables: {
        order_by: orderBy,
        where: JSON.parse(where),
      },
    };
    if (JSON.stringify(input2) !== JSON.stringify(input)) {
      setInput(input2);
    }
  };
  const totalActiveProducts = counts.data.actives.aggregate?.count || 0;
  const totalArchivedProducts = counts.data.archives.aggregate?.count || 0;
  const totalPendingProducts = counts.data.pendings.aggregate?.count || 0;
  const totalPimProducts = counts.data.pims.aggregate?.count || 0;

  const tabs = [
    {
      enabled: true,
      tabName: "All Products",
      route: `${baseRoute}`,

      totalNumberOfElements: totalActiveProducts,
      component: (
        <ActiveProductList handleCallBack={HandleCallBack} />
      ),
    },
    {
      enabled: !!industry_id,
      tabName: "PIM Catalog",
      route: `${baseRoute}/pim`,
      totalNumberOfElements: totalPimProducts,
      component: (
        <IndustryProductList />
      ),
    },
    {
      enabled: true,
      tabName: "Archived Products",
      route: `${baseRoute}/archived`,
      totalNumberOfElements: totalArchivedProducts,
      component: (
        <ArchivedProductList />
      ),
    },
    {
      enabled: products_require_operator_approval,
      tabName: "Pending Products",
      route: `${baseRoute}/pending`,
      totalNumberOfElements: totalPendingProducts,
      component: (
        <PendingProductList />
      ),
    },
    {
      enabled: true,
      tabName: "Process History",
      route: `${baseRoute}/import-history`,
      component: (
        <ImportHistory />
      ),
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between mb-3">
        <h1 className="page-title">Products</h1>
        <div className="d-flex flex-xs-column flex-sm-row justify-content-end align-items-start navbar-expand-xl">
          <Button data-testid="toggle-button"
            className="btn btn-light navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target=".navbarProductBtns"
            aria-label="Toggle buttons"
          >
            <i className="bi bi-three-dots-vertical"></i>
          </Button>
          <div className="navbarProductBtns align-items-end navbar-collapse collapse ms-5">
            <ul className="navbar-nav ms-xl-auto">
              {(pathname === baseRoute) ?
                (<>
                  {userInfo.seller_id && (<li className="nav-items ms-1">
                    <ExportData queryString={`QUERY_EXPORT_PRODUCT_OFFER`}
                      inputJson={JSON.stringify(input)}
                      caption={captionDownloadTemplate}
                      setAlert={setAlert}
                      redirectLink={notificationUrl}
                    />
                  </li>)}
                  <li className="nav-items ms-1">
                    <ExportData queryString={`QUERY_EXPORT_CATEGORY`}
                      inputJson={`{"variables":{"where":{"operator_id":{"_eq":"${userInfo.operator_id}"}}}}`}
                      caption={`Export categories`}
                      setAlert={setAlert}
                      redirectLink={notificationUrl} />
                  </li>
                  {!hide_feature && exportProd &&
                    <li className="nav-items ms-1">
                      <ExportData queryString={`QUERY_EXPORT_ACTIVE_PRODUCT`}
                        inputJson={JSON.stringify(input)}
                        caption="Export products"
                        setAlert={setAlert}
                        redirectLink={notificationUrl}
                      />
                    </li>}
                </>
                )
                : null
              }
              {(!cp_is_pim || !!userInfo.seller_id) && createProd && <>
                <li className="nav-items ms-1">
                  <NavLink
                    data-role="nav"
                    data-testid=""
                    to={{ pathname: `${baseRoute}/import` }}
                    state={{ seller: userInfo.seller_id }}
                    className="btn btn-light"
                  >
                    Import products
                  </NavLink>
                </li>
                {(!cp_is_pim || !!userInfo.seller_id) && <li className="nav-items">
                  <NavLink data-role="nav" data-testid="" to={`${baseRoute}/add`} className="btn btn-primary ms-3">
                    Add Product
                  </NavLink>
                </li>}
              </>}
            </ul>
          </div>
        </div>
      </div>
      <LinkTabsComponent tabs={tabs.filter(tab => tab.enabled)} />
    </div>
  );
}
