import { FC, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  useDashboardStoreStatsQuery
} from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { useMainContext } from "../../layout/components/MainProvider";
const columns = ["Status", "No."];

export const StoreStat: FC = (): ReturnType<FC> => {
  const userInfo = useUserInfo()!;
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const [loading, setLoading] = useState<boolean>(false);
  let reportStoreData: {
    open_count: number;
    pending_count: number;
    closed_count: number;
    suspended_count: number;
  } = {
    open_count: 0,
    pending_count: 0,
    closed_count: 0,
    suspended_count: 0,
  };
  const [where, setWhere] = useState<string>(`{
              "operator_id": { "_eq": "${userInfo.operator_id}" }
           }`);
  let inputs = {
    variables: {
      where: JSON.parse(where),
    },
  };
  const [reportStoreStatusCountData] = useDashboardStoreStatsQuery(inputs);

  useEffect(() => {
    if (reportStoreStatusCountData.data) {
      setLoading(false);
    }
  }, [reportStoreStatusCountData.data]);

  useEffect(() => {
    if (userInfo.operator_id !== '00000000-0000-0000-0000-000000000000') {
      setWhere(`{
                "operator_id": { "_eq": "${userInfo.operator_id}" }
           }`);
    }
  }, [userInfo]);

  const error = reportStoreStatusCountData.error;
  if (error) {
    return <PageError error={{ source: "StoreStats", errMsg: error.message }} />;
  }
  if (!reportStoreStatusCountData.data) {
    return (
      <>
        <h4 className="card-title placeholder-glow d-flex justify-content-between">
          <span>
            <i className="bi bi-shop me-2"></i>Sellers
          </span>
        </h4>
        <TablePlaceHolder columnNames={columns} numberOfRows={4} />
      </>
    );
  }

  if (reportStoreStatusCountData.data?.vw_report_operator_store_status_count.length > 0) {
    reportStoreData.closed_count =
      reportStoreStatusCountData.data?.vw_report_operator_store_status_count[0].closed_count;
    reportStoreData.suspended_count =
      reportStoreStatusCountData.data?.vw_report_operator_store_status_count[0].suspended_count;
    reportStoreData.open_count =
      reportStoreStatusCountData.data?.vw_report_operator_store_status_count[0].open_count;
    reportStoreData.pending_count =
      reportStoreStatusCountData.data?.vw_report_operator_store_status_count[0].pending_count;
  }
  return (
    <>
      <div className="d-flex flex-end justify-content-between">
        <div className="btn-group">
          <h4 className="card-title me-2">
            <i className="bi bi-shop me-2"></i>Sellers
          </h4>
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-3">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Status</th>
                <th scope="col" className="text-right">
                  No.
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p className="font-weight-semi-bold mb-0">Pending Approval</p>
                </td>
                <td className="text-right">{reportStoreData.pending_count}</td>
              </tr>
              <tr>
                <td>
                  <p className="font-weight-semi-bold mb-0">Open</p>
                </td>
                <td className="text-right">{reportStoreData.open_count}</td>
              </tr>
              <tr>
                <td>
                  <p className="font-weight-semi-bold mb-0">Closed</p>
                </td>
                <td className="text-right">{reportStoreData.closed_count}</td>
              </tr>
              <tr>
                <td>
                  <p className="font-weight-semi-bold mb-0">Suspended</p>
                </td>
                <td className="text-right">{reportStoreData.suspended_count}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-12">
          <NavLink
            data-role="nav"
            data-testid="view-orders-button"
            className="btn btn-primary btn-block"
            to={`/${tenantUrlTag}/sellers`}
          >
            View Sellers
          </NavLink>
        </div>
      </div>
    </>
  );
};
