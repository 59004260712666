import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  useDeleteRoleMutation,
  useGroupRoleUsersInitialQuery,
  useGroupRoleUsersSubscription,
} from "../../../generated/urql-graphql";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { Table } from "../../common/components/Table";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";

export const RolePermissionsRoute: FC = () => {
  const [context] = useMainContext();
  const navigate = useNavigate();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/administration`;
  const adminRoute = `${baseRoute}/role-permissions`;
  const userInfo = useUserInfo()!;
  const [queried] = useGroupRoleUsersInitialQuery();
  const [subscribed] = useGroupRoleUsersSubscription({});
  const [, deleteRoleMutation] = useDeleteRoleMutation();
  const [deleteRoleId, setDeleteRoleId] = useState<string>("");

  const accessRolePermissions = BigInt(userInfo.permissions!) & BigInt(context.permissions.manage_role_permission);
  if (queried.error) {
    return <p>{queried.error.message}</p>;
  }
  if (!subscribed.data || !queried.data) {
    return <Loader />;
  }
  const data = subscribed.data ?? queried.data;
  const roleList = data?.role!;
  const continueDelete = (process: boolean) => {
    if (process) {
      deleteRoleMutation({ roleId: deleteRoleId }, { additionalTypenames: ["role"] })
        .then((res) => {
          if (res.data && !res.error) {
            setDeleteRoleId("");
          }
          alertsRef.current?.generate(mutationInfo("role", MutationAction.Delete, res));
          return;
        })
        .catch((error) => {
          alertsRef.current?.generate(mutationInfo("role", MutationAction.Create, error));
          return;
        });
    } else setDeleteRoleId("");
  };

  const columnNames = ["Group", "Role", "Description", "Users", "Actions"];
  const tableData = roleList.map((role) => {
    const actions = [
      {
        enabled: accessRolePermissions && role.role_id === userInfo.role_id,
        actionType: "view",
        id: role.role_id,
        display: "View",
        icon: "bi bi-eye",
        actionFunc: () => navigate(`${adminRoute}/${role.role_id}`),
      },
      {
        enabled: accessRolePermissions && role.role_id !== userInfo.role_id,
        actionType: "edit",
        id: role.role_id,
        display: "Edit",
        icon: "bi bi-pencil",
        actionFunc: () => navigate(`${adminRoute}/${role.role_id}`),
      },
      {
        enabled: accessRolePermissions && role.users === 0,
        actionType: "delete",
        id: role.role_id,
        display: "Delete",
        icon: "bi bi-trash",
        actionFunc: (id: string) => setDeleteRoleId(id),
      },
    ];

    return {
      id: role.role_id,
      Group: role.group_name,
      Role: role.role_name,
      Description: role.description,
      Users: role.users,
      Actions: <DropdownItems items={actions.filter((action) => action.enabled)} />,
    };
  });

  return (
    <div>
      <ReturnHeader
        title="Manage Roles and Permissions"
        url={baseRoute}
        description="Role and permission determine what MarketPush users can see and do."
      >
        <div>
          {accessRolePermissions ? (
            <Link data-role="nav" to={`${adminRoute}/add-role`}>
              <Button className="btn btn-primary" data-testid="btn-add-role">
                Add Role
              </Button>
            </Link>
          ) : null}
        </div>
      </ReturnHeader>
      <Table columnNames={columnNames} data={tableData} />
      <Dialog
        show={!!deleteRoleId}
        title="Delete Role"
        continueText="Delete"
        closeText="Cancel"
        subFormId=""
        continue={continueDelete}
        contineBtnCss="btn-danger"
      >
        <div className="pt-2">Are you sure you want to delete this role?</div>
      </Dialog>
    </div>
  );
};
