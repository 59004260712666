import { FC, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { Vw_Report_Fulfillment_Rate_Order_By, useFulfillmentRateBySellerQuery } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleColumnFilter } from "../../common/handlers/handleColumnFilter";
import { handleWhere } from "../../common/handlers/handleWhere";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { ELoadingType, IRef, OrderByType } from "../../common/types/types";
import { useMainContext } from "../../layout/components/MainProvider";
import { ColumnAlignLeft } from "./ColumnAlignEnd";
import { IntervalFormat } from "./IntervalFormat";
import { RateNumber } from "./RateNumber";
interface IProp {
  handleCallBack: (where: string,
    orderBy: Vw_Report_Fulfillment_Rate_Order_By | Vw_Report_Fulfillment_Rate_Order_By[]) => void;
};

export const FulfillmentRateData: FC<IProp> = (props) => {
  const [loading, setLoading] = useState<number>(-1);
  const { handleCallBack } = props;
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const accessSeller = userInfo.permissions! & context.permissions.access_seller;
  const { number_items_per_page } = context.operatorSettings.preset;

  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const [offset, setOffset] = useState<number>(0);
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "Seller",
    column_name: "company_name",
    orderBy: "asc",
  });
  const orderByOptions: { [key: string]: string } = {
    Seller: "company_name",
    Orders: "orders",
    Accepted_Rate: "rate_accepted",
    Cancelled_Rate: "rate_cancelled",
    Incident_Rate: "rate_incident",
    Rejected_Rate: "rate_rejected",
    Avg_Acceptance_Time: "avg_acceptance_time",
    Avg_Time_To_Shipped: "avg_time_to_shipped",
  };
  const tableColumnsToSearch: string[] = ["company_name"];

  const tableColumns = [
    "Seller",
    "Orders",
    "Accepted Rate",
    "Cancelled Rate",
    "Incident Rate",
    "Rejected Rate",
    "Avg Acceptance Time",
    "Avg Time To Shipped"
  ];
  const orderByString = orderBy.column_name
    ? { [orderBy.column_name]: orderBy.orderBy }
    : { [orderByOptions.company_name]: "desc" };

  const customRule = `,"operator_id": {"_eq":"${userInfo.operator_id}"},"orders": {"_gt": 0}, "status_cd":{"_in":["Open","Suspended", "Closed" ]}`;
  let handleWhereStr = handleWhere({
    columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue),
    selectedFilters,
    customRule
  });
  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      order_by: orderByString,
      where: JSON.parse(handleWhereStr),
    },
  };

  const [queried] = useFulfillmentRateBySellerQuery(inputs);
  const multiSelectRef = useRef<IRef | null>(null);

  useEffect(() => {
    if (queried.data) {
      setLoading(ELoadingType.None);
    }
  }, [queried.data]);

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "FulfillmentRateData", errMsg: error.message }} />;
  }

  const rows = queried.data?.vw_report_fulfillment_rate;
  const totalRows = queried.data?.items?.aggregate?.count ?? 0;

  handleCallBack(handleWhereStr, orderByString);
  const FormatNumber = (value: number, label: string): string => {
    return (value > 0 ? `${value} ${label}${value > 1 ? 's' : ''}` : '');
  }
  const displayInterval = (jsonInterval: string): string => {
    const jsonObj = JSON.parse(JSON.stringify(jsonInterval));
    const yy = FormatNumber(jsonObj.year, 'yr');
    const mth = FormatNumber(jsonObj.month, 'mth');
    const day = FormatNumber(jsonObj.day, 'day');
    const hr = FormatNumber(jsonObj.hour, 'hr');
    const min = FormatNumber(jsonObj.minute, 'min');
    const sec = FormatNumber(jsonObj.second, 'sec');

    return (
      yy
      + (yy === "" ? mth : ", " + mth)
      + (mth === "" ? day : ", " + day)
      + (day === "" ? hr : ", " + hr)
      + (hr === "" ? min : ", " + min)
      + (min === "" ? sec : ", " + sec)
    );
  };
  const tableData = rows?.map((row) => {
    return {
      id:
        row.id ?? "MISSING ID",

      // TODO: hide entire column if seller, cause only 1 seller
      Seller: accessSeller ? (
        <NavLink
          data-role="nav"
          data-testid="nav-message"
          // TODO: alter SQL to make id unique and replace with store id
          to={`/${tenantUrlTag}/sellers/${row.id}`}
          style={{ maxWidth: "180px" }}
          className="nav-link text-truncate text-decoration-underline"
        >
          {row.company_name}
        </NavLink>
      ) : (
        <div style={{ maxWidth: "180px" }} className="text-truncate">
          {row.company_name}
        </div>
      ),
      Orders: <ColumnAlignLeft className="order-count">{row.orders}</ColumnAlignLeft>,

      Accepted_Rate: (
        <ColumnAlignLeft className="accepted-rate">
          <RateNumber value={row.rate_accepted} />
        </ColumnAlignLeft>
      ),
      Cancelled_Rate: (
        <ColumnAlignLeft className="cancelled-rate">
          <RateNumber value={row.rate_cancelled} />
        </ColumnAlignLeft>
      ),
      Incident_Rate: (
        <ColumnAlignLeft className="incident-rate">
          <RateNumber value={row.rate_incident} />
        </ColumnAlignLeft>
      ),
      Rejected_Rate: (
        <ColumnAlignLeft className="rejected-rate">
          <RateNumber value={row.rate_rejected} />
        </ColumnAlignLeft>
      ),
      Avg_Acceptance_Time: (
        <ColumnAlignLeft className="avg-acceptance-time">
          <IntervalFormat value={row.avg_acceptance_time ? displayInterval(row.avg_acceptance_time_json) : "N/A"} />
        </ColumnAlignLeft>
      ),
      Avg_Time_To_Shipped: (
        <ColumnAlignLeft className="avg-time-to-shipped">
          <IntervalFormat value={row.avg_time_to_shipped ? displayInterval(row.avg_time_to_shipped_json) : "N/A"} />
        </ColumnAlignLeft>
      ),
    };
  });

  const applyChanges = () => { };
  const addFilterOptions = [
    { label: "Seller", value: "company_name_lower", type: "string" },
    { label: "Orders", value: "orders", type: "numeric" },
    { label: "Accepted Rate", value: "rate_accepted", type: "numeric" },
    { label: "Cancelled Rate", value: "rate_cancelled", type: "numeric" },
    { label: "Incident Rate", value: "rate_incident", type: "numeric" },
    { label: "Rejected Rate", value: "rate_rejected", type: "numeric" },
  ];

  return (
    <Card>
      {tableData === undefined ? (
        <>
          <PlaceholderTableSearchItem />
          <TablePlaceHolder columnNames={tableColumns} numberOfRows={number_items_per_page} />
        </>
      ) : (
        <Table
          setSearchValue={setSearchValue}
          columnNames={tableColumns}
          data={tableData}
          applyChanges={applyChanges}
          offset={offset}
          setOffset={setOffset}
          totalRecords={totalRows}
          ref={multiSelectRef}
          multiSelectTable={false}
          filters
          addFilterOptions={addFilterOptions}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          setOrderBy={setOrderBy}
          orderByOptions={orderByOptions}
          orderBy={orderBy}
          loading={loading}
          setLoading={setLoading}
          searchPlaceHolder="Search by Seller"
          searchHint="Search by Seller"
        />
      )}
    </Card>
  );
};
