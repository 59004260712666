import { FC } from "react";
import { NavLink, Route, Routes, useLocation } from "react-router-dom";

export interface ITab {
  tabName: string;
  route: string;
  component: JSX.Element;
  totalNumberOfElements?: number;
}

type Props = {
  tabs: ITab[];
  baseRoute?: string;
};

export const LinkTabsComponent: FC<Props> = (props): ReturnType<FC> => {
  const { tabs, baseRoute } = props;
  const { pathname } = useLocation();
  return (
    <>
      <nav className="nav nav-tabs">
        {tabs.map((tab) => (
          <NavLink
            data-role="nav"
            key={"tab_" + tab.tabName}
            data-testid="tab-link"
            to={tab.route}
            className={`nav-link tab ${pathname === tab.route && "current"} text-capitalize`}
            id={`tab-${tab.tabName}`}
          >
            {tab.tabName}
            <span
              className={`badge text-white ms-3 ${pathname === tab.route ? "bg-primary" : "bg-light-gray text-dark"
                }`}
            >
              {tab.totalNumberOfElements}
            </span>
          </NavLink>
        ))}
      </nav>
      <div className="tab-content">
        {baseRoute ?
          <Routes>
            {tabs.map((tab, index) => {
              return (tab.route === baseRoute ?
                <Route key={index} index element={tab?.component} /> :
                <Route key={index} path={tab.route.replace(baseRoute, "").replace("/", "")} element={tab?.component} />
              );
            })}
          </Routes> :
          tabs.find(tab => tab.route === pathname)?.component}
      </div>
    </>
  );
};
