import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  useProductsListQuery,
  useRestoreProductsMutation
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { Dialog } from "../../common/components/Dialog";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleDateRangeForSQL } from "../../common/handlers/handleDateRangeForSQL";
import { escapeControlCharacters } from "../../common/handlers/handleEscapeControlChar";
import { handleSingleColumnFilter } from "../../common/handlers/handleSingleColumnFilter";
import { handleWhereSingleColumnSearch } from "../../common/handlers/handleWhereSingleColumnSearch";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { mutationInfo } from "../../common/miscellaneous/utility";
import { ELoadingType, IDateRange, IRef, MutationAction, OrderByType } from "../../common/types/types";
import { alertsRef } from "../../layout/components/Main";
import { useMainContext } from "../../layout/components/MainProvider";

const ArchivedProductList: FC = () => {
  const [loading, setLoading] = useState<number>(-1);
  const formatDate = useDateFormat();
  const navigate = useNavigate();
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const managePerm = userInfo.permissions! & context.permissions.manage_product;
  const baseRoute = `/${tenantUrlTag}/products`;
  const operatorManage = Boolean(managePerm && userInfo && userInfo.user_level! > 10);
  const { number_items_per_page } = context.operatorSettings.preset;
  const [offset, setOffset] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedCountFilters, setSelectedCountFilters] = useState<any>("");
  const [selectedFilters, setSelectedFilters] = useState<any>("");
  const [restoreDialog, setRestoreDialog] = useState<boolean>(false);
  const selectedProductsRef = useRef<string[]>([]);
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "Date Archived",
    column_name: "archived_at",
    orderBy: "desc",
  });
  const orderByOptions: { [key: string]: string } = {
    Product: "product_title",
    Manufacturer: "manufacturer",
    Date_Archived: "archived_at",
    MPN: "mfr_part_number",
    Id: "id"
  };
  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "archived_at",
  });
  const tableColumns = [
    "MPN",
    "Product",
    "Manufacturer",
    "Date Archived",
    "Archived By",
    "Actions",
  ];

  const addFilterOptions = [
    { label: "Product", value: "product_title_lower" },
    { label: "MPN", value: "mfr_part_number" },
    { label: "Manufacturer", value: "manufacturer_lower" },
    // { label: "MPIN", value: "mpin_lower" },
  ];

  const tableColumnToSearch = "mfr_part_number_lower";
  const sellerFilter = userInfo.seller_id
    ? `"seller_id":{"_eq": "${userInfo.seller_id}"}`
    : "";
  const handleWhereStr = handleWhereSingleColumnSearch({
    columnSearch: handleSingleColumnFilter(tableColumnToSearch, searchValue.toLowerCase()),
    dateRange,
    selectedFilters,
    statusFilter: `"status":{"_in": ["Archived"]}`,
    sellerFilter
  });

  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      order_by: orderBy.column_name
        ? { [orderBy.column_name]: orderBy.orderBy }
        : { [orderByOptions.Created_Date]: "desc" },
      where: JSON.parse(handleWhereStr)
    },
  };

  const fixSearchStr = () => {
    if (searchValue === "")
      return searchValue;
    const escapedSearchValue = escapeControlCharacters(searchValue)
    return `${escapedSearchValue.toLowerCase().trim()}%`
  }
  const checkComma = () => {
    const comma = (selectedCountFilters !== "" && (dateRange.endDay || dateRange.startDate)) ? "," : "";
    return comma;
  }
  const countInputs = {
    variables: {
      operatorId: context.operatorInfo.id,
      "status": "Archived", "search": fixSearchStr().replaceAll("\\", "\\\\").replaceAll('"', '\\"').replaceAll("''", "'''").replaceAll("'", "''").toLowerCase(),
      "and": `{${selectedCountFilters}${checkComma()}${handleDateRangeForSQL(dateRange)}}`,
      sellerId: userInfo.seller_id ? userInfo.seller_id : null
    }
  };

  const tableBulkActions = ["Restore"];
  const [{ fetching }, restoreProduct] = useRestoreProductsMutation();
  const [queried] = useProductsListQuery(inputs);

  const multiSelectRef = useRef<IRef | null>(null);
  const restoreProductsFunc = async (productsId: string[]) => {
    const results = await restoreProduct({ ids: productsId }, { additionalTypenames: ["data_productship_aggregate", "data_productship", "data_vw_product_aggregate"] });
    if (results && !results.error) {
      multiSelectRef.current?.clearSelectedData();
    }
    alertsRef.current?.generate(mutationInfo("product(s)", MutationAction.Restore, results));
    setRestoreDialog(false);
    selectedProductsRef.current = [];
  };

  useEffect(() => {
    if (queried.data) {
      setLoading(ELoadingType.None);
    }
  }, [queried.data]);

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "ArchivedProductList", errMsg: error.message }} />;
  }

  if (!queried.data) {
    return (
      <Card>
        <PlaceholderTableSearchItem />
        <TablePlaceHolder columnNames={tableColumns} numberOfRows={number_items_per_page} />
      </Card>
    );
  }
  const products = queried.data?.data_vw_product;
  const totalProducts = queried.data?.total_products.aggregate?.count || 0;

  const tableData = products.map((product) => {
    const productImages =
      product.product_image_urls;

    let productImgUrls = productImages?.split("||");
    const actions = [
      {
        actionType: "edit",
        id: product.id,
        display: "Edit",
        icon: "bi bi-pencil",
        actionFunc: () => navigate(`${baseRoute}/${product.id}`),
      },
      {
        actionType: "restore",
        id: product.id,
        display: "restore",
        icon: "bi bi-arrow-counterclockwise",
        actionFunc: () => {
          setRestoreDialog(true);
          selectedProductsRef.current = [product.id];
        },
      },
    ];
    return {
      id: product.id,
      MPN:
        <NavLink
          data-role="nav"
          data-testid="nav-message"
          to={`${baseRoute}/${product.id}`}
          className="nav-link text-truncate text-decoration-underline product-title pb-0 pt-0"
        >
          {product.mfr_part_number}
        </NavLink>,
      Product: (
        <div className="d-inline-block text-truncate">
          <div className="product-wrapper">
            <div className="d-flex align-items-center">
              {productImgUrls?.length ? (
                <div className="d-none d-sm-block product-image me-2">
                  <img
                    src={productImgUrls[0]}
                    alt={productImgUrls[0]}
                    height="60"
                    width="60"
                    className="img-fluid"
                  />
                </div>
              ) : (
                <div className="d-none d-sm-block img-placeholder d-flex justify-content-center align-items-center me-2">
                  <i className="bi bi-card-image"></i>
                </div>
              )}
              <div className="product-details">
                {product?.mfr_part_number && product?.product_title && (
                  <p className="product-title mb-0 text-truncate">{product.product_title}</p>
                )}
                {!product?.product_title && <p className="product-title mb-0">No Title</p>}
                {product?.product_description && (
                  <p className="product-description small text-muted text-truncate mb-0">
                    {product.product_description}
                  </p>
                )}
                <div className="product-category d-flex align-items-center text-muted mb-0">
                  <span className="category-wrapper font-weight-semi-bold small me-1">
                    Category:
                  </span>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <span className="small">{product.product_category}</span>
                      </li>
                    </ol>
                  </nav>
                </div>
                {/*                 {operatorManage && (
                  <small className="text-muted">
                    <span className="font-weight-semi-bold me-1">MPIN:</span>
                    {product.mpin}
                  </small>
                )} */}
              </div>
            </div>
          </div>
        </div>
      ),

      Manufacturer: product.manufacturer,
      Date_Archived: (
        <div className="date-created">
          <p className="mb-0">{formatDate(product.archived_at)}</p>
          <span className="small text-muted">{formatDate(product.archived_at, "time")}</span>
        </div>
      ),
      Archived_By: product.archived_by,
      Actions: <DropdownItems items={actions} />,
    };
  });
  const applyChanges = () => {
    if (multiSelectRef.current?.selectedOption === "Restore") {
      selectedProductsRef.current = [...multiSelectRef.current?.selectedData];
      // setSelectedProducts([...multiSelectRef.current?.selectedData]);
      setRestoreDialog(true);
    }
  };
  return (
    <>
      <Card>
        <Table
          columnNames={tableColumns}
          options={tableBulkActions}
          applyChanges={applyChanges}
          data={tableData}
          ref={multiSelectRef}
          offset={offset}
          setOffset={setOffset}
          totalRecords={totalProducts}
          multiSelectTable
          filters
          dateRange={dateRange}
          setDateRange={setdateRange}
          setSearchValue={setSearchValue}
          addFilterOptions={
            operatorManage
              ? addFilterOptions
              : addFilterOptions.filter(
                (options) => options.label !== "Sellers" && options.label !== "MPIN"
              )
          }
          setSelectedFiltersFunc={setSelectedCountFilters}
          setSelectedFilters={setSelectedFilters}
          convertFiltersToLower={true}
          setOrderBy={setOrderBy}
          orderByOptions={orderByOptions}
          orderBy={orderBy}
          loading={loading}
          setLoading={setLoading}
          searchPlaceHolder="Search by Mfr Part #"
          searchHint="Search by Mfr Part #"
        />
      </Card>
      <Dialog
        show={restoreDialog}
        title={`Restore ${selectedProductsRef.current.length <= 1 ? "Product" : "Products"}`}
        continueText="Restore"
        continue={(process) => {
          if (process) {
            if (selectedProductsRef.current.length)
              restoreProductsFunc(selectedProductsRef.current);
          } else {
            setRestoreDialog(false);
            selectedProductsRef.current = [];
          }
        }}
        contineBtnCss="btn-success"
        fetching={fetching}
      >
        <p className="mb-0">
          Restoring{" "}
          {`${selectedProductsRef.current.length <= 1 ? "this product" : "these products"}`} will
          activate all associated offers. Are you sure you want to restore the selected{" "}
          {`${selectedProductsRef.current.length <= 1 ? "product" : "products"}`}?
        </p>
      </Dialog>
    </>
  );
};

export default ArchivedProductList;
