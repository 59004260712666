import { FC } from "react";
import { NavLink } from "react-router-dom";
import {
  useMessageSettingsInitialQuery,
  useMessageSettingsSubscription,
} from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { Loader } from "../../common/components/Loader";
import { ReturnHeader } from "../../common/components/ReturnHeader";
import { TabsComponent } from "../../common/components/TabsComponent";
import { Button } from "../../forms/components/Button";
import { EmailSettingList } from "./EmailSettingList";

export const EmailSettingsRoute: FC = (): ReturnType<FC> => {
  const [queried] = useMessageSettingsInitialQuery();
  const [subscribed] = useMessageSettingsSubscription({});

  const error = queried.error;
  if (error) {

    return <PageError error={{ source: "EmailSettingsRoute", errMsg: error.message }} />;
  }
  if (!queried.data) {
    return <Loader />;
  }

  const settings = subscribed.data?.message_setting ?? queried.data?.message_setting;

  const tabs = [
    {
      tabName: "To Customer",
      component: (
        <EmailSettingList
          messages={settings
            .filter((ns) => ns.target === "customer")
            .sort((ns1, ns2) => ns1.name.localeCompare(ns2.name))}
        />
      ),
    },
    {
      tabName: "To Seller",
      component: (
        <EmailSettingList
          messages={settings
            .filter((ns) => ns.target === "seller")
            .sort((ns1, ns2) => ns1.name.localeCompare(ns2.name))}
        />
      ),
    },
  ];
  return (
    <>
      <ReturnHeader
        title="Email Settings"
        url="/settings"
        description="Click on the notification title below to view or edit the content."
      >
        <NavLink data-role="nav" data-testid="" to="/settings/email-settings/smtp">
          <Button data-testid="" className="btn btn-primary">
            SMTP Settings
          </Button>
        </NavLink>
      </ReturnHeader>
      <TabsComponent tabs={tabs} />
    </>
  );
};
