import { FC, useEffect, useRef, useState } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import {
  CategoryAttributePartFragment,
  useAddCtgAttributesMutation,
  useCategoryAttributesQuery,
  useRemoveCtgAttributeMutation,
  useUpdateCtgAttrsOrderMutation
} from "../../../generated/urql-graphql";
import { SelectRef } from "../../common/components/CustomSelect";
import { Dialog } from "../../common/components/Dialog";
import { PageError } from "../../common/components/Errors";
import { SearchSelect } from "../../common/components/SearchSelect";
import { appRef } from "../../common/components/appStatus";
import { useGblAttrList } from "../../common/hooks/globals";
import { mutationInfo, reorderList } from "../../common/miscellaneous/utility";
import { IActionState, MutationAction } from "../../common/types/types";
import { Button } from "../../forms/components/Button";
import { alertsRef } from "../../layout/components/Main";
import { DragableItem } from "./DragableItem";
import { EditAttribute } from "./EditAttribute";
import { CategoryAttributes } from "../../products-route/components/CategoryAttributes";
import { useMainContext } from "../../layout/components/MainProvider";
import { useUserInfo } from "../../common/hooks/useUserInfo";

interface IProps {
  categoryId: string;
}

interface AttributesState {
  ctgAttributes: Record<string, CategoryAttributePartFragment> | null;
  attributesOrder: string[];
}

export const AttributesContainer: FC<IProps> = ({ categoryId }: IProps): ReturnType<FC> => {
  const selectRef = useRef<SelectRef | null>(null);
  const [editAttrChanged, setEditAttrChanged] = useState<boolean>(false);
  const glbAttrList = useGblAttrList();
  const userInfo = useUserInfo();
  const [context] = useMainContext();
  const { mng_ctg_attr_view } = context.operatorSettings.product;
  const mngCtgAttr = (BigInt(userInfo.permissions)! & BigInt(context.permissions.manage_ctg_n_attr))
    && mng_ctg_attr_view;

  const defAction = { ids: null, action: "", item: null };
  const [actionState, setActionState] = useState<IActionState>(defAction);
  const defAttrs = {
    ctgAttributes: null,
    attributesOrder: [],
  };
  const [attrsState, setAttrsState] = useState<AttributesState>(defAttrs);

  const input = {
    variables: {
      categoryId: categoryId,
    },
  };
  const [ctgAttrsQueried] = useCategoryAttributesQuery(input);
  const [, addMutation] = useAddCtgAttributesMutation();
  const [, removeMutation] = useRemoveCtgAttributeMutation();
  const [, updateMutation] = useUpdateCtgAttrsOrderMutation();
  const { ctgAttributes, attributesOrder } = attrsState;

  const savedAttrOrder = ctgAttrsQueried.data?.category_by_pk?.attributes_order || [];

  useEffect(() => {
    const ctgData = ctgAttrsQueried?.data;
    const attrsLength = ctgData?.category_attribute?.length;
      if (attrsLength) {
      const hash: Record<string, CategoryAttributePartFragment> = {};
      const attrsOrder : string [] = [];
      ctgData?.category_attribute.forEach((attr, index) => {
        hash[attr.attribute_id] = attr;
        if (savedAttrOrder?.length !== attrsLength) {
          attrsOrder[index] = attr.attribute_id;
        }
      });
      setAttrsState({
        ctgAttributes: hash,
        attributesOrder: attrsOrder.length ? attrsOrder : savedAttrOrder
      });
    }
    else setAttrsState(defAttrs);
  }, [ctgAttrsQueried.data]);

  const error = ctgAttrsQueried?.error
  if (error) {
    return <PageError error={{ source: "AttributesContainer", errMsg: error.message }} />;
  }
  if (!ctgAttrsQueried.data)
    return (
      <div className="d-flex align-items-center justify-content-center attributes-container">
        <div className="absolute-center">
          <div className="spinner-border spinner-border-lg text-muted" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    );

  const addAttributes = async (group: string) => {
    let attrIds;
    if (group === "category") {
      attrIds = selectRef.current?.value || [];
    } else {
      attrIds = glbAttrList.map((attr) => attr.id);
    }
    if (attrIds.length === 0) return;
    const res = await addMutation({
      ctgAttrs: attrIds.map((id: string) => ({ attribute_id: id, category_id: categoryId }))
    }, { additionalTypenames: ["category_attribute"] });
    if (!res.error) {
      selectRef.current?.clear();
      setActionState(defAction);
    }
    alertsRef.current?.generate(mutationInfo("category attribute", MutationAction.Create, res));
  };

  const onDragEnd = async (result: DropResult) => {
    if (result.combine) {
      if (result.type === "COLUMN") {
        const shallow = [...attrsState.attributesOrder];
        shallow.splice(result.source.index, 1);
        setAttrsState({ ...attrsState, attributesOrder: shallow });
        return;
      }
    }

    // dropped nowherez
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // reordering attributes
    if (result.type === "COLUMN") {
      let attrIds = attrsState.attributesOrder;
      attrIds = reorderList(attrIds, source.index, destination.index);
      setAttrsState({ ...attrsState, attributesOrder: attrIds });
      return;
    }
  };

  const continueUpdate = (isContinue: boolean) => {
    if (!isContinue) {
      setActionState(defAction);
      appRef.current?.updateStatus(false);
    }
  };
  const continueRemove = async (isContinue: boolean) => {
    if (isContinue) {
      const res = await removeMutation({
        ctgAttrId: actionState.item?.id!
      }, { additionalTypenames: ["category_attribute"] });
      if (!res.error) {
        setActionState(defAction);
      }
      alertsRef.current?.generate(mutationInfo("attribute", MutationAction.Delete, res));
    } else setActionState(defAction);
  };

  const updateAttrOrder = async (reset: boolean = false) => {
    const res = await updateMutation({
      categoryId,
      attrsOrder: reset ? null : attrsState.attributesOrder,
    }, { additionalTypenames: ["category"] });
    alertsRef.current?.generate(mutationInfo("category attributes order", MutationAction.Update, res));
    if (reset && !res.error)
      setAttrsState({ ...attrsState, attributesOrder: [] });
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center p-3 bg-white rounded">
        <h5 className="card-title mb-0">Attributes</h5>
        <div className="d-flex align-items-center">
          {mngCtgAttr && (!ctgAttributes ? (
            <Button
              className="btn btn-outline-primary btn-sm"
              data-testid="add"
              onClick={() => addAttributes("global")}
            >
              Add Global Attributes
            </Button>
            ): 
            <>
              <Button
                className="btn btn-link btn-sm"
                data-testid="add"
                onClick={()=>updateAttrOrder()}
              >
                Save Attributes Order
              </Button>
              {!!savedAttrOrder?.length && CategoryAttributes.length !== savedAttrOrder.length && (
                <Button
                  className="btn btn-link btn-sm"
                  data-testid="remove"
                  onClick={()=>updateAttrOrder(true)}
                >
                  Reset
                </Button>
                
              )}
            </>)}
        </div>
      </div>
      {ctgAttributes && (
        <div className="list-group">
          {mngCtgAttr && <div className="d-flex p-2 m-2">
            <div className="w-100">
              <SearchSelect
                placeholder="Please select or search for the category attribute that you wish to add"
                itemType="ctgattr"
                multiple={true}
                ref={selectRef}
              />
            </div>
            <div className="ms-3">
              <Button
                className="btn btn-outline-primary"
                data-testid="Add"
                onClick={() => addAttributes("category")}
              >
                Add
              </Button>
            </div>
          </div>}
          {!!attributesOrder.length && <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
            <Droppable
              droppableId="attrs"
              type="COLUMN"
              direction="vertical"
              ignoreContainerClipping={false}
              isCombineEnabled={false}
            >
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {attributesOrder.map((attrId: string, index: number) => (
                    <DragableItem
                      key={attrId}
                      index={index}
                      categoryAttr={ctgAttributes[attrId]}
                      remove={() =>
                        setActionState({
                          ...actionState,
                          action: "remove",
                          item: ctgAttributes[attrId],
                        })
                      }
                      edit={() =>
                        setActionState({
                          ...actionState,
                          action: "edit",
                          item: ctgAttributes[attrId],
                        })
                      }
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>}
          <Dialog
            show={actionState.action === "edit"}
            subFormId="edit-atrribute-form"
            title="Edit Attribute"
            continueText="Update"
            staticModal={true}
            size="xl"
            continue={continueUpdate}
            disableAction={!editAttrChanged}
          >
            <EditAttribute
              categoryAttribute={actionState.item}
              resetAttribute={() => setActionState(defAction)}
              setFormChanged={setEditAttrChanged}
            />
          </Dialog>
          <Dialog
            show={actionState.action === "remove"}
            title="Remove Attribute"
            continueText="Remove"
            continue={continueRemove}
            contineBtnCss={"btn-danger"}
          >
            <p className="mb-0">
              You are about to remove this attribute from all products in this category. Are
              you sure you want to do that?
            </p>
          </Dialog>
        </div>
      )}
    </>
  );
};
