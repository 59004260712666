/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Transactions_Vw_Transaction,
  useTransactionListCountQuery,
  //useTransactionListSubscription,
  useTransactionListQuery
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleDateRangeForSQL } from "../../common/handlers/handleDateRangeForSQL";
import { escapeControlCharacters } from "../../common/handlers/handleEscapeControlChar";
import { handleSingleColumnFilter } from "../../common/handlers/handleSingleColumnFilter";
import { handleWhereSingleColumnSearch } from "../../common/handlers/handleWhereSingleColumnSearch";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { ELoadingType, IDateRange, TransactionByType } from "../../common/types/types";
import { useMainContext } from "../../layout/components/MainProvider";
import { addCommaFunc } from "../../orders-route/handler/handler";
interface ITransactionUI extends Transactions_Vw_Transaction {
  debit: number | null | undefined;
  credit: number | null | undefined;
}

export const TransactionList = ({ sellerId }: { sellerId: string }) => {
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const orderRoute = `/${tenantUrlTag}/orders`;
  const sellerRoute = `/${tenantUrlTag}/sellers`;
  const [loading, setLoading] = useState<number>(-1);
  const formatDate = useDateFormat();
  const { number_items_per_page } = context.operatorSettings.preset;

  const userInfo = useUserInfo()!;
  const manageOrder = userInfo.permissions! & context.permissions.manage_order;
  const accessSeller = userInfo.permissions! & context.permissions.access_seller;
  const isSeller = sellerId;
  const [offset, setOffset] = useState<number>(0);
  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "created_date",
  });
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [selectedCountFilters, setSelectedCountFilters] = useState<any>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [orderBy, setOrderBy] = useState<TransactionByType>({
    display: "",
    column_name: "",
    orderBy: "",
  });
  const orderByOptions: { [key: string]: string } = {
    Date: "transaction_number",
    Seller: "seller_name",
    Order_No: "order_number_text",
  };
  //   console.log("orderByOptions " + JSON.stringify(orderByOptions));
  //const tableColumnsToSearch = isSeller
  //  ? ["cp_order_number_text", "cp_amount_text"]
  //  : ["cp_seller_name", "cp_order_number_text", "cp_amount_text"];

  const tableColumns = isSeller
    ? ["Date", "Order No", "Description", "Debit", "Credit", "Balance"]
    : ["Date", "Seller", "Order No", "Description", "Debit", "Credit", "Balance"];

  //const orderByString = orderBy.column_name
  //  ? { [orderBy.column_name]: orderBy.orderBy }
  //  : { [orderByOptions.Date]: "desc" };

  //const inputs = {
  //  variables: {
  //    limit: number_items_per_page,
  //    offset: offset,
  //    order_by: orderByString,
  //    where: JSON.parse(
  //      handleWhere({
  //        columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue),
  //        dateRange,
  //        selectedFilters,
  //      })
  //    ),
  //  },
  //};

  const formatDescription = (_desc: string | null | undefined, _transType: string) => {
    if (_desc == null) return <span>{_transType?.replace(/([A-Z])/g, " $1")}</span>;

    const lines = _desc.split(" | ");
    return lines.map((line, i) => {
      return (
        <div className="transaction-desc" key={i}>
          {line}
        </div>
      );
    });
  };
  const fixSearchStr = () => {
    if (searchValue === "")
      return searchValue;
    const escapedSearchValue = escapeControlCharacters(searchValue)
    return `${escapedSearchValue.toLowerCase().trim()}%`
  }
  const checkComma = () => {
    const comma = (selectedCountFilters !== "" && (dateRange.endDay || dateRange.startDate)) ? "," : "";
    return comma;
  }
  const countInputs = {
    variables: {
      operatorId: context.operatorInfo.id,
      "search": fixSearchStr().replaceAll("\\", "\\\\").replaceAll('"', '\\"').replaceAll("''", "'''").replaceAll("'", "''").toLowerCase(),
      "and": `{${selectedCountFilters}${checkComma()}${handleDateRangeForSQL(dateRange)}}`,
      sellerId: sellerId
    }
  };
  const tableColumnToSearch = "order_number_text";

  const listBySeller = sellerId
    ? `"seller_id":{"_eq": ${JSON.stringify(sellerId)}}`
    : "";

  const handleWhereStr = handleWhereSingleColumnSearch({
    columnSearch: handleSingleColumnFilter(tableColumnToSearch, searchValue.toLowerCase()),
    dateRange,
    selectedFilters,
    sellerFilter: listBySeller
  });

  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      order_by: orderBy.column_name
        ? { [orderBy.column_name]: orderBy.orderBy }
        : { [orderByOptions.Date]: "desc" },
      where: JSON.parse(handleWhereStr)
      ,
    },
  };
  //   console.log("inputs " + JSON.stringify(inputs));

  const [totalCount] = useTransactionListCountQuery(countInputs);
  const [queried] = useTransactionListQuery(inputs);
  // const [subscribed] = useTransactionListSubscription(inputs);
  useEffect(() => {
    if (queried.data) {
      setLoading(ELoadingType.None);
    }
  }, [queried.data]);

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "TransactionList", errMsg: error.message }} />;
  }

  if (!queried.data) {
    return (
      <>
        <div className="col">
          <Card>
            <PlaceholderTableSearchItem />
            <TablePlaceHolder columnNames={tableColumns} numberOfRows={number_items_per_page} />
          </Card>
        </div>
      </>
    );
  }

  const transactions =
    queried.data?.transactions_vw_transaction;
  const transactionsForUI: ITransactionUI[] = transactions.map((tr) => {
    return {
      ...tr,
      debit: tr.amount <= 0 ? tr.amount : null,
      credit: tr.amount > 0 ? tr.amount : null,
    };
  });

  // const totalTransactions = queried.data?.total_transactions?.aggregate?.count ?? 0;
  const totalTransactions = totalCount.data?.transactions_search_transactions_total_count[0].total_count ?? 0;
  const tableData = transactionsForUI.map((transaction) => {
    return {
      id: transaction.id,

      Date: (
        <div className="date-created">
          <p className="mb-0">{formatDate(transaction.created_date)}</p>
          <span className="small text-muted">{formatDate(transaction.created_date, "time")}</span>
        </div>
      ),
      Seller: accessSeller ? (
        <NavLink
          data-role="nav"
          data-testid="nav-message"
          to={`${sellerRoute}/${transaction.seller_id}`}
          style={{ maxWidth: "180px" }}
          className="nav-link text-truncate text-decoration-underline"
        >
          {transaction.seller_name}
        </NavLink>
      ) : (
        <div style={{ maxWidth: "180px" }} className="text-truncate">
          {transaction.seller_name}
        </div>
      ),
      Order_No: manageOrder ? (
        <NavLink
          data-role="nav"
          data-testid="nav-message"
          to={{
            pathname: `${orderRoute}/${transaction.order_id}`,
          }}
          state={{ sellerId: transaction.seller_id }}
          className="nav-link text-truncate text-decoration-underline"
        >
          {transaction.order_number_text}
        </NavLink>
      ) : (
        transaction.order_number_text
      ),
      Description: formatDescription(transaction.description, transaction.transaction_type ?? ""),
      Debit: (transaction.debit != null) && (
        <span className="text-danger text-nowrap">
          ($ <span>{addCommaFunc(-transaction.debit)}</span>)
        </span>
      ),
      Credit: (transaction.credit != null) && (
        <span className="text-nowrap">
          $ <span>{addCommaFunc(transaction.credit)}</span>
        </span>
      ),
      Balance: (
        <span className="text-nowrap">
          ${" "}
          <span>
            {addCommaFunc(
              transaction.transaction_type == "Payment"
                ? transaction.seller_invoiced_balance
                : transaction.seller_rolling_balance
            )}
          </span>
        </span>
      ),
    };
  });

  const addFilterOptions = isSeller
    ? [
      { label: "Type", value: "transaction_type_lower" },
      { label: "Status", value: "transaction_status_lower" },
      { label: "Order #", value: "order_number_text" },
    ]
    : [
      { label: "Type", value: "transaction_type_lower" },
      { label: "Status", value: "transaction_status_lower" },
      { label: "Seller", value: "seller_name_lower" },
      { label: "Order #", value: "order_number_text" },
    ];

  return (
    <>
      <div className="col">
        <Card>
          <Table
            setSearchValue={setSearchValue}
            columnNames={tableColumns}
            data={tableData}
            offset={offset}
            setOffset={setOffset}
            totalRecords={totalTransactions}
            filters
            dateRange={dateRange}
            setDateRange={setdateRange}
            addFilterOptions={addFilterOptions}
            setSelectedFiltersFunc={setSelectedCountFilters}
            setSelectedFilters={setSelectedFilters}
            convertFiltersToLower={true}
            setOrderBy={setOrderBy}
            orderByOptions={orderByOptions}
            orderBy={orderBy}
            loading={loading}
            setLoading={setLoading}
          />
        </Card>
      </div>
    </>
  );
};
