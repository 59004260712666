import { Tooltip } from "bootstrap";
import { FC, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { sidebarState } from "../../common/hooks/globals";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { defaultLogoUrl, iconDefaultSource } from "../../common/miscellaneous/data";
import { setCssVar } from "../../settings-route/handlers/getCssVar";
import { useMainContext } from "./MainProvider";

const Nav: FC = (): ReturnType<FC> => {
  const userInfo = useUserInfo()!;
  const [context] = useMainContext();
  const { site_info, custom_style, tenant_url_tag } = context.operatorInfo;

  const accessMessageAppSetting = context.operatorSettings.seller.allow_sellers_to_manage_messages
  const enabledFinancesSection = context.operatorSettings.preset.enable_finances_section
  const location = useLocation();
  const { pathname } = location;
  const [sidebar] = useRecoilState(sidebarState);

  const sellerId = userInfo.seller_id
  const baseRoute = `/${tenant_url_tag}`;

  const newFavicon: HTMLLinkElement | null = document.querySelector("[rel=icon]");
  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", function () {
    setWidth(window.innerWidth);
  });

  useEffect(() => {
    if (site_info) {
      document.title = site_info.title;

      if (newFavicon) newFavicon.href = `${site_info.icon}`;

      // eslint-disable-next-line id-length
      custom_style?.forEach((v: { value: string; cssVariable: string }) => {
        setCssVar(v.cssVariable, v.value as string);
      });

      let styleFile = document.getElementById("customStyle");

      if (styleFile) {
        styleFile.remove();
      }

      let newStyle: string = "";
      if (newStyle.length > 0) {
        const style = document.createElement("style");
        style.setAttribute("id", "customStyle");
        style.appendChild(document.createTextNode(""));
        document.head.appendChild(style);
        style.innerHTML = newStyle;
      }
    }
  }, [site_info, custom_style]);

  // tooltip
  useEffect(() => {
    if (!sidebar && width >= 1200) {
      Array.from(document.querySelectorAll('span[data-bs-toggle="tooltip"]')).forEach(
        (tooltipNode) => new Tooltip(tooltipNode)
      );
    } else {
      Array.from(
        document.querySelectorAll('span[data-bs-toggle="tooltip"]')
      ).forEach((tooltipNode) => tooltipNode.removeAttribute("data-bs-original-title"));
    }
  }, [width, sidebar]);

  return (
    <aside className="app-menu">
      <div
        className={`nav-container navbar-${width <= 1200 && sidebar
          ? "small collapsed"
          : width <= 1200 && !sidebar
            ? "small expanded"
            : width >= 1200 && !sidebar
              ? "large collapsed"
              : "large expanded"
          }`}
        id="navContainer"
      >
        <div className="d-flex flex-column justify-content-between wrapper">
          <div className="sidebar-wrapper">
            <div className="logo-container">
              {width <= 1200 && sidebar ? (
                <img
                  src={site_info.icon ?? iconDefaultSource}
                  alt="Marketpush icon"
                  className={`logo-small p-3`}
                />
              ) : width >= 1200 && !sidebar ? (
                <img
                  src={site_info.icon ?? iconDefaultSource}
                  alt="Marketpush icon"
                  className={`logo-small p-3`}
                />
              ) : (
                <img
                  src={site_info?.logo ?? defaultLogoUrl}
                  alt="Marketpush logo"
                  className={`p-3`}
                />
              )}
            </div>
            <hr className="thematic-break mb-3" />
            <nav className="menu-navs">
              {(userInfo.user_level! >= 30) ? (
                <NavLink
                  data-role="nav"
                  data-testid="Nav-store-details"
                  data-category="Nav-tenants"
                  to="/tenants"
                  className="nav-link d-flex align-items-center">
                  <span className="tooltip-custom right d-flex position-fixed">
                    {!sidebar ?
                      <span className="tooltip-text">
                        Tenants
                      </span> : <></>
                    }
                    <i className="bi bi-shop mx-3"></i>
                    <p className="m-0 mx-2">Tenants</p>
                  </span>
                </NavLink>)
                : (
                  <>
                    {
                      (!sellerId && !context.operatorInfo.cp_is_pim) &&
                      <NavLink
                        data-role="nav"
                        data-testid="Nav-dashboard"
                        data-category="Nav-dashboard"
                        to={`${baseRoute}/dashboard`}
                        className={`d-flex align-items-center ${pathname === baseRoute ? "nav-link" : "dashboard-nav-link"}`}
                      >
                        <span className="tooltip-custom right d-flex position-fixed">
                          {!sidebar ?
                            <span className="tooltip-text">
                              Dashboard
                            </span> : <></>
                          }
                          <i className="bi bi-grid-1x2 mx-3"></i>
                          <p className="m-0 mx-2">Dashboard</p>
                        </span>
                      </NavLink>
                    }
                    {userInfo.permissions! & context.permissions.access_seller ? (
                      <NavLink
                        data-role="nav"
                        data-testid="Nav-sellers"
                        data-category="Nav-sellers"
                        to={`${baseRoute}/sellers`}
                        className="nav-link d-flex align-items-center"
                      >
                        <span className="tooltip-custom right d-flex position-fixed">
                          {!sidebar ?
                            <span className="tooltip-text">
                              Sellers
                            </span> : <></>
                          }
                          <i className="bi bi-shop-window mx-3"></i>
                          <p className="m-0 mx-2">Sellers</p>
                        </span>
                      </NavLink>
                    ) : null}
                    {userInfo.user_level! < 20 && (
                      <NavLink
                        data-role="nav"
                        data-testid="Nav-store-details"
                        data-category="Nav-my-seller"
                        to={`${baseRoute}/sellers/${userInfo.seller_id}`}
                        className="nav-link d-flex align-items-center">
                        <span className="tooltip-custom right d-flex position-fixed">
                          {!sidebar ?
                            <span className="tooltip-text">
                              My Seller
                            </span> : <></>
                          }
                          <i className="bi bi-shop mx-3"></i>
                          <p className="m-0 mx-2">My Seller</p>
                        </span>
                      </NavLink>
                    )}
                    {(userInfo.permissions! & context.permissions.access_product) && userInfo.user_level! <= 10 ? (
                      <NavLink
                        data-role="nav"
                        data-testid="Nav-shipping"
                        to={`${baseRoute}/shipping`}
                        data-category="Nav-shipping"
                        className="nav-link d-flex align-items-center"
                      >
                        <span className="tooltip-custom right d-flex position-fixed">
                          {!sidebar ?
                            <span className="tooltip-text">
                              Shipping
                            </span> : <></>
                          }
                          <i className="bi bi-truck mx-3"></i>
                          <p className="m-0 mx-2">Shipping</p>
                        </span>
                      </NavLink>
                    ) : null}
                    {userInfo.permissions! & context.permissions.access_product ? (
                      <NavLink
                        data-role="nav"
                        data-testid="Nav-products"
                        data-category="Nav-products"
                        to={`${baseRoute}/products`}
                        className="nav-link d-flex align-items-center">
                        <span className="tooltip-custom right d-flex position-fixed">
                          {!sidebar ?
                            <span className="tooltip-text">
                              Products
                            </span> : <></>
                          }
                          <i className="bi bi-bag-check mx-3"></i>
                          <p className="m-0 mx-2">Products</p>
                        </span>
                      </NavLink>
                    ) : null}
                    {(!context.operatorInfo.cp_is_pim && userInfo.permissions! & context.permissions.access_offer) ? (
                      <NavLink
                        data-role="nav"
                        data-testid="Nav-offers"
                        data-category="Nav-offers"
                        to={`${baseRoute}/offers`}
                        className="nav-link d-flex align-items-center">
                        <span className="tooltip-custom right d-flex position-fixed">
                          {!sidebar ?
                            <span className="tooltip-text">
                              Offers
                            </span> : <></>
                          }
                          <i className="bi bi-tags mx-3"></i>
                          <p className="m-0 mx-2">Offers</p>
                        </span>
                      </NavLink>
                    ) : null}
                    {!context.operatorInfo.cp_is_pim && userInfo.permissions! & context.permissions.access_order ? (
                      <NavLink
                        data-role="nav"
                        data-testid="Nav-orders"
                        to={`${baseRoute}/orders`}
                        data-category="Nav-orders"
                        className="nav-link d-flex align-items-center">
                        <span className="tooltip-custom right d-flex position-fixed">
                          {!sidebar ?
                            <span className="tooltip-text">
                              Orders
                            </span> : <></>
                          }
                          <i className="bi bi-cart mx-3"></i>
                          <p className="m-0 mx-2">Orders</p>
                        </span>
                      </NavLink>
                    ) : null}
                    {(userInfo.permissions! & context.permissions.access_order) && userInfo.seller_id && accessMessageAppSetting ? (
                      <NavLink
                        data-role="nav"
                        data-testid="Nav-seller-messages"
                        data-category="Nav-seller-messages"
                        to={`${baseRoute}/seller-messages`}
                        className="nav-link d-flex align-items-center">
                        <span className="tooltip-custom right d-flex position-fixed">
                          {!sidebar ?
                            <span className="tooltip-text">
                              Seller Messages
                            </span> : <></>
                          }
                          <i className="bi bi-envelope-open mx-3"></i>
                          <p className="m-0 mx-2">Seller Messages</p>
                        </span>
                      </NavLink>
                    ) : null}
                    {!context.operatorInfo.cp_is_pim && userInfo.permissions! & context.permissions.access_report ? (
                      <NavLink
                        data-role="nav"
                        data-testid="Nav-reports"
                        data-category="Nav-reports"
                        to={`${baseRoute}/reports`}
                        className="nav-link d-flex align-items-center"
                        title="">
                        <span className="tooltip-custom right d-flex position-fixed">
                          {!sidebar ?
                            <span className="tooltip-text">
                              Reports
                            </span> : <></>
                          }
                          <i className="bi bi-graph-up-arrow mx-3"></i>
                          <p className="m-0 mx-2">Reports</p>
                        </span>
                      </NavLink>
                    ) : null}
                    {enabledFinancesSection && ((userInfo.permissions! & context.permissions.access_statement) ||
                      (userInfo.permissions! & context.permissions.manage_finance)) ? (
                      <NavLink
                        data-role="nav"
                        data-testid="Nav-statements"
                        data-category="Nav-finance"
                        to={`${baseRoute}/finance`}
                        className="nav-link d-flex align-items-center"
                        title=""
                      >
                        <span className="tooltip-custom right d-flex position-fixed">
                          {!sidebar ?
                            <span className="tooltip-text">
                              Finances
                            </span> : <></>
                          }
                          <i className="bi bi-bank mx-3"></i>
                          <p className="m-0 mx-2">Finances</p>
                        </span>
                      </NavLink>
                    ) : null}
                    {userInfo.user_level! > 20 && context.operatorInfo.cp_is_pim &&
                      <NavLink data-role="nav"
                        data-testid="Nav-pim-sync-history"
                        className="nav-link d-flex align-items-center"
                        data-category="Nav-pim-sync-history"
                        title="PIM Sync History"
                        to={`${baseRoute}/pim-sync-history`}>
                        <span className="tooltip-custom right d-flex position-fixed">
                          {!sidebar ?
                            <span className="tooltip-text">
                              PIM Sync History
                            </span> : <></>
                          }
                          <i className="bi bi-bank mx-3"></i>
                          <p className="m-0 mx-2">PIM Sync History</p>
                        </span>
                      </NavLink>}
                  </>)}
            </nav>
          </div>
          <footer className={`mb-3 ${sidebar ? "ms-4" : "ms-3"}`}>
            <small className="font-weight-semi-bold ">
              MarketPush &copy; {new Date().getFullYear()}
            </small>
          </footer>
        </div>
      </div>
    </aside >
  );
};

export default Nav;
