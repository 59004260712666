/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useOrderListCountQuery, useOrderListQuery } from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleDateRangeForSQL } from "../../common/handlers/handleDateRangeForSQL";
import { escapeControlCharacters } from "../../common/handlers/handleEscapeControlChar";
import { handleSingleColumnFilter } from "../../common/handlers/handleSingleColumnFilter";
import { handleWhereSingleColumnSearch } from "../../common/handlers/handleWhereSingleColumnSearch";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { badgeCss, separateWithSpace } from "../../common/miscellaneous/utility";
import { ELoadingType, IDateRange, IRef, OrderByType } from "../../common/types/types";
import { useMainContext } from "../../layout/components/MainProvider";
import { addCommaFunc } from "../handler/handler";

export const OrderList = () => {
  const userInfo = useUserInfo()!;
  const sellerId = userInfo.seller_id;
  const [loading, setLoading] = useState<number>(-1);
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const baseRoute = `/${tenantUrlTag}/orders`;
  const navigate = useNavigate();
  const sellerRoute = `/${tenantUrlTag}/sellers`;
  const { number_items_per_page } = context.operatorSettings.preset;
  const formatDate = useDateFormat();

  const manageOrder = userInfo.permissions! & context.permissions.manage_order;
  const accessSeller = userInfo.permissions! & context.permissions.access_seller;

  const [offset, setOffset] = useState<number>(0);
  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "created_date",
  });
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [selectedCountFilters, setSelectedCountFilters] = useState<any>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "Date",
    column_name: "created_date",
    orderBy: "desc",
  });
  const orderByOptions: { [key: string]: string } = {
    Shipping_Deadline: "shipping_deadline",
    Order: "order_number_text",
    Date: "created_date",
    Customer: "customer_full_name_lower",
    Seller: "seller_name_lower",
    Total: "order_total",
    Status: "order_status_lower",
    Items: "order_items_count",
  };
  //const tableColumnsToSearch = [
  //  "customer_full_name_lower",
  //  "order_status_lower",
  //  "seller_name_lower",
  //  "order_number_text",
  //];

  const tableColumns = accessSeller
    ? ["Date", "Order", "Customer", "Items", "Shipping Deadline", "Status", "Seller", "Total"]
    : ["Date", "Order", "Customer", "Items", "Shipping Deadline", "Status", "Total"];

  //const createOrderByFunc = (by: string, orderBy: OrderByType) => {
  //       return { [orderBy.column_name]: orderBy.orderBy };
  //   };

  //const order_items_sort_obj = { count: "count" };
  //  const orderByString = orderBy.column_name
  //      ? [createOrderByFunc(orderBy.column_name, orderBy)]
  //      : [{ [orderByOptions.created_date]: "desc" }];

  //const inputs = {
  //  variables: {
  //    limit: number_items_per_page,
  //    offset: offset,
  //    order_by: orderByString,
  //    where: JSON.parse(
  //      handleWhere({
  //        columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue),
  //        dateRange,
  //        selectedFilters,
  //      })
  //    ),
  //  },
  //  };

  const fixSearchStr = () => {
    if (searchValue === "") return searchValue;
    const escapedSearchValue = escapeControlCharacters(searchValue)
    return `${escapedSearchValue.toLowerCase().trim()}%`;
  };
  const checkComma = () => {
    const comma =
      selectedCountFilters !== "" && (dateRange.endDay || dateRange.startDate) ? "," : "";
    return comma;
  };
  const countInputs = {
    variables: {
      operatorId: context.operatorInfo.id,
      search: fixSearchStr().replaceAll("\\", "\\\\").replaceAll('"', '\\"').replaceAll("''", "'''").replaceAll("'", "''").toLowerCase(),
      and: `{${selectedCountFilters}${checkComma()}${handleDateRangeForSQL(dateRange)}}`,
      sellerId: sellerId,
    },
  };

  //  console.log("countInputs " + JSON.stringify(countInputs));

  const tableColumnToSearch = "order_number_text";

  const listBySeller = sellerId ? `"seller_id":{"_eq": ${JSON.stringify(sellerId)}}` : "";

  const handleWhereStr = handleWhereSingleColumnSearch({
    columnSearch: handleSingleColumnFilter(tableColumnToSearch, searchValue.toLowerCase()),
    dateRange,
    selectedFilters,
    sellerFilter: listBySeller,
  });

  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      order_by: orderBy.column_name
        ? { [orderBy.column_name]: orderBy.orderBy }
        : { [orderByOptions.Created_Date]: "desc" },
      where: JSON.parse(handleWhereStr),
    },
  };

  const [orderCount] = useOrderListCountQuery(countInputs);
  const [queried] = useOrderListQuery(inputs);

  const multiSelectRef = useRef<IRef | null>(null);
  useEffect(() => {
    if (queried.data) {
      setLoading(ELoadingType.None);
    }
  }, [queried.data]);

  const error = queried.error || orderCount?.error;
  if (error) {
    return <PageError error={{ source: "OrderList", errMsg: error.message }} />;
  }

  if (!queried.data || !orderCount.data) {
    return (
      <>
        <div className="d-flex justify-content-between mb-3">
          <h1 className="page-title">Orders</h1>
        </div>
        <Card>
          <div className="table-wrapper">
            <PlaceholderTableSearchItem />
            <TablePlaceHolder columnNames={tableColumns} numberOfRows={number_items_per_page} />
          </div>
        </Card>
      </>
    );
  }

  const orders = queried.data?.vw_order;
  const totalOrders = orderCount.data?.search_orders_total_count[0].total_count ?? 0;

  const checkShippedDate = (
    status: string,
    shippingDeadline: string | null,
    date: string | null
  ) => {
    switch (status) {
      case "Pending":
      case "AwaitingFulfillment":
        return shippingDeadline ? compareDates(shippingDeadline, new Date().toJSON()) : false;
      default:
        return shippingDeadline && date ? compareDates(shippingDeadline, date) : false;
    }
  };
  const compareDates = (shippingDeadline: string | number, date: string | number) =>
    dayjs(shippingDeadline).valueOf() - dayjs(date).valueOf() <= 0;

  const tableData = orders.map((order) => {
    const actions = [
      {
        actionType: "View",
        id: order.id,
        display: "View",
        icon: "bi bi-eye",
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        actionFunc: (id: string) => {
          navigate(
            `${baseRoute}/${order.id}`,
            { state: { sellerId: order.seller_id } },
          );
        },
      },
      {
        actionType: "Accept", // need to add action types later.
        id: order.id,
        // adding some basic logic, will need to add real options later.
        display: order.order_status === "Pending" ? "Accept" : "Cancel",
        icon: order.order_status === "Pending" ? "bi bi-bag-check" : "bi bi-slash-circle",
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        actionFunc: (id: string) => { },
      },
      {
        actionType: "Hold",
        id: order.id,
        display: "Hold",
        icon: "bi bi-stop-circle",
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        actionFunc: (id: string) => { },
      },
    ];
    const comparedDate: string | null =
      (order.order_status === "Cancelled" || order.order_status === "Rejected")
        ? order.created_date
        : order.shipped_date;

    return {
      id: order.id,
      Order: manageOrder ? (
        <div className={`d-flex align-items-center`}>
          <NavLink
            data-role="nav"
            data-testid="nav-message"
            // to={`${baseRoute}/${order.id}`}
            to={{ pathname: `${baseRoute}/${order.id}` }}
            state={{ sellerId: order.seller_id }}
            className={`nav-link text-truncate text-decoration-underline order-number`}
          >
            <span>{order.order_number}</span>
          </NavLink>
          {order.incident_count != null && order.incident_count > 0 && (
            <span className="ms-2 tooltip-custom right">
              <span className="tooltip-text">Order Incident
              </span>
              <i className="bi bi-question-circle" />
            </span>
          )}
        </div>
      ) : (
        <>
          {order.order_number}
          {order.incident_count != null && order.incident_count > 0 && (
            <span className="ms-2 tooltip-custom right">
              <span className="tooltip-text">Open Incident
              </span>
              <i className="bi bi-question-circle" />
            </span>
          )}
        </>
      ),
      Date: (
        <div
          className={`date-created ${order.incident_count != null && order.incident_count > 0 ? "order-incident" : ""
            } ${order.order_total > 0 && order.items_refund > 0 ? "refund-issued" : ""}`}
        >
          <p className="mb-0">{formatDate(order.created_date)}</p>
          <span className="small text-muted">{formatDate(order.created_date, "time")}</span>
        </div>
      ),
      Shipping_Deadline: (
        <>
          <div className={`shipping-due-date`}>
            <p className="mb-0">{formatDate(order.shipping_deadline)}</p>
            <span className="small text-muted">
              {formatDate(order.shipping_deadline, "time")}
            </span>{" "}
            {order.order_status &&
              checkShippedDate(order.order_status, order.shipping_deadline ?? null, comparedDate) ? (
              <span className="ms-2 tooltip-custom top">
                <span className="tooltip-text">Behind Schedule
                </span>
                <i className="bi bi-exclamation-circle text-danger"></i>
              </span>
            ) : null}
          </div>
        </>
      ),
      Customer: (
        <div style={{ maxWidth: "130px" }} className="text-truncate">
          {order.customer_full_name}
        </div>
      ),
      Seller: accessSeller ? (
        <NavLink
          data-role="nav"
          data-testid="nav-message"
          to={`${sellerRoute}/${order.seller_id}`}
          style={{ maxWidth: "180px" }}
          className="nav-link text-truncate text-decoration-underline"
        >
          {order.seller_name}
        </NavLink>
      ) : (
        <div style={{ maxWidth: "180px" }} className="text-truncate">
          {order.seller_name}
        </div>
      ),
      Total: (
        <div className="order-total text-left d-flex align-items-center">
          {/* Using .toFixed function to display number of decimals */}
          <span className="total-amount">
            {order.order_total === 0 ? (
              <span className="text-muted line-through">
                $ <span>{addCommaFunc(order.order_base_total)}</span>
              </span>
            ) : (
              <span className="total">
                <span className="currency-symbol me-1">$</span>
                <span>{addCommaFunc(order.order_total)}</span>
              </span>
            )}
          </span>
          {order.order_total > 0 && order.items_refund > 0 ? (
            <span className="ms-2 tooltip-custom top">
              <span className="tooltip-text">Partially Refunded
              </span>
              <i className="bi bi-info-circle"></i>
            </span>
          ) : null}
        </div>
      ),
      Status: (
        <>
          <span className={badgeCss(order?.order_status)}>
            {separateWithSpace(order.order_status)}
          </span>
        </>
      ),
      Items: order.order_items_count,
    };
  });

  const applyChanges = () => { };
  //{ label: "order", value: "order_number" },
  const addFilterOptions = [
    { label: "Customer", value: "customer_full_name_lower" },
    {
      label: "Status", value: "order_status_lower",
      valueOptions:
        [
          { label: '', value: '' },
          { label: 'Pending', value: 'pending' },
          { label: 'Awaiting Fulfillment', value: 'awaitingfulfillment' },
          { label: 'Shipped', value: 'shipped' },
          { label: 'Canceled', value: 'cancelled' },
          { label: 'Rejected', value: 'rejected' },
          { label: 'Received', value: 'received' },
          { label: 'Refunded', value: 'refunded' },
        ]
    },
    { label: "Seller", value: "seller_name_lower" },
    { label: "Order No.", value: "order_number_text" },
  ];

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <h1 className="page-title">Orders</h1>
      </div>
      <Card>
        <Table
          setSearchValue={setSearchValue}
          columnNames={tableColumns}
          data={tableData}
          applyChanges={applyChanges}
          offset={offset}
          setOffset={setOffset}
          totalRecords={totalOrders}
          ref={multiSelectRef}
          filters
          dateRange={dateRange}
          setDateRange={setdateRange}
          addFilterOptions={addFilterOptions}
          setSelectedFiltersFunc={setSelectedCountFilters}
          setSelectedFilters={setSelectedFilters}
          convertFiltersToLower={true}
          setOrderBy={setOrderBy}
          orderByOptions={orderByOptions}
          orderBy={orderBy}
          loading={loading}
          setLoading={setLoading}
          searchPlaceHolder="Search by Order #"
          searchHint="Search by Order #"
        />
      </Card>
    </>
  );
};
