import { FC, useContext } from "react";
import { NavLink } from "react-router-dom";
import {
  useProductImportInitQuery,
  useProductImportSubscription,
} from "../../../generated/urql-graphql";
import { PageError } from "../../common/components/Errors";
import { PlaceholderLists } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { emptyUuid } from "../../common/miscellaneous/utility";
import { useMainContext } from "../../layout/components/MainProvider";
import { productImportsCols, productImportsData } from "../../products-route/components/ImportHistory";
import { MappingContext } from "../utils/types";

export const ProcessImport: FC = () => {
  const [context] = useMainContext();
  const tenantUrlTag = context.operatorInfo.tenant_url_tag;
  const mapContext = useContext(MappingContext);
  const userInfo = useUserInfo();
  const formatDate = useDateFormat();
  const results = mapContext.resultsRef!.current;
  const importId = results.importing.id || emptyUuid;

  const [queried] = useProductImportInitQuery({
    variables: { importId },
  });
  const [subscribed] = useProductImportSubscription({
    variables: { importId },
  });

  const error = queried.error || subscribed.error;
  if (error) {
    return <PageError error={{ source: "ProcessImport", errMsg: error.message }} />;
  }

  const prodImport =
    subscribed?.data?.imports_vw_product_import || queried?.data?.imports_vw_product_import;
  const tableData = prodImport?.length && productImportsData(prodImport, formatDate, `/${tenantUrlTag}/sellers`)
  if (!tableData)
    return <PlaceholderLists />;

  return (
    <div className="product-import-wizard final-steps mb-4">
      <Table
        columnNames={productImportsCols(userInfo.seller_id)}
        data={tableData}
      />
      <div className="d-flex justify-content-center align-items-center mt-5">
        <NavLink data-role="nav" data-testid="" to={`/${tenantUrlTag}/products`} className="btn btn-primary">
          View Products List
        </NavLink>
      </div>
    </div>
  );
};
