import { FC, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import {
  IndustryProductPartFragment,
  useCreateProductMutation,
  useIndustryProductListQuery, useIndustryProductQuery,
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import DropdownItems from "../../common/components/DropdownItems";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleSingleColumnFilter } from "../../common/handlers/handleSingleColumnFilter";
import { handleWhereSingleColumnSearch } from "../../common/handlers/handleWhereSingleColumnSearch";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { IDateRange, IHash, IRef, OrderByType } from "../../common/types/types";
import { useMainContext } from "../../layout/components/MainProvider";
import { getProdImgUrls } from "../types/product";

const IndustryProductList: FC = () => {
  const [context] = useMainContext();
  const { tenant_url_tag: tenantUrlTag, industry_id } = context.operatorInfo;
  const baseRoute = `/${tenantUrlTag}/products`;
  const [loading, setLoading] = useState<number>(-1);
  const navigate = useNavigate();
  const formatDate = useDateFormat();
  const [offset, setOffset] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [productId, setProductId] = useState<string>("");
  const userInfo = useUserInfo();
  const { number_items_per_page } = context.operatorSettings.preset;
  const managePerm = userInfo.permissions! & context.permissions.manage_product;
  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "created_date",
  });
  const [selectedCountFilters, setSelectedCountFilters] = useState<any>("");
  const [selectedFilters, setSelectedFilters] = useState<any>("");
  const [{ fetching: creating }, createMutation] = useCreateProductMutation();

  const [orderBy, setOrderBy] = useState<OrderByType>({
    display: "Created Date",
    column_name: "created_date",
    orderBy: "desc",
  });
  const orderByOptions: { [key: string]: string } = {
    Product: "product_title",
    Manufacturer: "manufacturer",
    Created_Date: "created_date",
    Updated_Date: "updated_date",
    MPN: "mfr_part_number",
    Id: "id"
  };
  const tableColumns = [
    "Created Date",
    "MPN",
    "Product",
    "Manufacturer",
    "Updated Date",
    "Actions",
  ];

  const tableColumnToSearch = "mfr_part_number_lower";

  const handleWhereStr = handleWhereSingleColumnSearch({
    columnSearch: handleSingleColumnFilter(tableColumnToSearch, searchValue.toLowerCase()),
    dateRange,
    selectedFilters,
    statusFilter: `"status":{"_eq": "Active"}, "industry_id":{"_eq": "${industry_id}"}`
  });

  const orderByString = orderBy.column_name
    ? [{ [orderBy.column_name]: orderBy.orderBy }, { [orderByOptions.Id]: "asc" }]
    : [{ [orderByOptions.Created_Date]: "desc" }, { [orderByOptions.Id]: "asc" }];

  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      order_by: orderByString,
      where: JSON.parse(handleWhereStr)
    },
  };

  const [queried] = useIndustryProductListQuery(inputs);
  const [prodQueried] = useIndustryProductQuery({
    variables: { productId, operatorId: userInfo.operator_id },
    pause: !productId
  });

  useEffect(() => {
    const pimProd = prodQueried.data?.data_mvw_industry_product?.find(prod => prod);
    if (pimProd) {
      addOffer(pimProd);
    }
  }, [prodQueried]);

  const addOffer = async (prod: IndustryProductPartFragment) => {
    let newProdId = prod.tenant_products.find(prod => prod)?.id;
    if (!newProdId) {
      const newCtgId = prod.tenant_categories.find(ctg => ctg)?.category_id;
      const product: IHash = {
        data: prod.data,
        lmfr: prod.manufacturer_lower,
        lmpn: prod.mfr_part_number_lower,
        mpin: v4(),
        operator_id: userInfo.operator_id,
        seller_id: null,
        category_id: newCtgId,
        pim_prod_id: prod.id
      };
      const additionalTypenames = ["data_productship",
        "data_vw_product", "data_vw_product_aggregate"];
      const res = await createMutation({ product }, { additionalTypenames });
      newProdId = res.data?.insert_data_productship_one?.id;
    }
    if (newProdId) {
      navigate(`/${tenantUrlTag}/offers/add/${newProdId}`);
    }
  }

  const multiSelectRef = useRef<IRef | null>(null);
  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "IndustryProductList", errMsg: error.message }} />;
  }

  if (!queried.data) {
    return (
      <>
        <Card>
          <PlaceholderTableSearchItem />
          <TablePlaceHolder columnNames={tableColumns} numberOfRows={number_items_per_page} />
        </Card>
      </>
    );
  }

  const products = queried.data?.data_mvw_industry_product;
  const totalProducts = queried.data?.total_products.aggregate?.count || 0;

  const tableData = (products == null) ? [] : products?.map((product) => {
    const actions = [
      {
        enabled: true,
        actionType: "view",
        id: product.id,
        display: "View",
        icon: "bi bi-eye",
        actionFunc: () => navigate(`${baseRoute}/pim/${product.id}`),
      },
      {
        enabled: userInfo.seller_id,
        actionType: "add-offer",
        id: product.id,
        display: "Add Offer",
        icon: "bi bi-tag",
        actionFunc: () => setProductId(product.id),
      },
    ];

    const productImgUrls = getProdImgUrls(product.product_image_urls);
    return {
      id: product.id,
      Created_Date: (
        <div className="date-created">
          <p className="mb-0">{formatDate(product.created_date)}</p>
          <span className="small text-muted">{formatDate(product.created_date, "time")}</span>
        </div>
      ),
      Updated_Date: (
        <div className="date-created">
          <p className="mb-0">{formatDate(product.updated_date)}</p>
          <span className="small text-muted">{formatDate(product.updated_date, "time")}</span>
        </div>
      ),
      MPN:
        <NavLink
          data-role="nav"
          data-testid="nav-message"
          to={`${baseRoute}/pim/${product.id}`}
          className="nav-link text-truncate text-decoration-underline product-title pb-0 pt-0"
        >
          {product.mfr_part_number}
        </NavLink>,
      Product: (
        <div className="d-inline-block text-truncate product-info">
          <div className="product-wrapper">
            <div className="d-flex align-items-center">
              {productImgUrls?.length ? (
                <div className="d-none d-md-block product-image me-2">
                  <img
                    src={productImgUrls[0]}
                    alt={""}
                    height="60"
                    width="60"
                    className="img-fluid"
                  />
                </div>
              ) : (
                <div className="d-none d-md-block img-placeholder d-flex justify-content-center align-items-center me-2">
                  <i className="bi bi-card-image"></i>
                </div>
              )}
              <div className="product-details">
                <div className="product-header">
                  <p className="product-title mb-0 text-truncate">
                    {product?.product_title || "No Title"}
                  </p>
                </div>
                {product?.product_description && (
                  <p className="product-description small text-muted text-truncate mb-0">
                    {product.product_description}
                  </p>
                )}
                <div className="product-category d-flex align-items-center text-muted mb-0">
                  <span className="category-wrapper font-weight-semi-bold small me-1">
                    Category:
                  </span>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <span className="small">{product.product_category}</span>
                      </li>
                    </ol>
                  </nav>
                </div>
                {userInfo.user_level! > 10 &&
                  <small className="d-flex text-muted">
                    <span className="font-weight-semi-bold me-1">Source:</span>
                    <span>External data source</span>
                  </small>}
              </div>
            </div>
          </div>
        </div>
      ),
      Manufacturer: product.manufacturer,
      Actions: actions.filter((item) => item.enabled).length > 0 ?
        <DropdownItems items={actions.filter((item) => item.enabled)} />
        : <></>,
    };
  });

  const addFilterOptions = [
    { label: "Product", value: "product_title_lower" },
    { label: "MPN", value: "mfr_part_number_lower" },
    { label: "Manufacturer", value: "manufacturer_lower" },
  ];

  return (
    <>
      <Card>
        <Table
          columnNames={tableColumns}
          data={tableData}
          ref={multiSelectRef}
          offset={offset}
          setOffset={setOffset}
          totalRecords={totalProducts}
          multiSelectTable
          filters
          setSearchValue={setSearchValue}
          dateRange={dateRange}
          setDateRange={setdateRange}
          setSelectedFiltersFunc={setSelectedCountFilters}
          setSelectedFilters={setSelectedFilters}
          convertFiltersToLower={true}
          setOrderBy={setOrderBy}
          orderByOptions={orderByOptions}
          orderBy={orderBy}
          loading={loading}
          setLoading={setLoading}
          addFilterOptions={addFilterOptions}
          searchPlaceHolder="Search by Mfr Part #"
          searchHint="Search by Mfr Part #"
        />
      </Card>
    </>
  );
};

export default IndustryProductList;

