/* eslint-disable react-hooks/exhaustive-deps */
import { PDFDownloadLink } from "@react-pdf/renderer";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Transactions_Vw_Billing_Statement,
  useBillingStatementListCountQuery,
  useStatementByIdQuery,
  useStatementListQuery
} from "../../../generated/urql-graphql";
import { Card } from "../../common/components/Card";
import { PageError } from "../../common/components/Errors";
import { PlaceholderTableSearchItem } from "../../common/components/PlaceholderLoaders";
import { Table } from "../../common/components/Table/index";
import { TablePlaceHolder } from "../../common/components/TablePlaceHolder";
import { handleDateRangeForSQL } from "../../common/handlers/handleDateRangeForSQL";
import { escapeControlCharacters } from "../../common/handlers/handleEscapeControlChar";
import { handleSingleColumnFilter } from "../../common/handlers/handleSingleColumnFilter";
import { handleWhereSingleColumnSearch } from "../../common/handlers/handleWhereSingleColumnSearch";
import { useDateFormat } from "../../common/hooks/useDateFormat";
import { useUserInfo } from "../../common/hooks/useUserInfo";
import { ELoadingType, IDateRange } from "../../common/types/types";
import { useMainContext } from "../../layout/components/MainProvider";
import { addCommaFunc } from "../../orders-route/handler/handler";
import { IBillingStatement, IImageInfo, ToIStatementSeller } from "../misc/types";
import { PDFStatement } from "./pdf-generation/PDFStatement";

interface StatementByType {
  display: string;
  column_name: string;
  orderBy: string;
}
// comment
export const StatementList = ({ sellerId }: { sellerId: string }): JSX.Element => {
  const [loading, setLoading] = useState<number>(-1);
  const [context] = useMainContext();
  const { site_info, tenant_url_tag } = context.operatorInfo;
  const { number_items_per_page, time_zone } = context.operatorSettings.preset;

  const sellerRoute = `/${tenant_url_tag}/sellers`;

  const userInfo = useUserInfo()!;
  const accessSeller = userInfo.permissions! & context.permissions.access_seller;
  const isSeller = sellerId;
  const [offset, setOffset] = useState<number>(0);
  const [dateRange, setdateRange] = useState<IDateRange>({
    startDate: null,
    endDay: null,
    column_name: "billing_period_to_date",
  });
  const [selectedFilters, setSelectedFilters] = useState<string>("");
  const [selectedCountFilters, setSelectedCountFilters] = useState<any>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [orderBy, setOrderBy] = useState<StatementByType>({
    display: "",
    column_name: "",
    orderBy: "",
  });
  const orderByOptions: { [key: string]: string } = {
    Billing_Period: "billing_period_from_date",
    Seller: "seller_name",
    Invoiced_Amount: "total_amount",
  };

  // const tableColumnsToSearch = ["cp_seller_name"];

  const tableColumns = isSeller
    ? ["Billing Period", "Invoiced Amount", "Actions"]
    : ["Billing Period", "Seller", "Invoiced Amount", "Actions"];
  //const orderByString = orderBy.column_name
  //  ? { [orderBy.column_name]: orderBy.orderBy }
  //  : { [orderByOptions.Billing_Period]: "desc" };

  //const inputs = {
  //  variables: {
  //    limit: number_items_per_page,
  //    offset: offset,
  //    order_by: orderByString,
  //    where: JSON.parse(
  //      handleWhere({
  //        columnSearch: handleColumnFilter(tableColumnsToSearch, searchValue),
  //        dateRange,
  //        selectedFilters,
  //      })
  //    ),
  //  },
  //};
  // const inputs = {variables: { sellerId: seller.seller_id} };
  // const [query] = useStatementListBySellerIdQuery(inputs);
  const fixSearchStr = () => {
    if (searchValue === "")
      return searchValue;
    const escapedSearchValue = escapeControlCharacters(searchValue)
    return `${escapedSearchValue.toLowerCase().trim()}%`
  }
  const checkComma = () => {
    const comma = (selectedCountFilters !== "" && (dateRange.endDay || dateRange.startDate)) ? "," : "";
    return comma;
  }
  const countInputs = {
    variables: {
      operatorId: context.operatorInfo.id,
      "search": fixSearchStr().replaceAll("\\", "\\\\").replaceAll('"', '\\"').replaceAll("''", "'''").replaceAll("'", "''").toLowerCase(),
      "and": `{${selectedCountFilters}${checkComma()}${handleDateRangeForSQL(dateRange)}}`,
      sellerId: sellerId
    }
  };
  const tableColumnToSearch = "seller_name_lower";

  const listBySeller = sellerId
    ? `"seller_id":{"_eq": ${JSON.stringify(sellerId)}}`
    : "";

  const handleWhereStr = handleWhereSingleColumnSearch({
    columnSearch: handleSingleColumnFilter(tableColumnToSearch, searchValue.toLowerCase()),
    dateRange,
    selectedFilters,
    sellerFilter: listBySeller
  });

  const inputs = {
    variables: {
      limit: number_items_per_page,
      offset: offset,
      order_by: orderBy.column_name
        ? { [orderBy.column_name]: orderBy.orderBy }
        : { [orderByOptions.Billing_Period]: "desc" },
      where: JSON.parse(handleWhereStr)
      ,
    },
  };
  //   console.log("inputs " + JSON.stringify(inputs));

  const [totalCount] = useBillingStatementListCountQuery(countInputs);

  const [queried] = useStatementListQuery(inputs);
  useEffect(() => {
    if (queried.data) {
      setLoading(ELoadingType.None);
    }
  }, [queried.data]);
  const statements = queried.data?.transactions_vw_billing_statement;
  //const totalStmts = queried.data?.total_billing_statements?.aggregate?.count ?? 0;
  const totalStmts = totalCount.data?.transactions_search_billing_statements_total_count[0].total_count ?? 0;
  //const statements: IBillingStatement[] = statementsData
  //  ? statementsData.map((stmt) => {
  //    const {  ...bstmt } = stmt;
  //    return {
  //      ...bstmt,
  //      stmtSeller: { ...ToIStatementSeller(null) },
  //    };
  //  })
  //  : [];

  const [imageInfo, setImageInfo] = useState<IImageInfo>({
    imageURL: "",
    imageWidth: 0,
    imageHeight: 0,
  });
  useEffect(() => {
    if (site_info.logo != null) {
      const img: HTMLImageElement = new Image();
      img.src = site_info.logo;
      img.onload = () => {
        const height = 45;
        const imgH = img.height;
        const imgW = img.width;
        setImageInfo({
          imageURL: img.src,
          imageWidth: (height / imgH) * imgW,
          imageHeight: height,
        });
      };
    }
  }, []);


  const [activePdfStmt, setActivePdfStmt] = useState<Transactions_Vw_Billing_Statement | null>(null);

  const activatePdfDownload = (statementId: string) => {
    let stmt = statements?.find((x) => x.id == statementId);
    if (stmt == null) return;
    else {
      setActivePdfStmt(stmt);
    }
  };

  const error = queried.error;
  if (error) {
    return <PageError error={{ source: "StatementList", errMsg: error.message }} />;
  }

  if (!queried.data) {
    return (
      <>
        <div className="col">
          <Card>
            <PlaceholderTableSearchItem />
            <TablePlaceHolder columnNames={tableColumns} numberOfRows={number_items_per_page} />
          </Card>
        </div>
      </>
    );
  }
  // can't get dayjs to show date as UTC, so this custom function instead
  const utcDate = (_date: string) => {
    const utcDate = new Date(_date);
    return `${utcDate.getUTCMonth() + 1}/${utcDate.getUTCDate()}/${utcDate.getUTCFullYear()}`;
  };
  const tableData = statements
    ? statements?.map((stmt, i) => {
      // if the total is negative, it is not "invoiced", so show $0 in the UI
      const amt = stmt.total_amount > 0 ? stmt.total_amount : 0;
      return {
        id: stmt.id,

        Billing_Period: (
          <div className="date-created">
            <p className="mb-0">
              {utcDate(stmt.billing_period_from_date)} - {utcDate(stmt.billing_period_to_date)}
            </p>
          </div>
        ),
        Seller: accessSeller ? (
          <NavLink
            data-testid="nav-message"
            to={`${sellerRoute}/${stmt.seller_id}`}
            style={{ maxWidth: "180px" }}
            className="nav-link text-truncate text-decoration-underline"
          >
            {stmt.seller_name}
          </NavLink>
        ) : (
          <div style={{ maxWidth: "180px" }} className="text-truncate">
            {stmt.seller_name}
          </div>
        ),
        Invoiced_Amount: (
          <div style={{ maxWidth: "180px" }} className="text-truncate">
            $ {addCommaFunc(amt)}
          </div>
        ),
        Actions: (
          <div className="text-center my-2">
            <a data-role="nav" className="btn btn-link px-2" onClick={() => activatePdfDownload(stmt.id)}>
              Download
            </a>
          </div>
        ),
      };
    })
    : [];

  const addFilterOptions = isSeller
    ? []
    : [{ label: "Seller", value: "seller_name_lower" }];

  const resetPDFStmt = () => {
    setActivePdfStmt(null);
  };

  return (
    <>
      <div className="col">
        <Card>
          <Table
            // setSearchValue={setSearchValue}
            columnNames={tableColumns}
            data={tableData}
            offset={offset}
            setOffset={setOffset}
            totalRecords={totalStmts}
            filters
            dateRange={dateRange}
            setDateRange={setdateRange}
            addFilterOptions={addFilterOptions}
            setSelectedFiltersFunc={setSelectedCountFilters}
            setSelectedFilters={setSelectedFilters}
            convertFiltersToLower={true}
            setOrderBy={setOrderBy}
            orderByOptions={orderByOptions}
            orderBy={orderBy}
            loading={loading}
            setLoading={setLoading}
          />
          {activePdfStmt != null && (
            <MemoizedPDFLink
              imageInfo={imageInfo}
              stmt={activePdfStmt}
              timeZone={time_zone}
              resetDownloadLink={resetPDFStmt}
            />
          )}
        </Card>
      </div>
    </>
  );
};

interface PDRLinkProps {
  stmt: Transactions_Vw_Billing_Statement;
  imageInfo: IImageInfo;
  timeZone: string;
  resetDownloadLink: () => void;
}

const PDFLink = (props: PDRLinkProps) => {
  const formatDate = useDateFormat();
  const stmt = props.stmt;
  const [stmtProp, setStmtProp] = useState<IBillingStatement | null>(null);
  const time_zone = props.timeZone;

  const downloadedRef = useRef<boolean>(false);
  const getFileName = (endDate: string | null | undefined) => {
    if (endDate == null) return "statement.pdf";
    else return `statement-${formatDate(endDate, "MM-DD-YYYY")}.pdf`;
  };
  const inputs = { variables: { statementId: stmt!.id } };
  const [queried] = useStatementByIdQuery(inputs);

  useEffect(() => {
    downloadedRef.current = false;
  }, []);

  useEffect(() => {
    const st = queried.data?.transactions_billing_statement[0];
    if (st != null) {
      const { cp_seller, ...bstmt } = st;
      setStmtProp({
        ...bstmt,
        stmtSeller: { ...ToIStatementSeller(st!.cp_seller?.[0]) },
      });
    }
  }, [queried.data]);

  return (
    <>
      {stmtProp && (
        <PDFDownloadLink
          document={<PDFStatement logoImage={props.imageInfo} billing_statement={stmtProp} timeZone={time_zone} />}
          fileName={getFileName(stmt.billing_period_to_date)}
        >
          {({ blob, url, loading, error }) => {
            if (url != null && downloadedRef.current == false) {
              const anchor = document.createElement("a");
              anchor.href = url!;
              //  anchor.target = "_blank";
              anchor.download = getFileName(stmt.billing_period_to_date);
              anchor.click();
              URL.revokeObjectURL(anchor.href);
              downloadedRef.current = true;
              setTimeout(props.resetDownloadLink, 1000);
            }
            return null;
          }
          }
        </PDFDownloadLink>)}
    </>
  );
};

const areEqual = (prevProps: PDRLinkProps, nextProps: PDRLinkProps) => {
  const isEqual = prevProps.stmt != null && prevProps.stmt.id === nextProps.stmt.id;
  return isEqual;
};

const MemoizedPDFLink = React.memo(PDFLink, areEqual);
